import './App.less';
import * as jspb from 'google-protobuf';
// import 'amfe-flexible';
import React, { useEffect, useReducer, createContext, Dispatch } from 'react';
import { Layout } from 'antd';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Home from './views/Home';
import AddressSearchList from './views/AddressSearchList';
import TxDetail from './views/TsxDetail';
import { getIMStats, getTransferConfigs, getChainVolumeList } from './redux/gateway';
import { transferConfig, reducer } from './store/reducer';
import * as actions from './store/actions';
import { IconConfig, getInnerConfigs, getOuterConfigs } from './constants/chainsConfig';
import { storageConstants } from './constants/const';
import useThemeType from './hooks/useThemeType';
import { Chain, GetChainVolumeResponse, GetTransferConfigsResponse } from './constants/interfaces';

const appCtxDefaultValue = {
  state: { transferConfig, count: 0 },
  dispatch: (() => undefined) as Dispatch<any>,
  isMobile: false,
  clientWidth: 0,
};
export const AppContext = createContext(appCtxDefaultValue);

function App(): JSX.Element {
  const initialState = { transferConfig };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isMobile, clientWidth] = useThemeType();

  useEffect(() => {
    Promise.all([getTransferConfigs(), getIMStats(), getChainVolumeList()]).then((values) => {
      const [configs, stats, chainVolumeList] = values;
      const supportedChains = prepareChainIds(configs);
      const chainIdsOrder = prepareChainIdsOrderWithVolume(chainVolumeList, supportedChains)

      let newSupportedChains: Chain[] = [];
      configs.chains.forEach(chain => {
        if(supportedChains.has(chain.id)) {
          newSupportedChains.push(chain);
        } 
      });
      configs.chains = newSupportedChains;
      dispatch(actions.setTransferConfig(configs));
      dispatch(actions.setSupportedChains(supportedChains.size));

      const idAndIconList = configs?.chains.map((chain) => ({ id: chain.id, icon: chain.icon }))
      localStorage.setItem(storageConstants.CHAINID_AND_ICON_LIST, JSON.stringify(idAndIconList));

      const innerConfigs = getInnerConfigs(configs?.chains, chainIdsOrder);
      const outerConfigs = getOuterConfigs(configs?.chains, chainIdsOrder);
      const idAndTxsMap = prepareChainsTransactionsCountMap(stats.getChainTxMap(), innerConfigs.concat(outerConfigs));
      const idAndTxsMapKeys = Object.keys(idAndTxsMap);
      innerConfigs.concat(outerConfigs).forEach((config) => {
        if (idAndTxsMapKeys.includes(String(config.id))) {
          config.txs = idAndTxsMap[config.id];
        }
      });
      localStorage.setItem(storageConstants.SKETCH_INNER_CONFIGS, JSON.stringify(innerConfigs));
      localStorage.setItem(storageConstants.SKETCH_OUTER_CONFIGS, JSON.stringify(outerConfigs));
    });
  }, []);

  const prepareChainsTransactionsCountMap = (map: jspb.Map<number, number>, configs: IconConfig[]) => {
    const chainTransactionCountMap = {};

    map.forEach((value: number, key: number) => {
      const chain = configs.find((config) => {
        return config.id === key;
      });
      if (chain) {
        chainTransactionCountMap[chain.id] = value;
      }
    });
    return chainTransactionCountMap;
  };

  const prepareChainIdsOrderWithVolume = (response: GetChainVolumeResponse, supportedChains: Set<number>): number[] => {
    return response.chainVolumes
              .sort((chainVolumeA, chainVolumeB) => chainVolumeB.volume - chainVolumeA.volume)
              .map(chainVolume => { return Number(chainVolume.chainId) })
              .filter(chainId => { return supportedChains.has(chainId) })
  }

  return (
    <Layout>
      <BrowserRouter>
        <AppContext.Provider value={{ state, dispatch, isMobile, clientWidth }}>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/account/:address" element={<AddressSearchList />} />
            <Route path="/tx/:hash" element={<TxDetail/>} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </AppContext.Provider>
      </BrowserRouter>
      <Footer />
    </Layout>
  );
}

export default App;

const prepareChainIds = (transferConfig: GetTransferConfigsResponse) => {
  const allChainIds: number[] = transferConfig.chains
  .map(chain => {
    return chain.id;
  });

  const bridgedIds = new Set<number>();

  allChainIds.forEach(id1 => {
    if (bridgedIds.has(id1)) {
      return;
    }
    allChainIds.forEach(id2 => {
      if (id1 === id2) {
        return;
      }

      if (twoChainBridged(id1, id2, transferConfig)) {
        bridgedIds.add(id1);
        bridgedIds.add(id2);
      }
    });
  });

  return bridgedIds
}



const twoChainBridged = (
  chainId1: number,
  chainId2: number,
  transferConfig: GetTransferConfigsResponse,
) => {
  let peggedBridged = false;

  transferConfig.pegged_pair_configs.forEach(peggedPairConfig => {
    const bridged =
      (peggedPairConfig.org_chain_id === chainId1 &&
        peggedPairConfig.pegged_chain_id === chainId2) ||
      (peggedPairConfig.org_chain_id === chainId2 &&
        peggedPairConfig.pegged_chain_id === chainId1);
    peggedBridged = peggedBridged || bridged;
  });

  /// Skip pool based bridge check if two chains have pegged bridge
  if (peggedBridged) {
    return true;
  }

  const poolBasedTokensForChainId1 = transferConfig.chain_token[chainId1];
  const poolBasedTokensForChainId2 = transferConfig.chain_token[chainId2];

  let poolBasedBridged = false;
  if (
    poolBasedTokensForChainId1 &&
    poolBasedTokensForChainId1 !== undefined &&
    poolBasedTokensForChainId2 &&
    poolBasedTokensForChainId2 !== undefined
  ) {
    const poolBasedTokenSymbolsForChainId1: string[] = poolBasedTokensForChainId1.token
      .filter(tokenInfo => {
        return !tokenInfo.token.xfer_disabled
      })
      .map(tokenInfo => {
        return tokenInfo.token.symbol;
      });
    poolBasedTokensForChainId2.token.forEach(tokenInfo => {
      poolBasedBridged =
        poolBasedBridged ||
        (poolBasedTokenSymbolsForChainId1.includes(tokenInfo.token.symbol) &&
          !tokenInfo.token.xfer_disabled);
    });
  }
  return poolBasedBridged || peggedBridged;
};
