import { useState, useEffect, useMemo } from "react";
import _ from "lodash";

function useThemeType(): [boolean, number] {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [clientWidth, setClientWidth] = useState<number>(0);
  
  const onResize = useMemo(     
    () =>
      _.throttle(
        () => {
            setIsMobile(document.documentElement.clientWidth <= 768);
            setClientWidth(document.documentElement.clientWidth)
            document.getElementsByTagName('html')[0].style.fontSize = (document.documentElement.clientWidth / 375) * 100 + 'px';
        },
        400
      ),
    []
  );
  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);
  return [isMobile, clientWidth];
}

export default useThemeType;
