import axios from 'axios';
import { GetTransferConfigsResponse, GetChainVolumeResponse } from '../constants/interfaces';
import { WebClient } from '../proto/web/WebServiceClientPb';
import {
  IMStatusRequest,
  IMStatusResponse,
  QueryLatestTxRequest,
  QueryLatestTxTxResponse,
  SearchByAddrRequest,
  SearchByAddrResponse,
  SearchByTxHashRequest,
  SearchByTxHashResponse
} from '../proto/web/web_pb';

const client = new WebClient(`${process.env.REACT_APP_GRPC_SERVER_URL}`, null, null);

export const getTransferConfigs = (): Promise<GetTransferConfigsResponse> =>
  axios
    .get(`${process.env.REACT_APP_SERVER_URL}/v1/getTransferConfigsForAll`)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log('error=>', e);
    });

export const getIMStats = (): Promise<IMStatusResponse> => {
  const request = new IMStatusRequest();

  return client.iMStatus(request, null);
};

export const searchByAddress = (
  address: string,
  nextPageToken: number | undefined,
  pageNumber: number | undefined
): Promise<SearchByAddrResponse> => {
  const request = new SearchByAddrRequest();
  request.setAddr(address);
  if (nextPageToken) {
    request.setNextPageToken(nextPageToken);
  }
  if (pageNumber) {
    request.setPageNum(pageNumber);
  }

  return client.searchByAddr(request, null);
};

export const searchByTxHash = (transactionHash: string): Promise<SearchByTxHashResponse> => {
  const request = new SearchByTxHashRequest();
  request.setTx(transactionHash);

  return client.searchByTxHash(request, null);
};

export const queryLatestTransactions = (): Promise<QueryLatestTxTxResponse> => {
  const request = new QueryLatestTxRequest();
  request.setSize(20);

  return client.queryLatestTx(request, null);
};

export const getChainVolumeList = (): Promise<GetChainVolumeResponse> => {
  return axios
  .get(`https://getcelerapp.s3.us-west-1.amazonaws.com/dashboard/chainVolume.json`)
  .then((res) => {
    return res.data
  })
  .catch((e) => {
    console.log('error=>', e);
  });
}
