import { P5CanvasInstance } from 'react-p5-wrapper';
import { outerR, innerR, IconConfig } from '../../constants/chainsConfig';
import _ from 'lodash';
import bgGroup from '../../imgs/chains/bg.png';
import celerImg from '../../imgs/chains/celer.png';
import { storageConstants } from '../../constants/const';
import { getOpacityColor } from '../../utils/color';

function hexToRgb(hex: string) {
  hex = hex.slice(1);
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  return {
    r: Number.parseInt(hex.slice(0, 2), 16),
    g: Number.parseInt(hex.slice(2, 4), 16),
    b: Number.parseInt(hex.slice(4, 6), 16)
  };
}

const getRadians = (degrees: number) => degrees * (Math.PI / 180);

let innerBotAngle = 0;
let outerBotAngle = 0;
let step = 0.6;
let centerR = 65;
let angle = 10;
let centerAngle = 8;



export function sketch(p5: P5CanvasInstance) {
  let bg: any;
  let celer: any;
  let innerConfigs: IconConfig[] = [];
  let outerConfigs: IconConfig[] = [];
  let configsArr = [outerConfigs, innerConfigs];
  let centrAngleRad = getRadians(centerAngle);
  // let largerChainsStr: any;
  // let largerChains: any;

  let iconAndIdMap = {};
  // let bots: any = [];
  // let botNum = 0;
  // let chains: Array<number> = [];
  // let defaultRun: Array<number> = [5, 97, 4002];;

  p5.setup = () => {
    let ratio = window.devicePixelRatio;
    p5.frameRate(25)
    p5.pixelDensity(ratio * 2);
    // p5.createCanvas(530, 530);
    p5.createCanvas(530, 440);
    p5.angleMode(p5.DEGREES);
    p5.textStyle(p5.NORMAL);
    bg = p5.loadImage(bgGroup);
    celer = p5.loadImage(celerImg);
    const idAndIconStr = localStorage.getItem(storageConstants.CHAINID_AND_ICON_LIST);
    const idAndIconList = idAndIconStr ? JSON.parse(idAndIconStr) : [];
    idAndIconList?.forEach(el => iconAndIdMap[el.id] = p5.loadImage(el.icon));
    const innerConfigsStr = localStorage.getItem(storageConstants.SKETCH_INNER_CONFIGS);
    innerConfigs = innerConfigsStr ? JSON.parse(innerConfigsStr) : [];
    const outerConfigsStr = localStorage.getItem(storageConstants.SKETCH_OUTER_CONFIGS);
    outerConfigs = outerConfigsStr ? JSON.parse(outerConfigsStr) : [];
    configsArr = [outerConfigs, innerConfigs];
    setConfig(configsArr);
  };

  // const drawCircleBall = (R, inner) => {
  //   if (innerConfigs?.find((c) => c.larger) || outerConfigs?.find((c) => c.larger)) return;
  //   botNum++;
  //   let rad = ((inner ? innerBotAngle : outerBotAngle) * Math.PI) / 180;
  //   let x = R * Math.sin(rad);
  //   let y = R * Math.cos(rad);
  //   if (botNum > 80) bots.shift();
  //   for (var i = 0; i < bots.length; i++) {
  //     bots[i].a = (i + 1) * 0.05 * 100;
  //     bots[i].s = (i + 1) * 0.03;
  //   }
  //   var bb = {
  //     a: 1, 
  //     s: 1, 
  //     x: x, 
  //     y: y 
  //   };
  //   bots.push(bb);
  //   for (let i = 0; i < bots.length; i++) {
  //     let rgb = hexToRgb('#4791ff');
  //     p5.noStroke();
  //     p5.fill(rgb.r, rgb.g, rgb.b, bots[i].a);
  //     p5.ellipse(bots[i].x, bots[i].y, bots[i].s);
  //   }
  // };

  p5.draw = () => {
    if (!innerConfigs.length) return;
    p5.translate(265, 220, bg);
    p5.background(255, 255, 255);
    p5.image(bg, -265, -265, 530, 530);
    // drawCircleBall(118, false);
    // drawCircleBall(187, true);
    // drawCircleBall(265, false);
    innerBotAngle = innerBotAngle + step;
    outerBotAngle = outerBotAngle - step;
    configsArr.forEach((config) => {
      config?.forEach((el, i) => {
        let R = el.R;
        let x, y;
        if (el.larger) {
          if (R <= (el.isInner ? 20 : 20)) {
            el.R += 0.8;
          }
          let largerPlaceR = el.isInner ? el.placeR + 10 : el.placeR + 15;
          x = largerPlaceR * Math.cos(el.rad);
          y = largerPlaceR * Math.sin(el.rad);
        } else {
          let iconR = el.isInner ? innerR : outerR;
          if (R > iconR) {
            el.R -= 1;
          }
          x = el.coordinate[0];
          y = el.coordinate[1];
        }

        let iconRad = el.rad - Math.PI;
        let angleRad = getRadians(el.angle);
        let iconStartX = x + R * Math.cos(iconRad - angleRad);
        let iconStartY = y + R * Math.sin(iconRad - angleRad);
        let iconEndX = x + R * Math.cos(iconRad + angleRad);
        let iconEndY = y + R * Math.sin(iconRad + angleRad);
        el.bezierData[1].head = [iconStartX, iconStartY];
        el.bezierData[0].tail = [iconEndX, iconEndY];
        p5.image(el.icon, x - R, y - R, 2 * R, 2 * R);
        // if(el.maskImage) {
        //   el.icon.mask(el.maskImage);
        // }

         el.bezierData.forEach((data: any, i: number) => {
          let color = i === 0 ? el.startColor : el.endColor;
          if (!color) return;
          drawLine(i, data, el.larger, p5.color(color[0]), p5.color(color[1]), el);
          if (el.larger && el.R >= 20) {
            drawBall(i, data, el.bubbleColor[i], el);
          }
        });
      });
    });
    p5.image(celer, -50, -50, 100, 100);
  };

  const setConfig = (configsArr: Array<IconConfig[]>) => {
    configsArr.forEach((configs) => {
      configs?.forEach((el, i) => {
        let ln = configs.length;
        let agl = (360 / ln) * i;
        let rad = getRadians(agl);
        let x = el.placeR * Math.cos(rad);
        let y = el.placeR * Math.sin(rad);
        el.coordinate = [x, y];
        el.agl = agl;
        el.rad = rad;
        el.icon = iconAndIdMap[el.id];
        // 'ASTR', 'HARMONY', 'Optimistic Kovan'
        // if(['Fantom'].includes(el.symbol)) {
        //   let maskR = el.isInner ? innerR : outerR;
        //   el.maskImage = p5.createGraphics(maskR * 2, maskR * 2);
        //   el.maskImage.fill('red');
        //   el.maskImage.circle(el.R, el.R, 2 * (el.R));
        // }
        setBezier(el);
      });
    });
  };

  const setBezier = (el: IconConfig) => {
    let iconRad = el.rad - Math.PI;
    let angleRad = getRadians(el.angle);
    let x = el.coordinate[0];
    let y = el.coordinate[1];

    let cnbterStartX = (centerR - 15) * Math.cos(el.rad - centrAngleRad);
    let cnbterStartY = (centerR - 15) * Math.sin(el.rad - centrAngleRad); // right
    let cnbterEndX = (centerR - 15) * Math.cos(el.rad + centrAngleRad);
    let cnbterEndY = (centerR - 15) * Math.sin(el.rad + centrAngleRad); // left

    let iconStartX = x + el.R * Math.cos(iconRad - angleRad);
    let iconStartY = y + el.R * Math.sin(iconRad - angleRad); // right
    let iconEndX = x + el.R * Math.cos(iconRad + angleRad);
    let iconEndY = y + el.R * Math.sin(iconRad + angleRad); // left

    let controlR = ((2 / 5) * el.placeR) / Math.cos(getRadians(angle));
    let controlR2 = ((2 / 3) * el.placeR) / Math.cos(getRadians(angle));
    let centerControlX = controlR * Math.cos(getRadians(el.agl - centerAngle));
    let centerControlY = controlR * Math.sin(getRadians(el.agl - centerAngle));
    let centerControlX2 = controlR2 * Math.cos(getRadians(el.agl - centerAngle));
    let centerControlY2 = controlR2 * Math.sin(getRadians(el.agl - centerAngle));

    let iconControlX = controlR2 * Math.cos(getRadians(el.agl + centerAngle));
    let iconControlY = controlR2 * Math.sin(getRadians(el.agl + centerAngle));
    let iconControlX2 = controlR * Math.cos(getRadians(el.agl + centerAngle));
    let iconControlY2 = controlR * Math.sin(getRadians(el.agl + centerAngle));

    const data = [
      {
        head: [cnbterStartX, cnbterStartY],
        tail: [iconEndX, iconEndY],
        control1: [centerControlX, centerControlY],
        control2: [centerControlX2, centerControlY2]
      },
      {
        head: [iconStartX, iconStartY],
        tail: [cnbterEndX, cnbterEndY],
        control1: [iconControlX, iconControlY],
        control2: [iconControlX2, iconControlY2]
      }
    ];
    el.bezierData = data;
  };

  const drawLine = (i: number, data: any, larger: boolean, from: any, to: any, el: IconConfig) => {
    const { head, tail, control1, control2 } = data;
    if (!head || !tail || !control1 || !control2) return;

    let hX = head[0],
      hY = head[1];
    let X = 0;
    let Y = 0;
    for (let tt = 0; tt <= 1; tt += 0.1) {
      X = p5.bezierPoint(head[0], control1[0], control2[0], tail[0], tt);
      Y = p5.bezierPoint(head[1], control1[1], control2[1], tail[1], tt);
      p5.noFill();
      p5.rotate(0);

      if (larger) {
        if (i === 0) {
          p5.stroke(p5.lerpColor(p5.color(getOpacityColor(from, 0.2)), p5.color(getOpacityColor(to, 0.2)), tt));
          p5.strokeWeight(1.5);
          p5.line(hX, hY, X, Y);
          p5.noFill();

          let a = 0;
          p5.push();
          p5.textFont('Courier New', 10); 
          p5.stroke('#000');
          p5.strokeWeight(0.5);
          let rotateX = el.isInner ? head[0] : control1[0];
          let rotateY = el.isInner ? head[1] : control1[1];
          if (rotateX > 0 && rotateY < 0) {
            a = 360 - p5.atan(p5.abs(rotateY / rotateX)); // first
            p5.translate(rotateX, rotateY);
            p5.rotate(a);
            p5.text(`${el.txs} txs`, el.isInner ? 8 : -10 , -5);
          } else if (rotateX > 0 && rotateY > 0) {
            a = p5.atan(p5.abs(rotateY / rotateX)); // forth
            p5.translate(rotateX, rotateY);
            p5.rotate(a);
            p5.text(`${el.txs} txs`, el.isInner ? 8 : -10 , -5);
          } else if (rotateX < 0 && rotateY < 0) {
            const absX = el.isInner ? tail[0] :  control1[0];
            const absY = el.isInner ? tail[1] :  control1[1];
            a = 360 + p5.atan(p5.abs(absY / absX)); // second
            p5.translate(absX, absY);
            p5.rotate(a);
            p5.text(`${el.txs} txs `, el.isInner ? 8 : -35 , 15);
          } else if (rotateX < 0 && rotateY > 0) {
            const absX = el.isInner ? tail[0] :  control1[0];
            const absY = el.isInner ? tail[1] :  control1[1];
            a = 360 - p5.atan(p5.abs(absY / absX)); // third
            p5.translate(absX, absY);
            p5.rotate(a);
            p5.text(`${el.txs} txs `, el.isInner ? 8 : -35 , 15);
          }
          p5.pop();
        } else {
          p5.stroke(p5.lerpColor(to, to, tt));
          p5.strokeWeight(1.5);
          p5.line(hX, hY, X, Y);
          p5.noFill();
        }
      } else {
        p5.stroke(p5.lerpColor(p5.color(getOpacityColor(from, 0.5)), p5.color(getOpacityColor(from, 0.5)), tt));
        p5.strokeWeight(0.2);
        p5.line(hX, hY, X, Y);
      }
      hX = X;
      hY = Y;
    }
  };

  const drawBall = (key: number, data: any, color: string, el: IconConfig) => {
    const { head, tail, control1, control2 } = data;
    if (!head || !tail || !control1 || !control2) return;

    let x = p5.bezierPoint(head[0], control1[0], control2[0], tail[0], el.t);
    let y = p5.bezierPoint(head[1], control1[1], control2[1], tail[1], el.t);
    let bubble: any;
    p5.noStroke();
    p5.noFill();
    p5.fill(color);

    if (key === 0) {
      el.bubbleNum1++;
      bubble = el.bubbles.start;
      if (el.bubbleNum1 > 30) bubble.shift();
    } else {
      el.bubbleNum2++;
      bubble = el.bubbles.end;
      if (el.bubbleNum2 > 30) bubble.shift();
    }

    for (let i = 0; i < bubble.length; i++) {
      bubble[i].a = (i + 1) * 30;
      bubble[i].s = (i + 1) *  0.07;
    }
    let b = { a: 1, s: 1, x, y };
    bubble.push(b);
    for (let i = 0; i < bubble.length; i++) {
      p5.noStroke();
      p5.noFill();
      let rgb = hexToRgb(color);
      p5.fill(rgb.r, rgb.g, rgb.b, bubble[i].a);
      p5.circle(bubble[i].x, bubble[i].y, bubble[i].s);
    }
    if (el.isInner) {
      el.t += el.bubbleT;
    } else {
      el.t += el.bubbleT2;
    }

    if (el.t >= 1) el.t = 0;
  };

  const setInnerChiansSizeSmall = () => {
    innerConfigs.forEach(chain => chain.larger = false)
  }

  p5.mouseMoved = _.debounce(() => {
    let mX = p5.mouseX - 265;
    let mY = p5.mouseY - 220;
    const icons = innerConfigs?.concat(outerConfigs);
    icons?.forEach((el) => {
      const [x, y] = el.coordinate;
      if (mX >= x - el.R && mX <= x + el.R && mY >= y - el.R && mY <= y + el.R) {
        !el.isInner && setInnerChiansSizeSmall();
        el.larger = true;
        configsArr = [innerConfigs, outerConfigs];
      } else if (mX > -265 && mX < 265 && mY > -220 && mY < 220) {
        el.larger = el.isInner;
      }
    });
  }, 10);
}
