import { List, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { createUseStyles } from 'react-jss';
import MobileTransferCard from './MobileTransferCard';

const useStyles = createUseStyles(() => ({
  list: {},
  title: {
    borderBottom: '1px solid #ECEBEE99',
    backgroundColor: '#fff',
    height: 60,
    width: '100%',
    fontWeight: 600,
    fontSize: '0.16rem',
    padding: '18px 16px',
    borderRadius: '16px 16px 0 0',
    marginBottom: 2
  },
  assets: {
    color: '#00D395'
  },
  lastestCard: {
    '&:first-child': {
      borderRadius: '0 0 20px 20px'
    }
  },
  searchCard: {
    // '&:first-child': {
    //   borderRadius: '0 0 20px 20px'
    // }
    borderRadius: 0,
    marginBottom: 2,
  },
}));

function ScrollList(props): JSX.Element {
  const classes = useStyles();
  const { data, total, loadMore, hasMore, handleLoadMore} = props;
    const loadMoreData = () => {
        handleLoadMore();
    }

    if(!loadMore) {
        return <List
        dataSource={data}
        renderItem={item => <MobileTransferCard cls={classes.lastestCard} value={item} />}
      />
    }
    return (
      <div className={classes.list}>
        <div className={classes.title}>Total <span style={{ color: '#0C82FF' }}>{total}</span> items</div>
        <div
          id="scrollableDiv"
          style={{
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <InfiniteScroll
            dataLength={data.length}
            next={loadMoreData}
            hasMore={hasMore}
            loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
            // endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
            scrollableTarget="scrollableDiv"
          >
          <List
            dataSource={data}
            renderItem={item => <MobileTransferCard cls={classes.searchCard} value={item} />}
          />
        </InfiniteScroll>
      </div>
    </div>)
}
export default ScrollList;

ScrollList.defaultProps = {
  data: [],
  page: 1
};
