import React, { useContext, useState } from 'react';
import { Modal } from 'antd';
import { MenuOutlined } from '@ant-design/icons'
import { createUseStyles } from 'react-jss';
import { AppContext } from '../../App';
import logo from '../../imgs/logo.svg';
import { useNavigate } from 'react-router-dom';

const useStyles = createUseStyles<string, { isMobile: boolean }>(() => ({
  header: {
    width: '100%',
    height: 56,
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    zIndex: 1,
    boxShadow: '0px 1px 5px rgb(0 0 0 / 6%)',
  },
  menuIcon: {
    position: 'absolute',
    right: 23,
    color: '#1874FF',
    fontSize: 15,
  },
  logo: {
    width: 180,
    marginLeft: props => props.isMobile ? 16 : 54,
    cursor: 'pointer',
  },
  nav: {
    marginLeft: 67,
    display: 'flex'
  },
  link: {
    fontSize: 14,
    fontWeight: 700,
    marginRight: 64,
    cursor: 'pointer',
    color: '#000',
    '&:focus, &:hover': {
      color: '#2C98F3'
    }
  },
  menuModal: {
    height: 'calc(100vh - 100px)',
    width: '100%',
    maxWidth: '100%',
    
    margin: 0,
    paddingBottom: 0,
    '& .ant-modal-content': {
      borderRadius: '16px 16px 0 0',
      height: '100%'
    },
    '& .ant-modal-body': {
      height: '100%',
      textAlign: 'center',
      position: 'relative',
      paddingTop: 80
    },
  },
  menuItem: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 32,
    color: '#000 !important'
  }
}));

function Header(props): JSX.Element {
  const { isMobile } = useContext(AppContext);
  const navigate = useNavigate();
  const classes = useStyles({isMobile});

  const [ menuVisible, setMenuVisible ] = useState(false);

  const handleMenuClick = () => {
    setMenuVisible(true);
  }

  return (
    <div className={classes.header}>
      <img className={classes.logo} src={logo} alt="" onClick={() => navigate('/')} />
      {!isMobile && <div className={classes.nav}>
        {/* eslint-disable-next-line */}
        <a className={classes.link} style={{ color: '#2C98F3' }} href="/">
          Home
        </a>
        {/* eslint-disable-next-line */}
        <a className={classes.link} onClick={() => window.open('https://im-docs.celer.network', '_blank')}>
          IM Docs
        </a>
        {/* eslint-disable-next-line */}
        <a className={classes.link} onClick={() => window.open('https://sgn.celer.network', '_blank')}>
          SGN
        </a>
      </div>}
      {isMobile && <MenuOutlined className={classes.menuIcon} onClick={handleMenuClick} />}
      <Modal className={classes.menuModal} visible={menuVisible} onCancel={() => setMenuVisible(false)} footer={null}>
        <a className={classes.menuItem} style={{display: 'inline-block' }} href="/">
          Home
        </a>
        <div className={classes.menuItem} onClick={() => window.open('https://im-docs.celer.network', '_blank')}>IM Docs</div>
        <div className={classes.menuItem} onClick={() => window.open('https://sgn.celer.network', '_blank')}>SGN</div>
      </Modal>
    </div>
  );
}

export default Header;
