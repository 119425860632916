import React, { useContext, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { queryLatestTransactions } from '../../redux/gateway';
import TableList from '../common/TableList';
import { TransferPreview } from '../../constants/interfaces';
import { getTokenByChainIdAndAddress } from '../../utils/info';
import { BigNumber } from 'ethers';
import { formatDecimal, safeParseUnits } from 'number-format-utils/lib/format';
import { AppContext } from '../../App';
import { QueryLatestTxTxResponse } from '../../proto/web/web_pb';
import ScrollList from '../common/ScrollList';
import { Spin } from 'antd';

const useStyles = createUseStyles<string, { isMobile: boolean }>(() => ({
  list: {
    marginTop: (props) => props.isMobile ? 16 : 24,
    borderRadius: 16,
    width: '100%'
  },
  title: {
    height: (props) => props.isMobile ? 60 :80,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #ECEBEE',
    width: '100%',
    paddingLeft: (props) => props.isMobile ? 16 : 36,
    fontWeight: 600,
    fontSize: (props) => props.isMobile ? '0.16rem' :20,
    backgroundColor: '#fff',
    borderRadius: '20px 20px 0 0'
  },
  table: {
    borderRadius: '0 0 16px 16px'
  }
}));

function LastestTxsList(): JSX.Element {
  const { isMobile, state } = useContext(AppContext);
  const classes = useStyles({ isMobile });
  const [transferPreviews, setTransferPreview] = useState<TransferPreview[]>([]);
  const { transferConfig } = state;
  const [latestTransactions, setLatestTransactions] = useState<QueryLatestTxTxResponse | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const chainPairBlackList = [
    {srcChainId: 592, dstChainId: 56, tokenAddr: "0x4Bf769b05E832FCdc9053fFFBC78Ca889aCb5E1E"}, 
    {srcChainId: 56, dstChainId: 592, tokenAddr: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"},
  ]

  const prepareAssetTransfer = (amount: string, chainId: number, tokenAddress: string) => {
    if (amount.length === 0) {
      return '';
    }

    const token = getTokenByChainIdAndAddress(chainId, tokenAddress, transferConfig.chain_token);

    const srcBigNum = BigNumber.from(amount || '0');
    const srcAmount = Number(formatDecimal(srcBigNum, 6, token?.decimals).split(',').join(''));
    return srcAmount + ' ' + token?.symbol ?? "";
  };

  useEffect(() => {
    if (!latestTransactions) {
      return;
    }

    setTransferPreview(
      latestTransactions.getTransferPreviewList().filter(transaction => {

        // check if this transaction hits the blacklist 
        let result = true;
        chainPairBlackList.forEach(blackItem => {
          const itemExistsInBlackList = transaction.getTransferInfoList()
            .filter(_ => _.getSrcTokenAddr().toLocaleLowerCase() === blackItem.tokenAddr.toLocaleLowerCase())?.[0];

          if(transaction.getSrcChainId() === blackItem.srcChainId 
            && transaction.getDstChainIdList().includes(blackItem.dstChainId) 
            && itemExistsInBlackList 
            && BigNumber.from(itemExistsInBlackList.getSendAmt()).gt(safeParseUnits("200000", 18))
            ) {
            result = false;
          }
        });
        return result;
      }).map((preview) => {
        let result: TransferPreview;
        result = {
          key: 'chain ' + preview.getSrcChainId() + ':' + preview.getSrcTxHash(),
          sourceChainId: preview.getSrcChainId(),
          senderAddress: preview.getSender(),
          destinationChainIds: preview.getDstChainIdList(),
          sourceTransactionHash: preview.getSrcTxHash(),
          initialTime: preview.getTs() + '',
          assetsTransfer: preview.getTransferInfoList().map(transferInfo => {
            return prepareAssetTransfer(transferInfo.getSendAmt(), preview.getSrcChainId(), transferInfo.getSrcTokenAddr())
          })
        };
        return result;
      })
    );
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferConfig, latestTransactions]);

  useEffect(() => {
    setLoading(true);
    queryLatestTransactions().then((response) => {
      setLatestTransactions(response);
    });
  }, []);

  return (
    <div className={classes.list}>
      <div className={classes.title}>Latest Cross-Chain Transactions</div>
     
      <Spin spinning={loading}>
        {!isMobile && <TableList data={transferPreviews} showPagination={false} cls={classes.table} />}
        {isMobile && <ScrollList data={transferPreviews} showPagination={false} loadMore={false} />}
      </Spin>
    </div>
  );
}

export default LastestTxsList;
