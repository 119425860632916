import { ethers } from 'ethers';

export const isValidAddress = (address) => {
  try {
    ethers.utils.getAddress(address);
    return true;
  } catch (e) {
    return false;
  }
};

export const isValidTxHash = (hash) => {
  return /^0x([A-Fa-f0-9]{64})$/.test(hash);
};
