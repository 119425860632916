import CountUp from 'react-countup';
import moment from 'moment';
import { Tooltip } from 'antd';
import { createUseStyles } from 'react-jss';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../App';

const useStyles = createUseStyles<string, { isMobile: boolean }>(() => ({
    tooltip: {
        '@global': {
          '.ant-tooltip-content': {
            color: '#000',
            backgroundColor: '#fff',
          },
          '.ant-tooltip-inner': {
            color: '#000',
            backgroundColor: '#fff',
            padding: '10px 15px',
            borderRadius: 8
          },
          '.ant-tooltip-arrow-content': {
            backgroundColor: '#fff',
            '--antd-arrow-background-color': '#fff',
          },
        }
      },
}))


const AnimateTimer = (props) => {
    const { isMobile } = useContext(AppContext);
    const classes = useStyles({isMobile});
    const [start, setStart] = useState<number>(0);
    const [end, setEnd] = useState<number>(0);
    const [unit, setUnit] = useState<string>('secs');
    const [dur, setDur] = useState<number>(0);
    const { time } = props;

    useEffect(() => {
        initData(time);
    }, [time])

    const initData = (time) => {
        const passSecs = Number(moment(Number(Date.now()) - Number(time)).format('X'));
        const passMins = Number(passSecs) / 60;
        const passHours = Number(passMins) / 60;
        const passDays = passHours / 24;
        if(passSecs < 60) {
            setStart(passSecs);
            setEnd(60);
            setUnit('secs');
            setDur(60 - passSecs);
        } else if (passMins < 60) {
            setStart(passMins);
            setEnd(60);
            setUnit('mins');
            setDur((60 - passMins) * 60);
        } else if (passHours < 24) {
            setStart(passHours);
            setEnd(24);
            setUnit('hours');
            setDur((24 - passHours) * 60 * 60);
        } else {
            setStart(passDays);
            setEnd(100);
            setUnit('days');
            setDur((100 - passDays) * 60 * 60 * 24);
        }
    }

    return (<span style={{ cursor: 'pointer' }}>
            <Tooltip placement={ isMobile ? 'topRight' : 'top' } overlayClassName={classes.tooltip} title={`${moment(Number(time)).format('MMM-DD-YYYY hh:mm:ss A')} +UTC`}>
                <CountUp start={start} end={end} duration={dur} onEnd={() => initData(time)} /> {unit} ago 
            </Tooltip>
        </span>)
}

export default AnimateTimer;