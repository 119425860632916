import { Chain } from '../constants/interfaces';
import * as Types from './types';

type TransferConfig = {
  chains: Array<Chain>;
  chain_token: any;
};

export const transferConfig: TransferConfig = {
  chains: [],
  chain_token: null
};

export const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.TRANSFER_CONFIG:
      return {
        ...state,
        transferConfig: payload
      };
    case Types.TRANSFER_SUPPORTED_CHAINS_COUNT:
      return {
        ...state,
        count: payload
      }
    default: {
      return state;
    }
  }
};
