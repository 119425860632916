import { Chain, Token } from '../constants/interfaces';
import { readOnlyContract } from '../hooks/customReadyOnlyContractLoader';
import { JsonRpcProvider } from '@ethersproject/providers';
import { Contract } from '@ethersproject/contracts';
import { ERC20__factory } from '../typechain/factories/ERC20__factory';
import { getNetworkById } from '../constants/network';

export const getChainById = (id: number, chains: Chain[]): Chain | undefined => {
  if (!chains) return;
  return chains.find((chain) => {
    return chain.id === id;
  });
};

export const getTokenByChainIdAndAddress = (chainId: number, tokenAddr: string, tokens: any) => {
  if (!chainId || !tokenAddr || !tokens) return;
  let res;
  const t = tokens[chainId];
  if (!t) return;
  t.token.forEach((el) => {
    if (el.token.address === tokenAddr) {
      res = {
        chainId,
        name: el.name,
        address: el.token.address,
        symbol: el.token.symbol,
        decimals: el.token.decimal
      };
    }
  });
  return res;
};

export const getTokenByChainIdAndAddressFromERC20 = async (chainId: number, tokenAddr: string): Promise<Token> => {
  let token = {
    name: '',
    symbol: '',
    address: '',
    chainId: 0,
    decimals: 0,
    xfer_disabled: false
  };
  if (!chainId || !tokenAddr) return token;
  try {
    const customContract = (await readOnlyContract(
      new JsonRpcProvider(getNetworkById(chainId ?? 0).rpcUrl),
      tokenAddr || '',
      ERC20__factory
    )) as Contract | undefined;
    const symbol = await customContract?.symbol();
    const decimal = await customContract?.decimals();
    const name = await customContract?.name();

    token = {
      name: name,
      symbol: symbol,
      address: tokenAddr,
      chainId,
      decimals: decimal,
      xfer_disabled: false
    };
    return token;
  } catch (e) {
    console.log(e);
    return token;
  }
};
