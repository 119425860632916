import React, { useState, useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { AppContext } from '../App';
import OverAll from '../components/home/OverAll';
import LastestTxsList from '../components/home/LatestTxsList';

import SearchBg from '../imgs/searchBg.png';
import Search from '../imgs/search.svg';
import homeTopBgImg from '../imgs/homeTopBgImg.svg';
// import tipsIcon from '../imgs/tipsIcon.svg';
import { Button, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { isValidAddress } from '../utils/validate';

const useStyles = createUseStyles<string, { isMobile: boolean }>(() => ({
  page: {
    position: 'relative',
    backgroundColor: '#F3F3F3'
  },
  bg: {
    height: (props) => (props.isMobile ? 490 : 440),
    // width: '100%',
    backgroundSize: 'cover',
    textAlign: 'center',
    marginTop: 54
  },
  title: {
    color: '#fff',
    fontWeight: 700,
    padding: (props) => (props.isMobile ? '80px 35px 0 35px' : '80px 0 0')
  },
  bigTitle: {
    fontSize: (props) => (props.isMobile ? '0.28rem' : 60),
    lineHeight: (props) => (props.isMobile ? '0.36rem' : '68px'),
    marginBottom: 12
  },
  subTitle: {
    fontSize: (props) => (props.isMobile ? '0.12rem' : 20),
    padding: (props) => (props.isMobile ? '0 20px' : '0'),
    lineHeight: (props) => (props.isMobile ? '0.20rem' : '28px'),
    color: 'rgba(255, 255, 255, 0.8)'
  },
  searchWrap: {
    width: 728,
    height: 68,
    backgroundSize: 'contain',
    marginTop: 35,
    marginLeft: '50%',
    transform: 'translateX(-364px)',
    backgroundRepeat: 'no-repeat',
    '@media (max-width: 960px)': {
      backgroundImage: 'none !important',
      width: '100%',
      transform: 'translateX(-50%)'
    }
  },
  search: {
    width: 720,
    height: (props) => (props.isMobile ? 48 : 60),
    fontSize: (props) => (props.isMobile ? '0.12rem' : 16),
    borderRadius: 30,
    fontWeight: 700,
    marginTop: 4,
    border: 'none',
    '@media (max-width: 960px)': {
      width: '90%'
    },
    '& .ant-input': {
      fontSize: 16,
      marginLeft: 8
    }
  },
  btn: {
    width: (props) => (props.isMobile ? 80 : 120),
    height: (props) => (props.isMobile ? 40 : 52),
    borderRadius: 30,
    backgroundColor: '#000',
    color: '#fff',
    fontSize: (props) => (props.isMobile ? '0.14rem' : 16),
    fontWeight: 700,
    border: 'none',
    marginRight: -5,
    '&:hover': {
      backgroundColor: '#000',
      color: '#fff'
    },
    '&:focus': {
      backgroundColor: '#000',
      color: '#fff'
    }
  },
  container: {
    padding: (props) => (props.isMobile ? '0 15px' : '0 56px'),
    marginTop: (props) => (props.isMobile ? -150 : -90),
    width: '100%'
  },
  tips: {
    width: `100%`,
    fontSize: 18,
    fontWeight: 700,
    padding: '15px 0',
    textAlign: 'center',
    background: '#000',
    color: '#fff',
    top: 56,
    position: 'fixed',
    zIndex: 100
  },
  tipsImg: {
    height: 30,
    marginRight: 10
  }
}));

function Home(props): JSX.Element {
  const { isMobile } = useContext(AppContext);
  const classes = useStyles({ isMobile });
  const [inputValue, setInputValue] = useState('');
  const navigate = useNavigate();

  const onInputSearch = () => {
    if (isValidAddress(inputValue)) {
      navigate(`/account/${inputValue}`);
    } else {
      navigate(`/tx/${inputValue}`);
    }
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
  };

  return (
    <div className={classes.page}>
      {/* <div className={classes.tips}>
        <img src={tipsIcon} className={classes.tipsImg} alt="" />
        Celer IM Scan is currently under maintenance. The service will resume as soon as the maintenance is completed.
      </div> */}
      <div className={classes.bg} style={{ backgroundImage: `url(${homeTopBgImg})` }}>
        <div className={classes.title}>
          <div className={classes.bigTitle}>Explore multi-chain transactions</div>
          <div className={classes.subTitle}>facilitated by Celer Inter-chain Messaging (IM) Framework</div>
        </div>
        <div className={classes.searchWrap} style={{ backgroundImage: `url(${SearchBg})` }}>
          <Input
            className={classes.search}
            placeholder="Search by Address / Txn Hash"
            prefix={<img src={Search} alt="prefix" />}
            suffix={
              <Button className={classes.btn} onClick={onInputSearch}>
                Search
              </Button>
            }
            value={inputValue}
            onChange={onInputChange}
            onPressEnter={onInputSearch}
          />
        </div>
      </div>
      <div className={classes.container}>
        <OverAll />
        <LastestTxsList />
      </div>
    </div>
  );
}

export default Home;
