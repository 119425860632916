import React, { useState, useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { InfoCircleOutlined, LinkOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { BigNumber } from '@ethersproject/bignumber';
import { formatDecimal } from 'number-format-utils/lib/format';
import { AppContext } from '../../App';
import forward from '../../imgs/forward.svg';
import mobileForward from '../../imgs/mobileForward.svg';
import { getChainById, getTokenByChainIdAndAddress, getTokenByChainIdAndAddressFromERC20 } from '../../utils/info';
import { XferStatus } from '../../proto/web/web_pb';
import { getNetworkById } from '../../constants/network';
import { useEffect } from 'react';
import { transactionExplorerMediator } from '../../utils/transactionExplorerUrlMediator';

const useStyles = createUseStyles<string, { isMobile: boolean }>(() => ({
  wrap: {
    fontWeight: 600
  },
  title: {
    fontSize: (props) => props.isMobile ? '0.16rem' : 16,
    fontWeight: 700,
    marginTop: 36,
    marginBottom: 15
  },
  content: {
    borderRadius: 16,
    border: '1px solid #ECEBEE99',
    padding: (props) => props.isMobile ? '12px 8px' : 16
  },
  card: {
    marginTop: 32,
    '&:first-child': {
      marginTop: 0
    }
  },
  cardTitle: {
    color: '#1874FF',
    fontSize: (props) => props.isMobile ? '0.16rem' : 16,
    fontWeight: 700,
    marginBottom: 8
  },
  status: {
    display: 'flex',
    marginBottom: 8,
    fontSize: 14,
    flexWrap: 'wrap',
  },
  label: {
    width: (props) => props.isMobile ? '100%' : 200
  },
  value: {
    flex: 1
  },
  waring: {
    display: 'flex',
    alignItems: 'center'
  },
  tooltip: {
    '& .ant-tooltip-inner': {
      color: '#000',
      fontSize: 12,
      backgroundColor: '#fff'
    },
    '& .ant-tooltip-arrow-content': {
      color: '#000',
      fontSize: 12,
      '--antd-arrow-background-color': '#fff'
    }
  },
  transferContent: {
    backgroundColor: '#F7F7F7',
    borderRadius: 12,
    padding: '16px 12px',
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'

  },
  chain: {
    display: 'flex',
    width:  (props) => props.isMobile ? '100%' : 'auto',
    alignItems: 'center'
  },
  forward: {
    margin:  (props) => props.isMobile ? '10px 0' : '0 15px',
  },
  chainIcon: {
    width: 24,
    height: 24,
    marginRight: 8,
  },
  chainName: {
    fontSize: 14,
    color: '#000'
  },
  ammount: {
    fontSize: 12,
    fontWeight: 500,
    color: '#808080'
  },
  linkIcon: {
    fontSize: 14,
    marginLeft: 5,
    color: '#1874FF'
  }
}));

function AssetsTransfer(props): JSX.Element {
  const { isMobile } = useContext(AppContext);
  const classes = useStyles({ isMobile });
  const { data, srcChain } = props;

  return (
    <div className={classes.wrap}>
      <div className={classes.title}>Assets Transfer</div>
      <div className={classes.content}>
        {data.map((el, idx) => (
          <TransferCard key={el.xferId} data={el} number={idx} srcChain={srcChain} />
        ))}
      </div>
    </div>
  );
}

export default AssetsTransfer;

const TransferCard = (props): JSX.Element => {
  const { state, isMobile } = useContext(AppContext);
  const classes = useStyles({ isMobile });
  const [sendAmount, setSendAmount] = useState<number>(0);
  const [sendTokenSymbol, setSendTokenSymbol] = useState<string>('');
  const [receviedAmount, setReceviedAmount] = useState<number>(0);
  const [receviedTokenSymbol, setReceviedTokenSymbol] = useState<string>('');

  const { data, number, srcChain } = props;
  const { xferStatus, dstChainId, srcTxHash, dstTxHash, dstDelayExecutedTxHash } = data;
  const getStatus = (status) => {
    let lab;
    switch (status) {
      case XferStatus.XS_WAITING_FOR_SGN_CONFIRMATIONS:
        lab = (
          <div className={classes.waring}>
            <span style={{ color: '#FF7D00' }}>Waiting for SGN confirmations</span>{' '}
            <Tooltip
              overlayClassName={classes.tooltip}
              title={<span>The cross-chain assets transfer is being processed in Celer SGN, which might take a few minutes.</span>}>
              <InfoCircleOutlined style={{ fontSize: 13, marginLeft: 6, color: '#C4C4C4' }} />
            </Tooltip>
          </div>
        );
        break;
      case XferStatus.XS_WAITING_FOR_FUND_RELEASE:
        lab = (
          <div className={classes.waring}>
            <span style={{ color: '#FF7D00' }}>Waiting for fund release</span>{' '}
            <Tooltip
              overlayClassName={classes.tooltip}
              title={<span>The assets are waiting to be released on the destination chain, which usually takes a few minutes.</span>}>
              <InfoCircleOutlined style={{ fontSize: 13, marginLeft: 6, color: '#C4C4C4' }} />
            </Tooltip>
          </div>
        );
        break;
      case XferStatus.XS_COMPLETED:
        lab = (
          <div className={classes.waring}>
            <span style={{ color: '#2ED57B' }}>Completed</span>
          </div>
        );
        break;
      case XferStatus.XS_DELAYED:
        lab = (
          <div className={classes.waring}>
            <span style={{ color: '#2ED57B' }}>Delayed</span>
          </div>
        );
        break;
      case XferStatus.XS_TO_BE_REFUND:
      case XferStatus.XS_REFUND_TO_BE_CONFIRMED:
        lab = (
          <div className={classes.waring}>
            <span style={{ color: '#FF7D00' }}>Bridge to be refunded</span>{' '}
            <Tooltip
              overlayClassName={classes.tooltip}
              title={
                <span>
                  {' '}
                  The cross-chain asset transfer fails because the slippage tolerance has been exceeded or there is not enough liquidity on
                  the destination chain. Please refund the transfer manually on the source chain by going to the dApp you initiated this
                  transaction.
                </span>
              }>
              <InfoCircleOutlined style={{ fontSize: 13, marginLeft: 6, color: '#C4C4C4' }} />
            </Tooltip>
          </div>
        );
        break;
      case XferStatus.XS_REFUNDED:
        lab = (
          <div className={classes.waring}>
            <span style={{ color: '#000' }}>Bridge refunded</span>
          </div>
        );
        break;
      default:
        break;
    }
    return lab;
  };
  const destinationChain = getChainById(dstChainId, state?.transferConfig?.chains);

  useEffect(() => {
    getAddrToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, srcChain]);

  // const getDstAmount = (xferStatus, amount) => {
  //   if(xferStatus === XferStatus.XS_REFUNDED || xferStatus === XferStatus.XS_TO_BE_REFUND) return <span>--</span>
  //   if()
  //   return null;
  //   // {(xferStatus === XferStatus.XS_REFUNDED || xferStatus === XferStatus.XS_TO_BE_REFUND) ? 
  //   //   <span>--</span> :
  //   //   <span style={{ color: '#2ED57B' }}>
  //   //    +{receviedAmount} {receviedTokenSymbol}
  //   //  </span>}
  // }

  const getAddrToken = async () => {
    let srcToken;
    let dstToken;
    const { sendAmt, receivedAmt, srcTokenAddr, dstTokenAddr, dstChainId } = data;
    srcToken = getTokenByChainIdAndAddress(srcChain?.id, srcTokenAddr, state.transferConfig.chain_token);
    dstToken = getTokenByChainIdAndAddress(dstChainId, dstTokenAddr, state.transferConfig.chain_token);
    if (!srcToken) {
      srcToken = await getTokenByChainIdAndAddressFromERC20(srcChain?.id, srcTokenAddr);
    }
    if (!dstToken) {
      dstToken = await getTokenByChainIdAndAddressFromERC20(dstChainId?.id, dstTokenAddr);
    }

    if (!srcToken || !dstToken) return;

    const srcBigNum = BigNumber.from(sendAmt || '0');
    const srcAmount = Number(formatDecimal(srcBigNum, 6, srcToken?.decimals).split(',').join(''));
    setSendAmount(srcAmount);
    setSendTokenSymbol(srcToken?.symbol);
    
    const dstBigNum = BigNumber.from(receivedAmt || '0');
    const dstAmount = Number(formatDecimal(dstBigNum, 6, dstToken?.decimals).split(',').join(''));
    setReceviedAmount(dstAmount);
    setReceviedTokenSymbol(dstToken?.symbol);
  };

  return (
    <div className={classes.card}>
      <div className={classes.cardTitle}>Transfer #{number + 1}</div>
      <div className={classes.status}>
        <div className={classes.label}>Status:</div>
        <div className={classes.value}>{getStatus(xferStatus)}</div>
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <div className={classes.transferContent} style={{ flex: 1 }}>
          <div className={classes.chain}>
            <img className={classes.chainIcon} src={srcChain?.icon} alt="" />
            <div>
              <div className={classes.chainName}>
                {srcChain?.name}
                {srcTxHash &&
                <a
                  href={getNetworkById(Number(srcChain?.id)).blockExplorerUrl + transactionExplorerMediator(srcChain?.id ?? 0) + srcTxHash}
                  target="_blank"
                  rel="noreferrer">
                  <LinkOutlined className={classes.linkIcon} />
                </a>}
              </div>
              <div className={classes.ammount}>
                Send Amount:{' '}
                {sendAmount && sendTokenSymbol && <span style={{ color: '#fd5657' }}>
                  -{sendAmount} {sendTokenSymbol}
                </span>}
              </div>
            </div>
          </div>
          <img className={classes.forward} src={isMobile ? mobileForward : forward} alt="" />
          <div className={classes.chain}>
            <img className={classes.chainIcon} src={destinationChain?.icon} alt="" />
            <div>
              <div className={classes.chainName}>
                {destinationChain?.name}
                {dstTxHash &&
                <a
                  href={
                    getNetworkById(Number(destinationChain?.id)).blockExplorerUrl +
                    transactionExplorerMediator(destinationChain?.id ?? 0) +
                    dstTxHash
                  }
                  target="_blank"
                  rel="noreferrer">
                  <LinkOutlined className={classes.linkIcon} />
                </a>}
              </div>
              <div className={classes.ammount}>
                Received amount:{' '}
                {receviedAmount && receviedTokenSymbol && ((xferStatus === XferStatus.XS_REFUNDED || xferStatus === XferStatus.XS_TO_BE_REFUND) ? 
                 <span>--</span> :
                 <span style={{ color: '#2ED57B' }}>
                  +{receviedAmount > 0.000001 ? receviedAmount : 0} {receviedTokenSymbol}
                </span>)}
              </div>
            </div>
          </div>
          {dstDelayExecutedTxHash && <div className={classes.delay} style={{ width: '100%', display: 'flex' }}>
            {!isMobile && <div style={{ width: '50%' }}/>}
            <div className={classes.ammount} style={{ marginLeft: isMobile ? 32 : 26 }}>
              Delay executed link:{' '} 
                <a
                  href={
                    getNetworkById(Number(destinationChain?.id)).blockExplorerUrl +
                    transactionExplorerMediator(destinationChain?.id ?? 0) +
                    dstDelayExecutedTxHash
                  }
                  target="_blank"
                  rel="noreferrer">
                  <LinkOutlined className={classes.linkIcon} />
                </a>
              </div>
          </div>}
        </div>
        {!isMobile && <div style={{ flex: 1 }} />}
      </div>
    </div>
  );
};
