// source: web.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_api_annotations_pb = require('./google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
goog.exportSymbol('proto.imscan.BaseInfo', null, global);
goog.exportSymbol('proto.imscan.DailyStat', null, global);
goog.exportSymbol('proto.imscan.ErrCode', null, global);
goog.exportSymbol('proto.imscan.ErrMsg', null, global);
goog.exportSymbol('proto.imscan.GetOverallStatsRequest', null, global);
goog.exportSymbol('proto.imscan.GetOverallStatsResponse', null, global);
goog.exportSymbol('proto.imscan.GetPerSrcPerDstTableRequest', null, global);
goog.exportSymbol('proto.imscan.GetPerSrcPerDstTableResponse', null, global);
goog.exportSymbol('proto.imscan.GetStatsWithFilterRequest', null, global);
goog.exportSymbol('proto.imscan.GetStatsWithFilterResponse', null, global);
goog.exportSymbol('proto.imscan.IMStatusRequest', null, global);
goog.exportSymbol('proto.imscan.IMStatusResponse', null, global);
goog.exportSymbol('proto.imscan.ImQueryAuth', null, global);
goog.exportSymbol('proto.imscan.ImQuerySignData', null, global);
goog.exportSymbol('proto.imscan.Message', null, global);
goog.exportSymbol('proto.imscan.MsgStatus', null, global);
goog.exportSymbol('proto.imscan.PerSrcDstChainMsgData', null, global);
goog.exportSymbol('proto.imscan.PerSrcPerDstData', null, global);
goog.exportSymbol('proto.imscan.QueryLatestTxRequest', null, global);
goog.exportSymbol('proto.imscan.QueryLatestTxTxResponse', null, global);
goog.exportSymbol('proto.imscan.SearchByAddrRequest', null, global);
goog.exportSymbol('proto.imscan.SearchByAddrResponse', null, global);
goog.exportSymbol('proto.imscan.SearchByTxHashRequest', null, global);
goog.exportSymbol('proto.imscan.SearchByTxHashResponse', null, global);
goog.exportSymbol('proto.imscan.SyncDataRequest', null, global);
goog.exportSymbol('proto.imscan.SyncDataeResponse', null, global);
goog.exportSymbol('proto.imscan.Transfer', null, global);
goog.exportSymbol('proto.imscan.TransferPreview', null, global);
goog.exportSymbol('proto.imscan.TransferPreviewInfo', null, global);
goog.exportSymbol('proto.imscan.TxSearchInfo', null, global);
goog.exportSymbol('proto.imscan.XferStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.SyncDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.imscan.SyncDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.SyncDataRequest.displayName = 'proto.imscan.SyncDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.SyncDataeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.imscan.SyncDataeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.SyncDataeResponse.displayName = 'proto.imscan.SyncDataeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.PerSrcDstChainMsgData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.imscan.PerSrcDstChainMsgData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.PerSrcDstChainMsgData.displayName = 'proto.imscan.PerSrcDstChainMsgData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.GetPerSrcPerDstTableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.imscan.GetPerSrcPerDstTableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.GetPerSrcPerDstTableRequest.displayName = 'proto.imscan.GetPerSrcPerDstTableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.GetPerSrcPerDstTableResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.imscan.GetPerSrcPerDstTableResponse.repeatedFields_, null);
};
goog.inherits(proto.imscan.GetPerSrcPerDstTableResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.GetPerSrcPerDstTableResponse.displayName = 'proto.imscan.GetPerSrcPerDstTableResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.PerSrcPerDstData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.imscan.PerSrcPerDstData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.PerSrcPerDstData.displayName = 'proto.imscan.PerSrcPerDstData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.GetStatsWithFilterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.imscan.GetStatsWithFilterRequest.repeatedFields_, null);
};
goog.inherits(proto.imscan.GetStatsWithFilterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.GetStatsWithFilterRequest.displayName = 'proto.imscan.GetStatsWithFilterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.GetStatsWithFilterResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.imscan.GetStatsWithFilterResponse.repeatedFields_, null);
};
goog.inherits(proto.imscan.GetStatsWithFilterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.GetStatsWithFilterResponse.displayName = 'proto.imscan.GetStatsWithFilterResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.DailyStat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.imscan.DailyStat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.DailyStat.displayName = 'proto.imscan.DailyStat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.GetOverallStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.imscan.GetOverallStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.GetOverallStatsRequest.displayName = 'proto.imscan.GetOverallStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.GetOverallStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.imscan.GetOverallStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.GetOverallStatsResponse.displayName = 'proto.imscan.GetOverallStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.IMStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.imscan.IMStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.IMStatusRequest.displayName = 'proto.imscan.IMStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.IMStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.imscan.IMStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.IMStatusResponse.displayName = 'proto.imscan.IMStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.QueryLatestTxRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.imscan.QueryLatestTxRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.QueryLatestTxRequest.displayName = 'proto.imscan.QueryLatestTxRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.QueryLatestTxTxResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.imscan.QueryLatestTxTxResponse.repeatedFields_, null);
};
goog.inherits(proto.imscan.QueryLatestTxTxResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.QueryLatestTxTxResponse.displayName = 'proto.imscan.QueryLatestTxTxResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.SearchByTxHashRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.imscan.SearchByTxHashRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.SearchByTxHashRequest.displayName = 'proto.imscan.SearchByTxHashRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.SearchByTxHashResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.imscan.SearchByTxHashResponse.repeatedFields_, null);
};
goog.inherits(proto.imscan.SearchByTxHashResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.SearchByTxHashResponse.displayName = 'proto.imscan.SearchByTxHashResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.TxSearchInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.imscan.TxSearchInfo.repeatedFields_, null);
};
goog.inherits(proto.imscan.TxSearchInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.TxSearchInfo.displayName = 'proto.imscan.TxSearchInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.SearchByAddrRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.imscan.SearchByAddrRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.SearchByAddrRequest.displayName = 'proto.imscan.SearchByAddrRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.SearchByAddrResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.imscan.SearchByAddrResponse.repeatedFields_, null);
};
goog.inherits(proto.imscan.SearchByAddrResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.SearchByAddrResponse.displayName = 'proto.imscan.SearchByAddrResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.TransferPreview = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.imscan.TransferPreview.repeatedFields_, null);
};
goog.inherits(proto.imscan.TransferPreview, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.TransferPreview.displayName = 'proto.imscan.TransferPreview';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.TransferPreviewInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.imscan.TransferPreviewInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.TransferPreviewInfo.displayName = 'proto.imscan.TransferPreviewInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.Transfer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.imscan.Transfer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.Transfer.displayName = 'proto.imscan.Transfer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.Message = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.imscan.Message, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.Message.displayName = 'proto.imscan.Message';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.BaseInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.imscan.BaseInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.BaseInfo.displayName = 'proto.imscan.BaseInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.ImQueryAuth = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.imscan.ImQueryAuth, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.ImQueryAuth.displayName = 'proto.imscan.ImQueryAuth';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.ImQuerySignData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.imscan.ImQuerySignData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.ImQuerySignData.displayName = 'proto.imscan.ImQuerySignData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.imscan.ErrMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.imscan.ErrMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.imscan.ErrMsg.displayName = 'proto.imscan.ErrMsg';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.SyncDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.SyncDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.SyncDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.SyncDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0),
    day: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.SyncDataRequest}
 */
proto.imscan.SyncDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.SyncDataRequest;
  return proto.imscan.SyncDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.SyncDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.SyncDataRequest}
 */
proto.imscan.SyncDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.SyncDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.SyncDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.SyncDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.SyncDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 year = 1;
 * @return {number}
 */
proto.imscan.SyncDataRequest.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.SyncDataRequest} returns this
 */
proto.imscan.SyncDataRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 month = 2;
 * @return {number}
 */
proto.imscan.SyncDataRequest.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.SyncDataRequest} returns this
 */
proto.imscan.SyncDataRequest.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 day = 3;
 * @return {number}
 */
proto.imscan.SyncDataRequest.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.SyncDataRequest} returns this
 */
proto.imscan.SyncDataRequest.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.SyncDataeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.SyncDataeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.SyncDataeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.SyncDataeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    err: (f = msg.getErr()) && proto.imscan.ErrMsg.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.SyncDataeResponse}
 */
proto.imscan.SyncDataeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.SyncDataeResponse;
  return proto.imscan.SyncDataeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.SyncDataeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.SyncDataeResponse}
 */
proto.imscan.SyncDataeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.imscan.ErrMsg;
      reader.readMessage(value,proto.imscan.ErrMsg.deserializeBinaryFromReader);
      msg.setErr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.SyncDataeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.SyncDataeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.SyncDataeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.SyncDataeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.imscan.ErrMsg.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrMsg err = 1;
 * @return {?proto.imscan.ErrMsg}
 */
proto.imscan.SyncDataeResponse.prototype.getErr = function() {
  return /** @type{?proto.imscan.ErrMsg} */ (
    jspb.Message.getWrapperField(this, proto.imscan.ErrMsg, 1));
};


/**
 * @param {?proto.imscan.ErrMsg|undefined} value
 * @return {!proto.imscan.SyncDataeResponse} returns this
*/
proto.imscan.SyncDataeResponse.prototype.setErr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.imscan.SyncDataeResponse} returns this
 */
proto.imscan.SyncDataeResponse.prototype.clearErr = function() {
  return this.setErr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.imscan.SyncDataeResponse.prototype.hasErr = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.PerSrcDstChainMsgData.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.PerSrcDstChainMsgData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.PerSrcDstChainMsgData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.PerSrcDstChainMsgData.toObject = function(includeInstance, msg) {
  var f, obj = {
    srcChainId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dstChainId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    msgCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    msgFeeUsd: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.PerSrcDstChainMsgData}
 */
proto.imscan.PerSrcDstChainMsgData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.PerSrcDstChainMsgData;
  return proto.imscan.PerSrcDstChainMsgData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.PerSrcDstChainMsgData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.PerSrcDstChainMsgData}
 */
proto.imscan.PerSrcDstChainMsgData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSrcChainId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDstChainId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMsgCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMsgFeeUsd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.PerSrcDstChainMsgData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.PerSrcDstChainMsgData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.PerSrcDstChainMsgData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.PerSrcDstChainMsgData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSrcChainId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getDstChainId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getMsgCount();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getMsgFeeUsd();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional uint64 src_chain_id = 1;
 * @return {number}
 */
proto.imscan.PerSrcDstChainMsgData.prototype.getSrcChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.PerSrcDstChainMsgData} returns this
 */
proto.imscan.PerSrcDstChainMsgData.prototype.setSrcChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 dst_chain_id = 2;
 * @return {number}
 */
proto.imscan.PerSrcDstChainMsgData.prototype.getDstChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.PerSrcDstChainMsgData} returns this
 */
proto.imscan.PerSrcDstChainMsgData.prototype.setDstChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 msg_count = 3;
 * @return {number}
 */
proto.imscan.PerSrcDstChainMsgData.prototype.getMsgCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.PerSrcDstChainMsgData} returns this
 */
proto.imscan.PerSrcDstChainMsgData.prototype.setMsgCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double msg_fee_usd = 4;
 * @return {number}
 */
proto.imscan.PerSrcDstChainMsgData.prototype.getMsgFeeUsd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.PerSrcDstChainMsgData} returns this
 */
proto.imscan.PerSrcDstChainMsgData.prototype.setMsgFeeUsd = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.GetPerSrcPerDstTableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.GetPerSrcPerDstTableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.GetPerSrcPerDstTableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.GetPerSrcPerDstTableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto.imscan.ImQueryAuth.toObject(includeInstance, f),
    startTimeInSecond: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endTimeInSecond: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.GetPerSrcPerDstTableRequest}
 */
proto.imscan.GetPerSrcPerDstTableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.GetPerSrcPerDstTableRequest;
  return proto.imscan.GetPerSrcPerDstTableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.GetPerSrcPerDstTableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.GetPerSrcPerDstTableRequest}
 */
proto.imscan.GetPerSrcPerDstTableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.imscan.ImQueryAuth;
      reader.readMessage(value,proto.imscan.ImQueryAuth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTimeInSecond(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTimeInSecond(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.GetPerSrcPerDstTableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.GetPerSrcPerDstTableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.GetPerSrcPerDstTableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.GetPerSrcPerDstTableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.imscan.ImQueryAuth.serializeBinaryToWriter
    );
  }
  f = message.getStartTimeInSecond();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEndTimeInSecond();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional ImQueryAuth auth = 1;
 * @return {?proto.imscan.ImQueryAuth}
 */
proto.imscan.GetPerSrcPerDstTableRequest.prototype.getAuth = function() {
  return /** @type{?proto.imscan.ImQueryAuth} */ (
    jspb.Message.getWrapperField(this, proto.imscan.ImQueryAuth, 1));
};


/**
 * @param {?proto.imscan.ImQueryAuth|undefined} value
 * @return {!proto.imscan.GetPerSrcPerDstTableRequest} returns this
*/
proto.imscan.GetPerSrcPerDstTableRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.imscan.GetPerSrcPerDstTableRequest} returns this
 */
proto.imscan.GetPerSrcPerDstTableRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.imscan.GetPerSrcPerDstTableRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 start_time_in_second = 2;
 * @return {number}
 */
proto.imscan.GetPerSrcPerDstTableRequest.prototype.getStartTimeInSecond = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.GetPerSrcPerDstTableRequest} returns this
 */
proto.imscan.GetPerSrcPerDstTableRequest.prototype.setStartTimeInSecond = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 end_time_in_second = 3;
 * @return {number}
 */
proto.imscan.GetPerSrcPerDstTableRequest.prototype.getEndTimeInSecond = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.GetPerSrcPerDstTableRequest} returns this
 */
proto.imscan.GetPerSrcPerDstTableRequest.prototype.setEndTimeInSecond = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.imscan.GetPerSrcPerDstTableResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.GetPerSrcPerDstTableResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.GetPerSrcPerDstTableResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.GetPerSrcPerDstTableResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.GetPerSrcPerDstTableResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    err: (f = msg.getErr()) && proto.imscan.ErrMsg.toObject(includeInstance, f),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.imscan.PerSrcPerDstData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.GetPerSrcPerDstTableResponse}
 */
proto.imscan.GetPerSrcPerDstTableResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.GetPerSrcPerDstTableResponse;
  return proto.imscan.GetPerSrcPerDstTableResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.GetPerSrcPerDstTableResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.GetPerSrcPerDstTableResponse}
 */
proto.imscan.GetPerSrcPerDstTableResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.imscan.ErrMsg;
      reader.readMessage(value,proto.imscan.ErrMsg.deserializeBinaryFromReader);
      msg.setErr(value);
      break;
    case 2:
      var value = new proto.imscan.PerSrcPerDstData;
      reader.readMessage(value,proto.imscan.PerSrcPerDstData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.GetPerSrcPerDstTableResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.GetPerSrcPerDstTableResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.GetPerSrcPerDstTableResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.GetPerSrcPerDstTableResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.imscan.ErrMsg.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.imscan.PerSrcPerDstData.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrMsg err = 1;
 * @return {?proto.imscan.ErrMsg}
 */
proto.imscan.GetPerSrcPerDstTableResponse.prototype.getErr = function() {
  return /** @type{?proto.imscan.ErrMsg} */ (
    jspb.Message.getWrapperField(this, proto.imscan.ErrMsg, 1));
};


/**
 * @param {?proto.imscan.ErrMsg|undefined} value
 * @return {!proto.imscan.GetPerSrcPerDstTableResponse} returns this
*/
proto.imscan.GetPerSrcPerDstTableResponse.prototype.setErr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.imscan.GetPerSrcPerDstTableResponse} returns this
 */
proto.imscan.GetPerSrcPerDstTableResponse.prototype.clearErr = function() {
  return this.setErr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.imscan.GetPerSrcPerDstTableResponse.prototype.hasErr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated PerSrcPerDstData data = 2;
 * @return {!Array<!proto.imscan.PerSrcPerDstData>}
 */
proto.imscan.GetPerSrcPerDstTableResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.imscan.PerSrcPerDstData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.imscan.PerSrcPerDstData, 2));
};


/**
 * @param {!Array<!proto.imscan.PerSrcPerDstData>} value
 * @return {!proto.imscan.GetPerSrcPerDstTableResponse} returns this
*/
proto.imscan.GetPerSrcPerDstTableResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.imscan.PerSrcPerDstData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.imscan.PerSrcPerDstData}
 */
proto.imscan.GetPerSrcPerDstTableResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.imscan.PerSrcPerDstData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.imscan.GetPerSrcPerDstTableResponse} returns this
 */
proto.imscan.GetPerSrcPerDstTableResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.PerSrcPerDstData.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.PerSrcPerDstData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.PerSrcPerDstData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.PerSrcPerDstData.toObject = function(includeInstance, msg) {
  var f, obj = {
    srcChainId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dstChainId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    msgCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    msgFeeUsd: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.PerSrcPerDstData}
 */
proto.imscan.PerSrcPerDstData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.PerSrcPerDstData;
  return proto.imscan.PerSrcPerDstData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.PerSrcPerDstData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.PerSrcPerDstData}
 */
proto.imscan.PerSrcPerDstData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSrcChainId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDstChainId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMsgCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMsgFeeUsd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.PerSrcPerDstData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.PerSrcPerDstData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.PerSrcPerDstData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.PerSrcPerDstData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSrcChainId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getDstChainId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getMsgCount();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getMsgFeeUsd();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional uint64 src_chain_id = 1;
 * @return {number}
 */
proto.imscan.PerSrcPerDstData.prototype.getSrcChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.PerSrcPerDstData} returns this
 */
proto.imscan.PerSrcPerDstData.prototype.setSrcChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 dst_chain_id = 2;
 * @return {number}
 */
proto.imscan.PerSrcPerDstData.prototype.getDstChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.PerSrcPerDstData} returns this
 */
proto.imscan.PerSrcPerDstData.prototype.setDstChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 msg_count = 3;
 * @return {number}
 */
proto.imscan.PerSrcPerDstData.prototype.getMsgCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.PerSrcPerDstData} returns this
 */
proto.imscan.PerSrcPerDstData.prototype.setMsgCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double msg_fee_usd = 4;
 * @return {number}
 */
proto.imscan.PerSrcPerDstData.prototype.getMsgFeeUsd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.PerSrcPerDstData} returns this
 */
proto.imscan.PerSrcPerDstData.prototype.setMsgFeeUsd = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.imscan.GetStatsWithFilterRequest.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.GetStatsWithFilterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.GetStatsWithFilterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.GetStatsWithFilterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.GetStatsWithFilterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto.imscan.ImQueryAuth.toObject(includeInstance, f),
    srcChainsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    dstChainsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    startTimeInSecond: jspb.Message.getFieldWithDefault(msg, 4, 0),
    endTimeInSecond: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.GetStatsWithFilterRequest}
 */
proto.imscan.GetStatsWithFilterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.GetStatsWithFilterRequest;
  return proto.imscan.GetStatsWithFilterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.GetStatsWithFilterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.GetStatsWithFilterRequest}
 */
proto.imscan.GetStatsWithFilterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.imscan.ImQueryAuth;
      reader.readMessage(value,proto.imscan.ImQueryAuth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSrcChains(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDstChains(values[i]);
      }
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTimeInSecond(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTimeInSecond(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.GetStatsWithFilterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.GetStatsWithFilterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.GetStatsWithFilterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.GetStatsWithFilterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.imscan.ImQueryAuth.serializeBinaryToWriter
    );
  }
  f = message.getSrcChainsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      2,
      f
    );
  }
  f = message.getDstChainsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      3,
      f
    );
  }
  f = message.getStartTimeInSecond();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getEndTimeInSecond();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional ImQueryAuth auth = 1;
 * @return {?proto.imscan.ImQueryAuth}
 */
proto.imscan.GetStatsWithFilterRequest.prototype.getAuth = function() {
  return /** @type{?proto.imscan.ImQueryAuth} */ (
    jspb.Message.getWrapperField(this, proto.imscan.ImQueryAuth, 1));
};


/**
 * @param {?proto.imscan.ImQueryAuth|undefined} value
 * @return {!proto.imscan.GetStatsWithFilterRequest} returns this
*/
proto.imscan.GetStatsWithFilterRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.imscan.GetStatsWithFilterRequest} returns this
 */
proto.imscan.GetStatsWithFilterRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.imscan.GetStatsWithFilterRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated uint64 src_chains = 2;
 * @return {!Array<number>}
 */
proto.imscan.GetStatsWithFilterRequest.prototype.getSrcChainsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.imscan.GetStatsWithFilterRequest} returns this
 */
proto.imscan.GetStatsWithFilterRequest.prototype.setSrcChainsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.imscan.GetStatsWithFilterRequest} returns this
 */
proto.imscan.GetStatsWithFilterRequest.prototype.addSrcChains = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.imscan.GetStatsWithFilterRequest} returns this
 */
proto.imscan.GetStatsWithFilterRequest.prototype.clearSrcChainsList = function() {
  return this.setSrcChainsList([]);
};


/**
 * repeated uint64 dst_chains = 3;
 * @return {!Array<number>}
 */
proto.imscan.GetStatsWithFilterRequest.prototype.getDstChainsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.imscan.GetStatsWithFilterRequest} returns this
 */
proto.imscan.GetStatsWithFilterRequest.prototype.setDstChainsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.imscan.GetStatsWithFilterRequest} returns this
 */
proto.imscan.GetStatsWithFilterRequest.prototype.addDstChains = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.imscan.GetStatsWithFilterRequest} returns this
 */
proto.imscan.GetStatsWithFilterRequest.prototype.clearDstChainsList = function() {
  return this.setDstChainsList([]);
};


/**
 * optional int64 start_time_in_second = 4;
 * @return {number}
 */
proto.imscan.GetStatsWithFilterRequest.prototype.getStartTimeInSecond = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.GetStatsWithFilterRequest} returns this
 */
proto.imscan.GetStatsWithFilterRequest.prototype.setStartTimeInSecond = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 end_time_in_second = 5;
 * @return {number}
 */
proto.imscan.GetStatsWithFilterRequest.prototype.getEndTimeInSecond = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.GetStatsWithFilterRequest} returns this
 */
proto.imscan.GetStatsWithFilterRequest.prototype.setEndTimeInSecond = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.imscan.GetStatsWithFilterResponse.repeatedFields_ = [21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.GetStatsWithFilterResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.GetStatsWithFilterResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.GetStatsWithFilterResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.GetStatsWithFilterResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    err: (f = msg.getErr()) && proto.imscan.ErrMsg.toObject(includeInstance, f),
    msgCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    msgFeeUsd: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    dailyStatList: jspb.Message.toObjectList(msg.getDailyStatList(),
    proto.imscan.DailyStat.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.GetStatsWithFilterResponse}
 */
proto.imscan.GetStatsWithFilterResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.GetStatsWithFilterResponse;
  return proto.imscan.GetStatsWithFilterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.GetStatsWithFilterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.GetStatsWithFilterResponse}
 */
proto.imscan.GetStatsWithFilterResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.imscan.ErrMsg;
      reader.readMessage(value,proto.imscan.ErrMsg.deserializeBinaryFromReader);
      msg.setErr(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMsgCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMsgFeeUsd(value);
      break;
    case 21:
      var value = new proto.imscan.DailyStat;
      reader.readMessage(value,proto.imscan.DailyStat.deserializeBinaryFromReader);
      msg.addDailyStat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.GetStatsWithFilterResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.GetStatsWithFilterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.GetStatsWithFilterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.GetStatsWithFilterResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.imscan.ErrMsg.serializeBinaryToWriter
    );
  }
  f = message.getMsgCount();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getMsgFeeUsd();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getDailyStatList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.imscan.DailyStat.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrMsg err = 1;
 * @return {?proto.imscan.ErrMsg}
 */
proto.imscan.GetStatsWithFilterResponse.prototype.getErr = function() {
  return /** @type{?proto.imscan.ErrMsg} */ (
    jspb.Message.getWrapperField(this, proto.imscan.ErrMsg, 1));
};


/**
 * @param {?proto.imscan.ErrMsg|undefined} value
 * @return {!proto.imscan.GetStatsWithFilterResponse} returns this
*/
proto.imscan.GetStatsWithFilterResponse.prototype.setErr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.imscan.GetStatsWithFilterResponse} returns this
 */
proto.imscan.GetStatsWithFilterResponse.prototype.clearErr = function() {
  return this.setErr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.imscan.GetStatsWithFilterResponse.prototype.hasErr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 msg_count = 2;
 * @return {number}
 */
proto.imscan.GetStatsWithFilterResponse.prototype.getMsgCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.GetStatsWithFilterResponse} returns this
 */
proto.imscan.GetStatsWithFilterResponse.prototype.setMsgCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double msg_fee_usd = 3;
 * @return {number}
 */
proto.imscan.GetStatsWithFilterResponse.prototype.getMsgFeeUsd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.GetStatsWithFilterResponse} returns this
 */
proto.imscan.GetStatsWithFilterResponse.prototype.setMsgFeeUsd = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * repeated DailyStat daily_stat = 21;
 * @return {!Array<!proto.imscan.DailyStat>}
 */
proto.imscan.GetStatsWithFilterResponse.prototype.getDailyStatList = function() {
  return /** @type{!Array<!proto.imscan.DailyStat>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.imscan.DailyStat, 21));
};


/**
 * @param {!Array<!proto.imscan.DailyStat>} value
 * @return {!proto.imscan.GetStatsWithFilterResponse} returns this
*/
proto.imscan.GetStatsWithFilterResponse.prototype.setDailyStatList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.imscan.DailyStat=} opt_value
 * @param {number=} opt_index
 * @return {!proto.imscan.DailyStat}
 */
proto.imscan.GetStatsWithFilterResponse.prototype.addDailyStat = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.imscan.DailyStat, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.imscan.GetStatsWithFilterResponse} returns this
 */
proto.imscan.GetStatsWithFilterResponse.prototype.clearDailyStatList = function() {
  return this.setDailyStatList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.DailyStat.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.DailyStat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.DailyStat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.DailyStat.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: jspb.Message.getFieldWithDefault(msg, 1, 0),
    msgCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    msgFeeUsd: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.DailyStat}
 */
proto.imscan.DailyStat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.DailyStat;
  return proto.imscan.DailyStat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.DailyStat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.DailyStat}
 */
proto.imscan.DailyStat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMsgCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMsgFeeUsd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.DailyStat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.DailyStat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.DailyStat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.DailyStat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMsgCount();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getMsgFeeUsd();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional int64 time = 1;
 * @return {number}
 */
proto.imscan.DailyStat.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.DailyStat} returns this
 */
proto.imscan.DailyStat.prototype.setTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 msg_count = 2;
 * @return {number}
 */
proto.imscan.DailyStat.prototype.getMsgCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.DailyStat} returns this
 */
proto.imscan.DailyStat.prototype.setMsgCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double msg_fee_usd = 3;
 * @return {number}
 */
proto.imscan.DailyStat.prototype.getMsgFeeUsd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.DailyStat} returns this
 */
proto.imscan.DailyStat.prototype.setMsgFeeUsd = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.GetOverallStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.GetOverallStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.GetOverallStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.GetOverallStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto.imscan.ImQueryAuth.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.GetOverallStatsRequest}
 */
proto.imscan.GetOverallStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.GetOverallStatsRequest;
  return proto.imscan.GetOverallStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.GetOverallStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.GetOverallStatsRequest}
 */
proto.imscan.GetOverallStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.imscan.ImQueryAuth;
      reader.readMessage(value,proto.imscan.ImQueryAuth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.GetOverallStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.GetOverallStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.GetOverallStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.GetOverallStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.imscan.ImQueryAuth.serializeBinaryToWriter
    );
  }
};


/**
 * optional ImQueryAuth auth = 1;
 * @return {?proto.imscan.ImQueryAuth}
 */
proto.imscan.GetOverallStatsRequest.prototype.getAuth = function() {
  return /** @type{?proto.imscan.ImQueryAuth} */ (
    jspb.Message.getWrapperField(this, proto.imscan.ImQueryAuth, 1));
};


/**
 * @param {?proto.imscan.ImQueryAuth|undefined} value
 * @return {!proto.imscan.GetOverallStatsRequest} returns this
*/
proto.imscan.GetOverallStatsRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.imscan.GetOverallStatsRequest} returns this
 */
proto.imscan.GetOverallStatsRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.imscan.GetOverallStatsRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.GetOverallStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.GetOverallStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.GetOverallStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.GetOverallStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    err: (f = msg.getErr()) && proto.imscan.ErrMsg.toObject(includeInstance, f),
    totalMsgCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    last24MsgCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalMsgFeeUsd: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    last24MsgFeeUsd: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.GetOverallStatsResponse}
 */
proto.imscan.GetOverallStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.GetOverallStatsResponse;
  return proto.imscan.GetOverallStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.GetOverallStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.GetOverallStatsResponse}
 */
proto.imscan.GetOverallStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.imscan.ErrMsg;
      reader.readMessage(value,proto.imscan.ErrMsg.deserializeBinaryFromReader);
      msg.setErr(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalMsgCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLast24MsgCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalMsgFeeUsd(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLast24MsgFeeUsd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.GetOverallStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.GetOverallStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.GetOverallStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.GetOverallStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.imscan.ErrMsg.serializeBinaryToWriter
    );
  }
  f = message.getTotalMsgCount();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getLast24MsgCount();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getTotalMsgFeeUsd();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getLast24MsgFeeUsd();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * optional ErrMsg err = 1;
 * @return {?proto.imscan.ErrMsg}
 */
proto.imscan.GetOverallStatsResponse.prototype.getErr = function() {
  return /** @type{?proto.imscan.ErrMsg} */ (
    jspb.Message.getWrapperField(this, proto.imscan.ErrMsg, 1));
};


/**
 * @param {?proto.imscan.ErrMsg|undefined} value
 * @return {!proto.imscan.GetOverallStatsResponse} returns this
*/
proto.imscan.GetOverallStatsResponse.prototype.setErr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.imscan.GetOverallStatsResponse} returns this
 */
proto.imscan.GetOverallStatsResponse.prototype.clearErr = function() {
  return this.setErr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.imscan.GetOverallStatsResponse.prototype.hasErr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 total_msg_count = 2;
 * @return {number}
 */
proto.imscan.GetOverallStatsResponse.prototype.getTotalMsgCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.GetOverallStatsResponse} returns this
 */
proto.imscan.GetOverallStatsResponse.prototype.setTotalMsgCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 last_24_msg_count = 3;
 * @return {number}
 */
proto.imscan.GetOverallStatsResponse.prototype.getLast24MsgCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.GetOverallStatsResponse} returns this
 */
proto.imscan.GetOverallStatsResponse.prototype.setLast24MsgCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double total_msg_fee_usd = 4;
 * @return {number}
 */
proto.imscan.GetOverallStatsResponse.prototype.getTotalMsgFeeUsd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.GetOverallStatsResponse} returns this
 */
proto.imscan.GetOverallStatsResponse.prototype.setTotalMsgFeeUsd = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double last_24_msg_fee_usd = 6;
 * @return {number}
 */
proto.imscan.GetOverallStatsResponse.prototype.getLast24MsgFeeUsd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.GetOverallStatsResponse} returns this
 */
proto.imscan.GetOverallStatsResponse.prototype.setLast24MsgFeeUsd = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.IMStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.IMStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.IMStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.IMStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.IMStatusRequest}
 */
proto.imscan.IMStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.IMStatusRequest;
  return proto.imscan.IMStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.IMStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.IMStatusRequest}
 */
proto.imscan.IMStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.IMStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.IMStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.IMStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.IMStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.IMStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.IMStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.IMStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.IMStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    err: (f = msg.getErr()) && proto.imscan.ErrMsg.toObject(includeInstance, f),
    totalTx: jspb.Message.getFieldWithDefault(msg, 2, 0),
    latest24Tx: jspb.Message.getFieldWithDefault(msg, 3, 0),
    dailyTxMap: (f = msg.getDailyTxMap()) ? f.toObject(includeInstance, undefined) : [],
    chainTxMap: (f = msg.getChainTxMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.IMStatusResponse}
 */
proto.imscan.IMStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.IMStatusResponse;
  return proto.imscan.IMStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.IMStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.IMStatusResponse}
 */
proto.imscan.IMStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.imscan.ErrMsg;
      reader.readMessage(value,proto.imscan.ErrMsg.deserializeBinaryFromReader);
      msg.setErr(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalTx(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLatest24Tx(value);
      break;
    case 4:
      var value = msg.getDailyTxMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readUint32, null, "", 0);
         });
      break;
    case 5:
      var value = msg.getChainTxMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint32, null, 0, 0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.IMStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.IMStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.IMStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.IMStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.imscan.ErrMsg.serializeBinaryToWriter
    );
  }
  f = message.getTotalTx();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getLatest24Tx();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getDailyTxMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeUint32);
  }
  f = message.getChainTxMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint32);
  }
};


/**
 * optional ErrMsg err = 1;
 * @return {?proto.imscan.ErrMsg}
 */
proto.imscan.IMStatusResponse.prototype.getErr = function() {
  return /** @type{?proto.imscan.ErrMsg} */ (
    jspb.Message.getWrapperField(this, proto.imscan.ErrMsg, 1));
};


/**
 * @param {?proto.imscan.ErrMsg|undefined} value
 * @return {!proto.imscan.IMStatusResponse} returns this
*/
proto.imscan.IMStatusResponse.prototype.setErr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.imscan.IMStatusResponse} returns this
 */
proto.imscan.IMStatusResponse.prototype.clearErr = function() {
  return this.setErr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.imscan.IMStatusResponse.prototype.hasErr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 total_tx = 2;
 * @return {number}
 */
proto.imscan.IMStatusResponse.prototype.getTotalTx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.IMStatusResponse} returns this
 */
proto.imscan.IMStatusResponse.prototype.setTotalTx = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 latest_24_tx = 3;
 * @return {number}
 */
proto.imscan.IMStatusResponse.prototype.getLatest24Tx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.IMStatusResponse} returns this
 */
proto.imscan.IMStatusResponse.prototype.setLatest24Tx = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * map<string, uint32> daily_tx = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.imscan.IMStatusResponse.prototype.getDailyTxMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.imscan.IMStatusResponse} returns this
 */
proto.imscan.IMStatusResponse.prototype.clearDailyTxMap = function() {
  this.getDailyTxMap().clear();
  return this;
};


/**
 * map<uint32, uint32> chain_tx = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.imscan.IMStatusResponse.prototype.getChainTxMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.imscan.IMStatusResponse} returns this
 */
proto.imscan.IMStatusResponse.prototype.clearChainTxMap = function() {
  this.getChainTxMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.QueryLatestTxRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.QueryLatestTxRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.QueryLatestTxRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.QueryLatestTxRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    size: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.QueryLatestTxRequest}
 */
proto.imscan.QueryLatestTxRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.QueryLatestTxRequest;
  return proto.imscan.QueryLatestTxRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.QueryLatestTxRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.QueryLatestTxRequest}
 */
proto.imscan.QueryLatestTxRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.QueryLatestTxRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.QueryLatestTxRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.QueryLatestTxRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.QueryLatestTxRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSize();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 size = 1;
 * @return {number}
 */
proto.imscan.QueryLatestTxRequest.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.QueryLatestTxRequest} returns this
 */
proto.imscan.QueryLatestTxRequest.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.imscan.QueryLatestTxTxResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.QueryLatestTxTxResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.QueryLatestTxTxResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.QueryLatestTxTxResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.QueryLatestTxTxResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    err: (f = msg.getErr()) && proto.imscan.ErrMsg.toObject(includeInstance, f),
    transferPreviewList: jspb.Message.toObjectList(msg.getTransferPreviewList(),
    proto.imscan.TransferPreview.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.QueryLatestTxTxResponse}
 */
proto.imscan.QueryLatestTxTxResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.QueryLatestTxTxResponse;
  return proto.imscan.QueryLatestTxTxResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.QueryLatestTxTxResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.QueryLatestTxTxResponse}
 */
proto.imscan.QueryLatestTxTxResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.imscan.ErrMsg;
      reader.readMessage(value,proto.imscan.ErrMsg.deserializeBinaryFromReader);
      msg.setErr(value);
      break;
    case 2:
      var value = new proto.imscan.TransferPreview;
      reader.readMessage(value,proto.imscan.TransferPreview.deserializeBinaryFromReader);
      msg.addTransferPreview(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.QueryLatestTxTxResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.QueryLatestTxTxResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.QueryLatestTxTxResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.QueryLatestTxTxResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.imscan.ErrMsg.serializeBinaryToWriter
    );
  }
  f = message.getTransferPreviewList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.imscan.TransferPreview.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrMsg err = 1;
 * @return {?proto.imscan.ErrMsg}
 */
proto.imscan.QueryLatestTxTxResponse.prototype.getErr = function() {
  return /** @type{?proto.imscan.ErrMsg} */ (
    jspb.Message.getWrapperField(this, proto.imscan.ErrMsg, 1));
};


/**
 * @param {?proto.imscan.ErrMsg|undefined} value
 * @return {!proto.imscan.QueryLatestTxTxResponse} returns this
*/
proto.imscan.QueryLatestTxTxResponse.prototype.setErr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.imscan.QueryLatestTxTxResponse} returns this
 */
proto.imscan.QueryLatestTxTxResponse.prototype.clearErr = function() {
  return this.setErr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.imscan.QueryLatestTxTxResponse.prototype.hasErr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated TransferPreview transfer_preview = 2;
 * @return {!Array<!proto.imscan.TransferPreview>}
 */
proto.imscan.QueryLatestTxTxResponse.prototype.getTransferPreviewList = function() {
  return /** @type{!Array<!proto.imscan.TransferPreview>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.imscan.TransferPreview, 2));
};


/**
 * @param {!Array<!proto.imscan.TransferPreview>} value
 * @return {!proto.imscan.QueryLatestTxTxResponse} returns this
*/
proto.imscan.QueryLatestTxTxResponse.prototype.setTransferPreviewList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.imscan.TransferPreview=} opt_value
 * @param {number=} opt_index
 * @return {!proto.imscan.TransferPreview}
 */
proto.imscan.QueryLatestTxTxResponse.prototype.addTransferPreview = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.imscan.TransferPreview, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.imscan.QueryLatestTxTxResponse} returns this
 */
proto.imscan.QueryLatestTxTxResponse.prototype.clearTransferPreviewList = function() {
  return this.setTransferPreviewList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.SearchByTxHashRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.SearchByTxHashRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.SearchByTxHashRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.SearchByTxHashRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tx: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.SearchByTxHashRequest}
 */
proto.imscan.SearchByTxHashRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.SearchByTxHashRequest;
  return proto.imscan.SearchByTxHashRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.SearchByTxHashRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.SearchByTxHashRequest}
 */
proto.imscan.SearchByTxHashRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTx(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.SearchByTxHashRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.SearchByTxHashRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.SearchByTxHashRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.SearchByTxHashRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTx();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tx = 1;
 * @return {string}
 */
proto.imscan.SearchByTxHashRequest.prototype.getTx = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.SearchByTxHashRequest} returns this
 */
proto.imscan.SearchByTxHashRequest.prototype.setTx = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.imscan.SearchByTxHashResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.SearchByTxHashResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.SearchByTxHashResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.SearchByTxHashResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.SearchByTxHashResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    err: (f = msg.getErr()) && proto.imscan.ErrMsg.toObject(includeInstance, f),
    txsearchinfoList: jspb.Message.toObjectList(msg.getTxsearchinfoList(),
    proto.imscan.TxSearchInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.SearchByTxHashResponse}
 */
proto.imscan.SearchByTxHashResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.SearchByTxHashResponse;
  return proto.imscan.SearchByTxHashResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.SearchByTxHashResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.SearchByTxHashResponse}
 */
proto.imscan.SearchByTxHashResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.imscan.ErrMsg;
      reader.readMessage(value,proto.imscan.ErrMsg.deserializeBinaryFromReader);
      msg.setErr(value);
      break;
    case 2:
      var value = new proto.imscan.TxSearchInfo;
      reader.readMessage(value,proto.imscan.TxSearchInfo.deserializeBinaryFromReader);
      msg.addTxsearchinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.SearchByTxHashResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.SearchByTxHashResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.SearchByTxHashResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.SearchByTxHashResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.imscan.ErrMsg.serializeBinaryToWriter
    );
  }
  f = message.getTxsearchinfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.imscan.TxSearchInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrMsg err = 1;
 * @return {?proto.imscan.ErrMsg}
 */
proto.imscan.SearchByTxHashResponse.prototype.getErr = function() {
  return /** @type{?proto.imscan.ErrMsg} */ (
    jspb.Message.getWrapperField(this, proto.imscan.ErrMsg, 1));
};


/**
 * @param {?proto.imscan.ErrMsg|undefined} value
 * @return {!proto.imscan.SearchByTxHashResponse} returns this
*/
proto.imscan.SearchByTxHashResponse.prototype.setErr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.imscan.SearchByTxHashResponse} returns this
 */
proto.imscan.SearchByTxHashResponse.prototype.clearErr = function() {
  return this.setErr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.imscan.SearchByTxHashResponse.prototype.hasErr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated TxSearchInfo txSearchInfo = 2;
 * @return {!Array<!proto.imscan.TxSearchInfo>}
 */
proto.imscan.SearchByTxHashResponse.prototype.getTxsearchinfoList = function() {
  return /** @type{!Array<!proto.imscan.TxSearchInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.imscan.TxSearchInfo, 2));
};


/**
 * @param {!Array<!proto.imscan.TxSearchInfo>} value
 * @return {!proto.imscan.SearchByTxHashResponse} returns this
*/
proto.imscan.SearchByTxHashResponse.prototype.setTxsearchinfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.imscan.TxSearchInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.imscan.TxSearchInfo}
 */
proto.imscan.SearchByTxHashResponse.prototype.addTxsearchinfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.imscan.TxSearchInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.imscan.SearchByTxHashResponse} returns this
 */
proto.imscan.SearchByTxHashResponse.prototype.clearTxsearchinfoList = function() {
  return this.setTxsearchinfoList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.imscan.TxSearchInfo.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.TxSearchInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.TxSearchInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.TxSearchInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.TxSearchInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseInfo: (f = msg.getBaseInfo()) && proto.imscan.BaseInfo.toObject(includeInstance, f),
    transferList: jspb.Message.toObjectList(msg.getTransferList(),
    proto.imscan.Transfer.toObject, includeInstance),
    messageList: jspb.Message.toObjectList(msg.getMessageList(),
    proto.imscan.Message.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.TxSearchInfo}
 */
proto.imscan.TxSearchInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.TxSearchInfo;
  return proto.imscan.TxSearchInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.TxSearchInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.TxSearchInfo}
 */
proto.imscan.TxSearchInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.imscan.BaseInfo;
      reader.readMessage(value,proto.imscan.BaseInfo.deserializeBinaryFromReader);
      msg.setBaseInfo(value);
      break;
    case 2:
      var value = new proto.imscan.Transfer;
      reader.readMessage(value,proto.imscan.Transfer.deserializeBinaryFromReader);
      msg.addTransfer(value);
      break;
    case 3:
      var value = new proto.imscan.Message;
      reader.readMessage(value,proto.imscan.Message.deserializeBinaryFromReader);
      msg.addMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.TxSearchInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.TxSearchInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.TxSearchInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.TxSearchInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.imscan.BaseInfo.serializeBinaryToWriter
    );
  }
  f = message.getTransferList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.imscan.Transfer.serializeBinaryToWriter
    );
  }
  f = message.getMessageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.imscan.Message.serializeBinaryToWriter
    );
  }
};


/**
 * optional BaseInfo base_info = 1;
 * @return {?proto.imscan.BaseInfo}
 */
proto.imscan.TxSearchInfo.prototype.getBaseInfo = function() {
  return /** @type{?proto.imscan.BaseInfo} */ (
    jspb.Message.getWrapperField(this, proto.imscan.BaseInfo, 1));
};


/**
 * @param {?proto.imscan.BaseInfo|undefined} value
 * @return {!proto.imscan.TxSearchInfo} returns this
*/
proto.imscan.TxSearchInfo.prototype.setBaseInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.imscan.TxSearchInfo} returns this
 */
proto.imscan.TxSearchInfo.prototype.clearBaseInfo = function() {
  return this.setBaseInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.imscan.TxSearchInfo.prototype.hasBaseInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Transfer transfer = 2;
 * @return {!Array<!proto.imscan.Transfer>}
 */
proto.imscan.TxSearchInfo.prototype.getTransferList = function() {
  return /** @type{!Array<!proto.imscan.Transfer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.imscan.Transfer, 2));
};


/**
 * @param {!Array<!proto.imscan.Transfer>} value
 * @return {!proto.imscan.TxSearchInfo} returns this
*/
proto.imscan.TxSearchInfo.prototype.setTransferList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.imscan.Transfer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.imscan.Transfer}
 */
proto.imscan.TxSearchInfo.prototype.addTransfer = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.imscan.Transfer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.imscan.TxSearchInfo} returns this
 */
proto.imscan.TxSearchInfo.prototype.clearTransferList = function() {
  return this.setTransferList([]);
};


/**
 * repeated Message message = 3;
 * @return {!Array<!proto.imscan.Message>}
 */
proto.imscan.TxSearchInfo.prototype.getMessageList = function() {
  return /** @type{!Array<!proto.imscan.Message>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.imscan.Message, 3));
};


/**
 * @param {!Array<!proto.imscan.Message>} value
 * @return {!proto.imscan.TxSearchInfo} returns this
*/
proto.imscan.TxSearchInfo.prototype.setMessageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.imscan.Message=} opt_value
 * @param {number=} opt_index
 * @return {!proto.imscan.Message}
 */
proto.imscan.TxSearchInfo.prototype.addMessage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.imscan.Message, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.imscan.TxSearchInfo} returns this
 */
proto.imscan.TxSearchInfo.prototype.clearMessageList = function() {
  return this.setMessageList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.SearchByAddrRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.SearchByAddrRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.SearchByAddrRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.SearchByAddrRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    addr: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageNum: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.SearchByAddrRequest}
 */
proto.imscan.SearchByAddrRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.SearchByAddrRequest;
  return proto.imscan.SearchByAddrRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.SearchByAddrRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.SearchByAddrRequest}
 */
proto.imscan.SearchByAddrRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddr(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNextPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageNum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.SearchByAddrRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.SearchByAddrRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.SearchByAddrRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.SearchByAddrRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddr();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNextPageToken();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getPageNum();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional string addr = 1;
 * @return {string}
 */
proto.imscan.SearchByAddrRequest.prototype.getAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.SearchByAddrRequest} returns this
 */
proto.imscan.SearchByAddrRequest.prototype.setAddr = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 next_page_token = 2;
 * @return {number}
 */
proto.imscan.SearchByAddrRequest.prototype.getNextPageToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.SearchByAddrRequest} returns this
 */
proto.imscan.SearchByAddrRequest.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 page_num = 3;
 * @return {number}
 */
proto.imscan.SearchByAddrRequest.prototype.getPageNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.SearchByAddrRequest} returns this
 */
proto.imscan.SearchByAddrRequest.prototype.setPageNum = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.imscan.SearchByAddrResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.SearchByAddrResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.SearchByAddrResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.SearchByAddrResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.SearchByAddrResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    err: (f = msg.getErr()) && proto.imscan.ErrMsg.toObject(includeInstance, f),
    transferPreviewList: jspb.Message.toObjectList(msg.getTransferPreviewList(),
    proto.imscan.TransferPreview.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalNum: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.SearchByAddrResponse}
 */
proto.imscan.SearchByAddrResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.SearchByAddrResponse;
  return proto.imscan.SearchByAddrResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.SearchByAddrResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.SearchByAddrResponse}
 */
proto.imscan.SearchByAddrResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.imscan.ErrMsg;
      reader.readMessage(value,proto.imscan.ErrMsg.deserializeBinaryFromReader);
      msg.setErr(value);
      break;
    case 2:
      var value = new proto.imscan.TransferPreview;
      reader.readMessage(value,proto.imscan.TransferPreview.deserializeBinaryFromReader);
      msg.addTransferPreview(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNextPageToken(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalNum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.SearchByAddrResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.SearchByAddrResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.SearchByAddrResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.SearchByAddrResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.imscan.ErrMsg.serializeBinaryToWriter
    );
  }
  f = message.getTransferPreviewList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.imscan.TransferPreview.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getTotalNum();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional ErrMsg err = 1;
 * @return {?proto.imscan.ErrMsg}
 */
proto.imscan.SearchByAddrResponse.prototype.getErr = function() {
  return /** @type{?proto.imscan.ErrMsg} */ (
    jspb.Message.getWrapperField(this, proto.imscan.ErrMsg, 1));
};


/**
 * @param {?proto.imscan.ErrMsg|undefined} value
 * @return {!proto.imscan.SearchByAddrResponse} returns this
*/
proto.imscan.SearchByAddrResponse.prototype.setErr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.imscan.SearchByAddrResponse} returns this
 */
proto.imscan.SearchByAddrResponse.prototype.clearErr = function() {
  return this.setErr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.imscan.SearchByAddrResponse.prototype.hasErr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated TransferPreview transfer_preview = 2;
 * @return {!Array<!proto.imscan.TransferPreview>}
 */
proto.imscan.SearchByAddrResponse.prototype.getTransferPreviewList = function() {
  return /** @type{!Array<!proto.imscan.TransferPreview>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.imscan.TransferPreview, 2));
};


/**
 * @param {!Array<!proto.imscan.TransferPreview>} value
 * @return {!proto.imscan.SearchByAddrResponse} returns this
*/
proto.imscan.SearchByAddrResponse.prototype.setTransferPreviewList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.imscan.TransferPreview=} opt_value
 * @param {number=} opt_index
 * @return {!proto.imscan.TransferPreview}
 */
proto.imscan.SearchByAddrResponse.prototype.addTransferPreview = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.imscan.TransferPreview, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.imscan.SearchByAddrResponse} returns this
 */
proto.imscan.SearchByAddrResponse.prototype.clearTransferPreviewList = function() {
  return this.setTransferPreviewList([]);
};


/**
 * optional uint64 next_page_token = 3;
 * @return {number}
 */
proto.imscan.SearchByAddrResponse.prototype.getNextPageToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.SearchByAddrResponse} returns this
 */
proto.imscan.SearchByAddrResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 total_num = 4;
 * @return {number}
 */
proto.imscan.SearchByAddrResponse.prototype.getTotalNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.SearchByAddrResponse} returns this
 */
proto.imscan.SearchByAddrResponse.prototype.setTotalNum = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.imscan.TransferPreview.repeatedFields_ = [2,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.TransferPreview.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.TransferPreview.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.TransferPreview} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.TransferPreview.toObject = function(includeInstance, msg) {
  var f, obj = {
    srcChainId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dstChainIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    sender: jspb.Message.getFieldWithDefault(msg, 3, ""),
    receiver: jspb.Message.getFieldWithDefault(msg, 4, ""),
    srcTxHash: jspb.Message.getFieldWithDefault(msg, 5, ""),
    ts: jspb.Message.getFieldWithDefault(msg, 6, 0),
    transferInfoList: jspb.Message.toObjectList(msg.getTransferInfoList(),
    proto.imscan.TransferPreviewInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.TransferPreview}
 */
proto.imscan.TransferPreview.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.TransferPreview;
  return proto.imscan.TransferPreview.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.TransferPreview} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.TransferPreview}
 */
proto.imscan.TransferPreview.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSrcChainId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDstChainId(values[i]);
      }
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSender(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiver(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSrcTxHash(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTs(value);
      break;
    case 7:
      var value = new proto.imscan.TransferPreviewInfo;
      reader.readMessage(value,proto.imscan.TransferPreviewInfo.deserializeBinaryFromReader);
      msg.addTransferInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.TransferPreview.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.TransferPreview.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.TransferPreview} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.TransferPreview.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSrcChainId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDstChainIdList();
  if (f.length > 0) {
    writer.writePackedUint32(
      2,
      f
    );
  }
  f = message.getSender();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReceiver();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSrcTxHash();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTs();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getTransferInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.imscan.TransferPreviewInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 src_chain_id = 1;
 * @return {number}
 */
proto.imscan.TransferPreview.prototype.getSrcChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.TransferPreview} returns this
 */
proto.imscan.TransferPreview.prototype.setSrcChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated uint32 dst_chain_id = 2;
 * @return {!Array<number>}
 */
proto.imscan.TransferPreview.prototype.getDstChainIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.imscan.TransferPreview} returns this
 */
proto.imscan.TransferPreview.prototype.setDstChainIdList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.imscan.TransferPreview} returns this
 */
proto.imscan.TransferPreview.prototype.addDstChainId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.imscan.TransferPreview} returns this
 */
proto.imscan.TransferPreview.prototype.clearDstChainIdList = function() {
  return this.setDstChainIdList([]);
};


/**
 * optional string sender = 3;
 * @return {string}
 */
proto.imscan.TransferPreview.prototype.getSender = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.TransferPreview} returns this
 */
proto.imscan.TransferPreview.prototype.setSender = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string receiver = 4;
 * @return {string}
 */
proto.imscan.TransferPreview.prototype.getReceiver = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.TransferPreview} returns this
 */
proto.imscan.TransferPreview.prototype.setReceiver = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string src_tx_hash = 5;
 * @return {string}
 */
proto.imscan.TransferPreview.prototype.getSrcTxHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.TransferPreview} returns this
 */
proto.imscan.TransferPreview.prototype.setSrcTxHash = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint64 ts = 6;
 * @return {number}
 */
proto.imscan.TransferPreview.prototype.getTs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.TransferPreview} returns this
 */
proto.imscan.TransferPreview.prototype.setTs = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated TransferPreviewInfo transfer_info = 7;
 * @return {!Array<!proto.imscan.TransferPreviewInfo>}
 */
proto.imscan.TransferPreview.prototype.getTransferInfoList = function() {
  return /** @type{!Array<!proto.imscan.TransferPreviewInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.imscan.TransferPreviewInfo, 7));
};


/**
 * @param {!Array<!proto.imscan.TransferPreviewInfo>} value
 * @return {!proto.imscan.TransferPreview} returns this
*/
proto.imscan.TransferPreview.prototype.setTransferInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.imscan.TransferPreviewInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.imscan.TransferPreviewInfo}
 */
proto.imscan.TransferPreview.prototype.addTransferInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.imscan.TransferPreviewInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.imscan.TransferPreview} returns this
 */
proto.imscan.TransferPreview.prototype.clearTransferInfoList = function() {
  return this.setTransferInfoList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.TransferPreviewInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.TransferPreviewInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.TransferPreviewInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.TransferPreviewInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    sendAmt: jspb.Message.getFieldWithDefault(msg, 1, ""),
    srcTokenAddr: jspb.Message.getFieldWithDefault(msg, 2, ""),
    xferId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.TransferPreviewInfo}
 */
proto.imscan.TransferPreviewInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.TransferPreviewInfo;
  return proto.imscan.TransferPreviewInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.TransferPreviewInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.TransferPreviewInfo}
 */
proto.imscan.TransferPreviewInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSendAmt(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSrcTokenAddr(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setXferId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.TransferPreviewInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.TransferPreviewInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.TransferPreviewInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.TransferPreviewInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSendAmt();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSrcTokenAddr();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getXferId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string send_amt = 1;
 * @return {string}
 */
proto.imscan.TransferPreviewInfo.prototype.getSendAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.TransferPreviewInfo} returns this
 */
proto.imscan.TransferPreviewInfo.prototype.setSendAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string src_token_addr = 2;
 * @return {string}
 */
proto.imscan.TransferPreviewInfo.prototype.getSrcTokenAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.TransferPreviewInfo} returns this
 */
proto.imscan.TransferPreviewInfo.prototype.setSrcTokenAddr = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string xfer_id = 3;
 * @return {string}
 */
proto.imscan.TransferPreviewInfo.prototype.getXferId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.TransferPreviewInfo} returns this
 */
proto.imscan.TransferPreviewInfo.prototype.setXferId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.Transfer.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.Transfer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.Transfer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.Transfer.toObject = function(includeInstance, msg) {
  var f, obj = {
    xferId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dstChainId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sendAmt: jspb.Message.getFieldWithDefault(msg, 3, ""),
    receivedAmt: jspb.Message.getFieldWithDefault(msg, 4, ""),
    srcTxHash: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dstTxHash: jspb.Message.getFieldWithDefault(msg, 6, ""),
    srcTokenAddr: jspb.Message.getFieldWithDefault(msg, 7, ""),
    dstTokenAddr: jspb.Message.getFieldWithDefault(msg, 8, ""),
    xferStatus: jspb.Message.getFieldWithDefault(msg, 9, 0),
    bridgeType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    refundAmt: jspb.Message.getFieldWithDefault(msg, 11, ""),
    refundTx: jspb.Message.getFieldWithDefault(msg, 12, ""),
    dstTransferId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    dstDelayExecutedTxHash: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.Transfer}
 */
proto.imscan.Transfer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.Transfer;
  return proto.imscan.Transfer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.Transfer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.Transfer}
 */
proto.imscan.Transfer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setXferId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDstChainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSendAmt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceivedAmt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSrcTxHash(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDstTxHash(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSrcTokenAddr(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDstTokenAddr(value);
      break;
    case 9:
      var value = /** @type {!proto.imscan.XferStatus} */ (reader.readEnum());
      msg.setXferStatus(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBridgeType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefundAmt(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefundTx(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDstTransferId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setDstDelayExecutedTxHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.Transfer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.Transfer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.Transfer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.Transfer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getXferId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDstChainId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSendAmt();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReceivedAmt();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSrcTxHash();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDstTxHash();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSrcTokenAddr();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDstTokenAddr();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getXferStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getBridgeType();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getRefundAmt();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getRefundTx();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getDstTransferId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDstDelayExecutedTxHash();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string xfer_id = 1;
 * @return {string}
 */
proto.imscan.Transfer.prototype.getXferId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.Transfer} returns this
 */
proto.imscan.Transfer.prototype.setXferId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 dst_chain_id = 2;
 * @return {number}
 */
proto.imscan.Transfer.prototype.getDstChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.Transfer} returns this
 */
proto.imscan.Transfer.prototype.setDstChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string send_amt = 3;
 * @return {string}
 */
proto.imscan.Transfer.prototype.getSendAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.Transfer} returns this
 */
proto.imscan.Transfer.prototype.setSendAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string received_amt = 4;
 * @return {string}
 */
proto.imscan.Transfer.prototype.getReceivedAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.Transfer} returns this
 */
proto.imscan.Transfer.prototype.setReceivedAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string src_tx_hash = 5;
 * @return {string}
 */
proto.imscan.Transfer.prototype.getSrcTxHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.Transfer} returns this
 */
proto.imscan.Transfer.prototype.setSrcTxHash = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string dst_tx_hash = 6;
 * @return {string}
 */
proto.imscan.Transfer.prototype.getDstTxHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.Transfer} returns this
 */
proto.imscan.Transfer.prototype.setDstTxHash = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string src_token_addr = 7;
 * @return {string}
 */
proto.imscan.Transfer.prototype.getSrcTokenAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.Transfer} returns this
 */
proto.imscan.Transfer.prototype.setSrcTokenAddr = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string dst_token_addr = 8;
 * @return {string}
 */
proto.imscan.Transfer.prototype.getDstTokenAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.Transfer} returns this
 */
proto.imscan.Transfer.prototype.setDstTokenAddr = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional XferStatus xfer_status = 9;
 * @return {!proto.imscan.XferStatus}
 */
proto.imscan.Transfer.prototype.getXferStatus = function() {
  return /** @type {!proto.imscan.XferStatus} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.imscan.XferStatus} value
 * @return {!proto.imscan.Transfer} returns this
 */
proto.imscan.Transfer.prototype.setXferStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional uint32 bridge_type = 10;
 * @return {number}
 */
proto.imscan.Transfer.prototype.getBridgeType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.Transfer} returns this
 */
proto.imscan.Transfer.prototype.setBridgeType = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string refund_amt = 11;
 * @return {string}
 */
proto.imscan.Transfer.prototype.getRefundAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.Transfer} returns this
 */
proto.imscan.Transfer.prototype.setRefundAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string refund_tx = 12;
 * @return {string}
 */
proto.imscan.Transfer.prototype.getRefundTx = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.Transfer} returns this
 */
proto.imscan.Transfer.prototype.setRefundTx = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string dst_transfer_id = 13;
 * @return {string}
 */
proto.imscan.Transfer.prototype.getDstTransferId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.Transfer} returns this
 */
proto.imscan.Transfer.prototype.setDstTransferId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string dst_delay_executed_tx_hash = 14;
 * @return {string}
 */
proto.imscan.Transfer.prototype.getDstDelayExecutedTxHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.Transfer} returns this
 */
proto.imscan.Transfer.prototype.setDstDelayExecutedTxHash = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.Message.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.Message.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.Message} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.Message.toObject = function(includeInstance, msg) {
  var f, obj = {
    msgId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dstChainId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    payload: jspb.Message.getFieldWithDefault(msg, 3, ""),
    executionTx: jspb.Message.getFieldWithDefault(msg, 4, ""),
    msgFeeGas: jspb.Message.getFieldWithDefault(msg, 5, ""),
    msgFeeVolume: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    msgStatus: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.Message}
 */
proto.imscan.Message.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.Message;
  return proto.imscan.Message.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.Message} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.Message}
 */
proto.imscan.Message.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMsgId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDstChainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayload(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecutionTx(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMsgFeeGas(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMsgFeeVolume(value);
      break;
    case 7:
      var value = /** @type {!proto.imscan.MsgStatus} */ (reader.readEnum());
      msg.setMsgStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.Message.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.Message.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.Message} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.Message.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMsgId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDstChainId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPayload();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExecutionTx();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMsgFeeGas();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMsgFeeVolume();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getMsgStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * optional string msg_id = 1;
 * @return {string}
 */
proto.imscan.Message.prototype.getMsgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.Message} returns this
 */
proto.imscan.Message.prototype.setMsgId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 dst_chain_id = 2;
 * @return {number}
 */
proto.imscan.Message.prototype.getDstChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.Message} returns this
 */
proto.imscan.Message.prototype.setDstChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string payload = 3;
 * @return {string}
 */
proto.imscan.Message.prototype.getPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.Message} returns this
 */
proto.imscan.Message.prototype.setPayload = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string execution_tx = 4;
 * @return {string}
 */
proto.imscan.Message.prototype.getExecutionTx = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.Message} returns this
 */
proto.imscan.Message.prototype.setExecutionTx = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string msg_fee_gas = 5;
 * @return {string}
 */
proto.imscan.Message.prototype.getMsgFeeGas = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.Message} returns this
 */
proto.imscan.Message.prototype.setMsgFeeGas = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional float msg_fee_volume = 6;
 * @return {number}
 */
proto.imscan.Message.prototype.getMsgFeeVolume = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.Message} returns this
 */
proto.imscan.Message.prototype.setMsgFeeVolume = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional MsgStatus msg_status = 7;
 * @return {!proto.imscan.MsgStatus}
 */
proto.imscan.Message.prototype.getMsgStatus = function() {
  return /** @type {!proto.imscan.MsgStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.imscan.MsgStatus} value
 * @return {!proto.imscan.Message} returns this
 */
proto.imscan.Message.prototype.setMsgStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.BaseInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.BaseInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.BaseInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.BaseInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    sender: jspb.Message.getFieldWithDefault(msg, 1, ""),
    receiver: jspb.Message.getFieldWithDefault(msg, 2, ""),
    srcChainId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    srcTxHash: jspb.Message.getFieldWithDefault(msg, 4, ""),
    initTime: jspb.Message.getFieldWithDefault(msg, 8, 0),
    lastUpdateTime: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.BaseInfo}
 */
proto.imscan.BaseInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.BaseInfo;
  return proto.imscan.BaseInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.BaseInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.BaseInfo}
 */
proto.imscan.BaseInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSender(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiver(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSrcChainId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSrcTxHash(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setInitTime(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastUpdateTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.BaseInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.BaseInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.BaseInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.BaseInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSender();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReceiver();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSrcChainId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getSrcTxHash();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInitTime();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = message.getLastUpdateTime();
  if (f !== 0) {
    writer.writeUint64(
      9,
      f
    );
  }
};


/**
 * optional string sender = 1;
 * @return {string}
 */
proto.imscan.BaseInfo.prototype.getSender = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.BaseInfo} returns this
 */
proto.imscan.BaseInfo.prototype.setSender = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string receiver = 2;
 * @return {string}
 */
proto.imscan.BaseInfo.prototype.getReceiver = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.BaseInfo} returns this
 */
proto.imscan.BaseInfo.prototype.setReceiver = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 src_chain_id = 3;
 * @return {number}
 */
proto.imscan.BaseInfo.prototype.getSrcChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.BaseInfo} returns this
 */
proto.imscan.BaseInfo.prototype.setSrcChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string src_tx_hash = 4;
 * @return {string}
 */
proto.imscan.BaseInfo.prototype.getSrcTxHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.BaseInfo} returns this
 */
proto.imscan.BaseInfo.prototype.setSrcTxHash = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint64 init_time = 8;
 * @return {number}
 */
proto.imscan.BaseInfo.prototype.getInitTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.BaseInfo} returns this
 */
proto.imscan.BaseInfo.prototype.setInitTime = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint64 last_update_time = 9;
 * @return {number}
 */
proto.imscan.BaseInfo.prototype.getLastUpdateTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.BaseInfo} returns this
 */
proto.imscan.BaseInfo.prototype.setLastUpdateTime = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.ImQueryAuth.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.ImQueryAuth.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.ImQueryAuth} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.ImQueryAuth.toObject = function(includeInstance, msg) {
  var f, obj = {
    signData: (f = msg.getSignData()) && proto.imscan.ImQuerySignData.toObject(includeInstance, f),
    sig: msg.getSig_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.ImQueryAuth}
 */
proto.imscan.ImQueryAuth.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.ImQueryAuth;
  return proto.imscan.ImQueryAuth.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.ImQueryAuth} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.ImQueryAuth}
 */
proto.imscan.ImQueryAuth.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.imscan.ImQuerySignData;
      reader.readMessage(value,proto.imscan.ImQuerySignData.deserializeBinaryFromReader);
      msg.setSignData(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.ImQueryAuth.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.ImQueryAuth.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.ImQueryAuth} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.ImQueryAuth.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSignData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.imscan.ImQuerySignData.serializeBinaryToWriter
    );
  }
  f = message.getSig_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional ImQuerySignData sign_data = 1;
 * @return {?proto.imscan.ImQuerySignData}
 */
proto.imscan.ImQueryAuth.prototype.getSignData = function() {
  return /** @type{?proto.imscan.ImQuerySignData} */ (
    jspb.Message.getWrapperField(this, proto.imscan.ImQuerySignData, 1));
};


/**
 * @param {?proto.imscan.ImQuerySignData|undefined} value
 * @return {!proto.imscan.ImQueryAuth} returns this
*/
proto.imscan.ImQueryAuth.prototype.setSignData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.imscan.ImQueryAuth} returns this
 */
proto.imscan.ImQueryAuth.prototype.clearSignData = function() {
  return this.setSignData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.imscan.ImQueryAuth.prototype.hasSignData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes sig = 2;
 * @return {!(string|Uint8Array)}
 */
proto.imscan.ImQueryAuth.prototype.getSig = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes sig = 2;
 * This is a type-conversion wrapper around `getSig()`
 * @return {string}
 */
proto.imscan.ImQueryAuth.prototype.getSig_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSig()));
};


/**
 * optional bytes sig = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSig()`
 * @return {!Uint8Array}
 */
proto.imscan.ImQueryAuth.prototype.getSig_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSig()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.imscan.ImQueryAuth} returns this
 */
proto.imscan.ImQueryAuth.prototype.setSig = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.ImQuerySignData.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.ImQuerySignData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.ImQuerySignData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.ImQuerySignData.toObject = function(includeInstance, msg) {
  var f, obj = {
    addr: jspb.Message.getFieldWithDefault(msg, 1, ""),
    signTime: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.ImQuerySignData}
 */
proto.imscan.ImQuerySignData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.ImQuerySignData;
  return proto.imscan.ImQuerySignData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.ImQuerySignData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.ImQuerySignData}
 */
proto.imscan.ImQuerySignData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddr(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSignTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.ImQuerySignData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.ImQuerySignData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.ImQuerySignData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.ImQuerySignData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddr();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSignTime();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional string addr = 1;
 * @return {string}
 */
proto.imscan.ImQuerySignData.prototype.getAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.ImQuerySignData} returns this
 */
proto.imscan.ImQuerySignData.prototype.setAddr = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 sign_time = 2;
 * @return {number}
 */
proto.imscan.ImQuerySignData.prototype.getSignTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.imscan.ImQuerySignData} returns this
 */
proto.imscan.ImQuerySignData.prototype.setSignTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.imscan.ErrMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.imscan.ErrMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.imscan.ErrMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.ErrMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    msg: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.imscan.ErrMsg}
 */
proto.imscan.ErrMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.imscan.ErrMsg;
  return proto.imscan.ErrMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.imscan.ErrMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.imscan.ErrMsg}
 */
proto.imscan.ErrMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.imscan.ErrCode} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMsg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.imscan.ErrMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.imscan.ErrMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.imscan.ErrMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.imscan.ErrMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMsg();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ErrCode code = 1;
 * @return {!proto.imscan.ErrCode}
 */
proto.imscan.ErrMsg.prototype.getCode = function() {
  return /** @type {!proto.imscan.ErrCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.imscan.ErrCode} value
 * @return {!proto.imscan.ErrMsg} returns this
 */
proto.imscan.ErrMsg.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string msg = 2;
 * @return {string}
 */
proto.imscan.ErrMsg.prototype.getMsg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.imscan.ErrMsg} returns this
 */
proto.imscan.ErrMsg.prototype.setMsg = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.imscan.MsgStatus = {
  MS_UNKNOWN: 0,
  MS_WAITING_FOR_SGN_CONFIRMATIONS: 1,
  MS_WAITING_FOR_DESTINATION_EXECUTION: 2,
  MS_COMPLETED: 3,
  MS_FAIL: 4,
  MS_FALLBACK: 5
};

/**
 * @enum {number}
 */
proto.imscan.XferStatus = {
  XS_UNKNOWN: 0,
  XS_WAITING_FOR_SGN_CONFIRMATIONS: 1,
  XS_WAITING_FOR_FUND_RELEASE: 2,
  XS_COMPLETED: 3,
  XS_TO_BE_REFUND: 4,
  XS_REFUND_TO_BE_CONFIRMED: 5,
  XS_REFUNDED: 6,
  XS_DELAYED: 7
};

/**
 * @enum {number}
 */
proto.imscan.ErrCode = {
  ERROR_CODE_UNDEFINED: 0,
  ERROR_CODE_COMMON: 500,
  ERROR_CODE_INVALID_AUTH: 501,
  ERROR_CODE_DB_ERR: 502
};

goog.object.extend(exports, proto.imscan);
