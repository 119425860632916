import React, { useContext } from "react";
import cl from "classnames";
import { Button } from "antd";
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../App';
import { openTransactionOnExplorer, prepareSenderAddressAbbreviation, prepareTransactionHashAbbreviation } from "../../utils/convert";
import AnimateTimer from "./AnimateTimer";

const useStyles = createUseStyles<string, { isMobile: boolean }>(() => ({
    card: {
        position: 'relative',
        padding: '20px 16px',
        marginBottom: 8,
        backgroundColor: '#fff',
        borderRadius: 20,
    },
    src: {

    },
    srcName: {
        fontWeight: 500,
        fontSize: '0.14rem'
    },
    srcItem: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 8,
        color: '#777777',
        fontSize: '0.12rem',
        fontWeight: 700
    },
    srcValue: {
        fontSize: '0.14rem',
        fontWeight: 500,
        color: '#333',
    },
    dst: {
        padding: '12px 8px',
        backgroundColor: '#F7F7F9',
        borderRadius: 12,
    },
    dstTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '0.12rem',
        fontWeight: 700,
        marginBottom: 8,
        color: '#777',
    },
    dstItem: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 8,
        color: '#333',
        fontSize: '0.14rem',
        fontWeight: 500
    },
    chainIcon: {
        width: 24,
        height: 24,
        borderRadius: '50%',
        marginBottom: 4
      },
    chainName: {
        fontSize: 14,
        fontWeight: 500,
        marginLeft: 4
    },
    dstContent: {
        display: 'flex',
        justifyContent: 'space-around'
    },
    dstLeft: {
        minWidth: '50%',
        flex: 1,
    },
    dstRight: {
        // width: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    btn: {
        width: '100%',
        height: 40,
        marginTop: 12,
        backgroundColor: 'rgba(77, 124, 255, 0.1)',
        borderRadius: 16,
        color: '#1874FF',
        border: 'none',
        fontWeight: 700,
        textShadow: 'none',
        boxShadow: '0 2px 3px rgb(0 0 0 / 6%)',
    }
  }));

const MobileTransferCard = (props) => {
    const navigate = useNavigate();
    const { isMobile, state } = useContext(AppContext);
     const classes = useStyles({ isMobile });
     const { value, cls } = props;
     const { sourceChainId, senderAddress, sourceTransactionHash, initialTime, destinationChainIds, assetsTransfer } = value || {};

     const prepareChainInfo = (chainId: number, isSrc) => {
        let chain = state?.transferConfig?.chains?.find((chainInfo) => {
          return chainInfo.id === chainId;
        });
       
        return (
          <div>
            <img src={chain?.icon} className={classes.chainIcon} style={{ width: isSrc ? '24px' : '20px', height: isSrc ? '24px' : '20px' }} alt="chainIcon"/>
            <span className={classes.chainName}>{chain?.name}</span> 
          </div>
        );
    };

    return (
        <div className={cl(cls, classes.card)}>
            <div className={classes.src}>
                <div className={classes.srcName}>
                    {prepareChainInfo(sourceChainId, true)}
                </div>
                <div className={classes.srcItem}>
                    <div className={classes.srcLabel}>Sender Address</div>
                    <div className={classes.srcValue}>{prepareSenderAddressAbbreviation(senderAddress)}</div>
                </div>
                <div className={classes.srcItem}>
                    <div className={classes.srcLabel}>Source TX Hash</div>
                    <div className={classes.srcValue} style={{ color: '#0C82FF' }}>
                        <a className={classes.senderAddress} href={openTransactionOnExplorer(state?.transferConfig?.chains, sourceChainId, sourceTransactionHash)} target="_blank" rel="noreferrer">
                            {prepareTransactionHashAbbreviation(sourceTransactionHash)}
                        </a>
                    </div>
                </div>
                <div className={classes.srcItem}>
                    <div className={classes.srcLabel}>Initiate time</div>
                    <div className={classes.srcValue}><AnimateTimer time={initialTime} /></div>
                    
                </div>
            </div>
            {destinationChainIds.length && <div className={classes.dst}>
                <div className={classes.dstTitle}>
                    <div>Destination chain</div>
                    <div>Assets Transfer</div>
                </div>
                <div className={classes.dstContent}>
                    <div className={classes.dstLeft}>
                         {
                            destinationChainIds.slice(0, 3).map((dstId, idx) => (
                                <div className={classes.dstItem}>
                                    <div className={classes.dstLabel}>{prepareChainInfo(dstId, false)}</div>
                                </div>
                            ))
                        }
                    </div>
                    <div className={classes.dstRight}>
                        {assetsTransfer.map(assets =>  <div className={classes.dstItem}><div className={classes.dstValue} style={{ color: '#00D395' }}>{assets}</div></div>)}
                    </div>
                </div>
            </div>}
            <Button type="primary" className={classes.btn} onClick={() => navigate('/tx/' + sourceTransactionHash)}>
                Details
            </Button>
        </div>
    )
}

export default MobileTransferCard;