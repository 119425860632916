import { Provider } from '@ethersproject/abstract-provider';
import { Signer } from '@ethersproject/abstract-signer';
import { Contract } from '@ethersproject/contracts';

export const readOnlyContract = async (
  signerOrProvider: Signer | Provider | undefined,
  address: string,
  factory: {
    connect(address: string, signerOrProvider: Signer | Provider): Contract;
  }
) => {
  if (typeof signerOrProvider !== 'undefined' && factory && address) {
    const customContract = await factory.connect(address, signerOrProvider);
    return customContract;
  }
  return undefined;
};
