/**
 * @fileoverview gRPC-Web generated client stub for imscan
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.23.2
// source: web.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as web_pb from './web_pb';


export class WebClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorSearchByTxHash = new grpcWeb.MethodDescriptor(
    '/imscan.Web/SearchByTxHash',
    grpcWeb.MethodType.UNARY,
    web_pb.SearchByTxHashRequest,
    web_pb.SearchByTxHashResponse,
    (request: web_pb.SearchByTxHashRequest) => {
      return request.serializeBinary();
    },
    web_pb.SearchByTxHashResponse.deserializeBinary
  );

  searchByTxHash(
    request: web_pb.SearchByTxHashRequest,
    metadata: grpcWeb.Metadata | null): Promise<web_pb.SearchByTxHashResponse>;

  searchByTxHash(
    request: web_pb.SearchByTxHashRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: web_pb.SearchByTxHashResponse) => void): grpcWeb.ClientReadableStream<web_pb.SearchByTxHashResponse>;

  searchByTxHash(
    request: web_pb.SearchByTxHashRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: web_pb.SearchByTxHashResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/imscan.Web/SearchByTxHash',
        request,
        metadata || {},
        this.methodDescriptorSearchByTxHash,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/imscan.Web/SearchByTxHash',
    request,
    metadata || {},
    this.methodDescriptorSearchByTxHash);
  }

  methodDescriptorSearchByAddr = new grpcWeb.MethodDescriptor(
    '/imscan.Web/SearchByAddr',
    grpcWeb.MethodType.UNARY,
    web_pb.SearchByAddrRequest,
    web_pb.SearchByAddrResponse,
    (request: web_pb.SearchByAddrRequest) => {
      return request.serializeBinary();
    },
    web_pb.SearchByAddrResponse.deserializeBinary
  );

  searchByAddr(
    request: web_pb.SearchByAddrRequest,
    metadata: grpcWeb.Metadata | null): Promise<web_pb.SearchByAddrResponse>;

  searchByAddr(
    request: web_pb.SearchByAddrRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: web_pb.SearchByAddrResponse) => void): grpcWeb.ClientReadableStream<web_pb.SearchByAddrResponse>;

  searchByAddr(
    request: web_pb.SearchByAddrRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: web_pb.SearchByAddrResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/imscan.Web/SearchByAddr',
        request,
        metadata || {},
        this.methodDescriptorSearchByAddr,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/imscan.Web/SearchByAddr',
    request,
    metadata || {},
    this.methodDescriptorSearchByAddr);
  }

  methodDescriptorQueryLatestTx = new grpcWeb.MethodDescriptor(
    '/imscan.Web/QueryLatestTx',
    grpcWeb.MethodType.UNARY,
    web_pb.QueryLatestTxRequest,
    web_pb.QueryLatestTxTxResponse,
    (request: web_pb.QueryLatestTxRequest) => {
      return request.serializeBinary();
    },
    web_pb.QueryLatestTxTxResponse.deserializeBinary
  );

  queryLatestTx(
    request: web_pb.QueryLatestTxRequest,
    metadata: grpcWeb.Metadata | null): Promise<web_pb.QueryLatestTxTxResponse>;

  queryLatestTx(
    request: web_pb.QueryLatestTxRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: web_pb.QueryLatestTxTxResponse) => void): grpcWeb.ClientReadableStream<web_pb.QueryLatestTxTxResponse>;

  queryLatestTx(
    request: web_pb.QueryLatestTxRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: web_pb.QueryLatestTxTxResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/imscan.Web/QueryLatestTx',
        request,
        metadata || {},
        this.methodDescriptorQueryLatestTx,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/imscan.Web/QueryLatestTx',
    request,
    metadata || {},
    this.methodDescriptorQueryLatestTx);
  }

  methodDescriptorIMStatus = new grpcWeb.MethodDescriptor(
    '/imscan.Web/IMStatus',
    grpcWeb.MethodType.UNARY,
    web_pb.IMStatusRequest,
    web_pb.IMStatusResponse,
    (request: web_pb.IMStatusRequest) => {
      return request.serializeBinary();
    },
    web_pb.IMStatusResponse.deserializeBinary
  );

  iMStatus(
    request: web_pb.IMStatusRequest,
    metadata: grpcWeb.Metadata | null): Promise<web_pb.IMStatusResponse>;

  iMStatus(
    request: web_pb.IMStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: web_pb.IMStatusResponse) => void): grpcWeb.ClientReadableStream<web_pb.IMStatusResponse>;

  iMStatus(
    request: web_pb.IMStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: web_pb.IMStatusResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/imscan.Web/IMStatus',
        request,
        metadata || {},
        this.methodDescriptorIMStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/imscan.Web/IMStatus',
    request,
    metadata || {},
    this.methodDescriptorIMStatus);
  }

  methodDescriptorGetOverallStats = new grpcWeb.MethodDescriptor(
    '/imscan.Web/GetOverallStats',
    grpcWeb.MethodType.UNARY,
    web_pb.GetOverallStatsRequest,
    web_pb.GetOverallStatsResponse,
    (request: web_pb.GetOverallStatsRequest) => {
      return request.serializeBinary();
    },
    web_pb.GetOverallStatsResponse.deserializeBinary
  );

  getOverallStats(
    request: web_pb.GetOverallStatsRequest,
    metadata: grpcWeb.Metadata | null): Promise<web_pb.GetOverallStatsResponse>;

  getOverallStats(
    request: web_pb.GetOverallStatsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: web_pb.GetOverallStatsResponse) => void): grpcWeb.ClientReadableStream<web_pb.GetOverallStatsResponse>;

  getOverallStats(
    request: web_pb.GetOverallStatsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: web_pb.GetOverallStatsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/imscan.Web/GetOverallStats',
        request,
        metadata || {},
        this.methodDescriptorGetOverallStats,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/imscan.Web/GetOverallStats',
    request,
    metadata || {},
    this.methodDescriptorGetOverallStats);
  }

  methodDescriptorGetStatsWithFilter = new grpcWeb.MethodDescriptor(
    '/imscan.Web/GetStatsWithFilter',
    grpcWeb.MethodType.UNARY,
    web_pb.GetStatsWithFilterRequest,
    web_pb.GetStatsWithFilterResponse,
    (request: web_pb.GetStatsWithFilterRequest) => {
      return request.serializeBinary();
    },
    web_pb.GetStatsWithFilterResponse.deserializeBinary
  );

  getStatsWithFilter(
    request: web_pb.GetStatsWithFilterRequest,
    metadata: grpcWeb.Metadata | null): Promise<web_pb.GetStatsWithFilterResponse>;

  getStatsWithFilter(
    request: web_pb.GetStatsWithFilterRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: web_pb.GetStatsWithFilterResponse) => void): grpcWeb.ClientReadableStream<web_pb.GetStatsWithFilterResponse>;

  getStatsWithFilter(
    request: web_pb.GetStatsWithFilterRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: web_pb.GetStatsWithFilterResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/imscan.Web/GetStatsWithFilter',
        request,
        metadata || {},
        this.methodDescriptorGetStatsWithFilter,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/imscan.Web/GetStatsWithFilter',
    request,
    metadata || {},
    this.methodDescriptorGetStatsWithFilter);
  }

  methodDescriptorGetPerSrcPerDstTable = new grpcWeb.MethodDescriptor(
    '/imscan.Web/GetPerSrcPerDstTable',
    grpcWeb.MethodType.UNARY,
    web_pb.GetPerSrcPerDstTableRequest,
    web_pb.GetPerSrcPerDstTableResponse,
    (request: web_pb.GetPerSrcPerDstTableRequest) => {
      return request.serializeBinary();
    },
    web_pb.GetPerSrcPerDstTableResponse.deserializeBinary
  );

  getPerSrcPerDstTable(
    request: web_pb.GetPerSrcPerDstTableRequest,
    metadata: grpcWeb.Metadata | null): Promise<web_pb.GetPerSrcPerDstTableResponse>;

  getPerSrcPerDstTable(
    request: web_pb.GetPerSrcPerDstTableRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: web_pb.GetPerSrcPerDstTableResponse) => void): grpcWeb.ClientReadableStream<web_pb.GetPerSrcPerDstTableResponse>;

  getPerSrcPerDstTable(
    request: web_pb.GetPerSrcPerDstTableRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: web_pb.GetPerSrcPerDstTableResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/imscan.Web/GetPerSrcPerDstTable',
        request,
        metadata || {},
        this.methodDescriptorGetPerSrcPerDstTable,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/imscan.Web/GetPerSrcPerDstTable',
    request,
    metadata || {},
    this.methodDescriptorGetPerSrcPerDstTable);
  }

  methodDescriptorSyncData = new grpcWeb.MethodDescriptor(
    '/imscan.Web/SyncData',
    grpcWeb.MethodType.UNARY,
    web_pb.SyncDataRequest,
    web_pb.SyncDataeResponse,
    (request: web_pb.SyncDataRequest) => {
      return request.serializeBinary();
    },
    web_pb.SyncDataeResponse.deserializeBinary
  );

  syncData(
    request: web_pb.SyncDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<web_pb.SyncDataeResponse>;

  syncData(
    request: web_pb.SyncDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: web_pb.SyncDataeResponse) => void): grpcWeb.ClientReadableStream<web_pb.SyncDataeResponse>;

  syncData(
    request: web_pb.SyncDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: web_pb.SyncDataeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/imscan.Web/SyncData',
        request,
        metadata || {},
        this.methodDescriptorSyncData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/imscan.Web/SyncData',
    request,
    metadata || {},
    this.methodDescriptorSyncData);
  }

}

