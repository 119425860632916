import { Chain } from '../constants/interfaces';

export const prepareSenderAddressAbbreviation = (address: string) => {
    return address?.substr(0, 6) + '...' + address?.slice(-4);
  };

export const prepareTransactionHashAbbreviation = (transactionHash: string) => {
    return transactionHash?.substr(0, 8) + '...';
  };

export const openTransactionOnExplorer = (chains: Chain[], chainId: number, transactionHash: string) => {
    let chain = chains?.find((chainInfo) => {
      return chainInfo.id === chainId;
    });

    let mediator = 'tx/';

    if (chainId === 12340001 || chainId === 12340002) {
      mediator = 'transaction/';
    }
    return chain?.explore_url + mediator + transactionHash;
};