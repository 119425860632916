import cl from 'classnames';
import { createUseStyles } from 'react-jss';
import { Input } from 'antd';
import Search from '../../imgs/search.svg';

const useStyles = createUseStyles(() => ({
  search: {
    // backgroundColor: '#F3F3F3',
    backgroundColor: '#fff',
    borderRadius: 16,
    height: 40,
    border: 'none',
  }
}));

function SearchBar(props): JSX.Element {
  const { className, value, onInputChange, onInputSearch } = props;
  const classes = useStyles();

  return (
    <Input
      className={cl(className, classes.search)}
      prefix={<img src={Search} alt="prefix" />}
      value={value}
      onChange={onInputChange}
      onPressEnter={onInputSearch}
    />
  );
}

export default SearchBar;
