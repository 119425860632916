import React from 'react';
import { createUseStyles } from 'react-jss';
// import { GithubFilled, TwitterCircleFilled } from '@ant-design/icons';
// import { DiscordCircleFilled, TelegramCircleFilled } from '../../icons';

const useStyles = createUseStyles(() => ({
  footer: {
    padding: '55px 0',
    backgroundColor: '#F3F3F3',
    textAlign: 'center'
  },
  text: {
    fontSize: 12,
    fontWeight: 400,
    color: '#87828D'
  },
  social: {
    display: 'flex',
    gap: 24,
    alignItems: 'center',
    justifyContent: 'center',
    color: '#87828D',
    marginTop: 18,
    fontSize: 24
  }
}));

function Footer(props): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <div className={classes.text}>IMScan © 2022</div>
      {/* <div className={classes.social}>
        <DiscordCircleFilled onClick={() => window.open('https://discord.gg/uGx4fjQ', '_blank')} />
        <TelegramCircleFilled onClick={() => window.open('https://t.me/celernetwork', '_blank')} />
        <TwitterCircleFilled onClick={() => window.open('https://twitter.com/CelerNetwork', '_blank')} />
        <GithubFilled onClick={() => window.open('https://github.com/celer-network', '_blank')} />
      </div> */}
    </div>
  );
}

export default Footer;
