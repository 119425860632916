import ReactECharts from 'echarts-for-react';
import { DailyTransactionCount } from '../../constants/interfaces';
import moment from 'antd/node_modules/moment';

export type DailyChartProps = {
  dailyChartCount: DailyTransactionCount[];
  dailyChartAxis: string[];
  width: number;
  isMobile: boolean;
};

function DailyChart(props: DailyChartProps): JSX.Element {
  
  const seriesData = props.dailyChartCount.map((el) => el.count);
  const xAxisData = props.dailyChartCount.map((el) => el.time);
  const getOption = () => {
    return {
      title: {
        text: 'Daily Transaction Count',
        textStyle: {
          color: '#000000',
          fontWeight: '700',
          fontSize: 16,
          marginTop: 24
        },
        top: 24,
        left: 24
      },
      grid: {
        left: props.isMobile ? 20 : 30,
        right: props.isMobile ? 20 : 30,
        bottom: props.isMobile ? 40 : 54,
      },
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
          type: 'none'
        },
        padding: [12, 8],
        formatter: (params) => {
          var dotHtml = '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#1874FF"></span>'
          var res = 
              "<div style='padding:'12px 8px';border-radius:8px;background:#fff;font-size:14px;'>" +
              "<span style='color:#777;font-weight:400'>"+  params[0].name +"</span>" +
                "<div style='color:#000;font-weight:700;margin-top:8px'>" + dotHtml + 'Transaction Count: ' + params[0].value + '</div>' +
              "</div>";
            return res;
        }
      },
      xAxis: {
        data: xAxisData,
        axisLabel: {
          textStyle: {
            color: '#86909C',
            fontSize: 12,
          },
          formatter(val, i) {
            return moment(val).format('MMM DD');
          }
        },
        axisTick: {
          show: false
        },
        boundaryGap: false,
        axisLine: {
          lineStyle: {
            color: '#ECEBEE',
            width: 1,
            type: 'solid'
          },
          onZero: false,
        }
      },
      yAxis: {
        show: false
      },
      series: [
        {
          name: 'Transaction Count',
          type: 'line',
          symbol: 'emptyCircle',
          showSymbol: false,
          symbolSize: 8,
          smooth: true,
          data: seriesData,
          lineStyle: {
            normal: {
              color: '#0066FF'
            }
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(0, 102, 255, 0.2)'
                },
                {
                  offset: 1,
                  color: 'rgba(0, 102, 255, 0)'
                }
              ]
            }
          }
        }
      ]
    };
  };
  return <ReactECharts option={getOption()} style={{width: props.isMobile ? props.width * 0.83 : '100%', height: '100%'}} />;
}
export default DailyChart;
