import React, { useContext } from 'react';
import { Button } from 'antd';
import { createUseStyles } from 'react-jss';
import NoAddress from '../../imgs/noAddress.svg';
import NoTx from '../../imgs/noTx.svg';
import Invalid from '../../imgs/invalid.svg';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../App';

const useStyles = createUseStyles<string, { isMobile: boolean }>(() => ({
  card: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: 500,
    width: '100%',
    position: 'relative',
    backgroundColor: '#FFFFFF',
    borderRadius: 20,
    textAlign: 'center'
  },
  img: {
    width: (props) => props.isMobile ? '90%' : 900,
    height: (props) => props.isMobile ? 'auto' : 300,
    position: 'absolute',
    bottom:  (props) => props.isMobile ? '80px' : 0,
    left: (props) => props.isMobile ? '5%' : '50%',
    transform: (props) => props.isMobile ? '0' : 'translateX(-450px)'
  },
  title: {
    color: '#000',
    fontSize: (props) => props.isMobile ? '0.2rem' : 32,
    fontWeight: 900,
    paddingTop: 48
  },
  discribe: {
    color: '#4C7CFF',
    fontSize: (props) => props.isMobile ? '0.14rem' : 24,
    fontWeight: 700,
    width: (props) => props.isMobile ? '90%' : 653,
    marginLeft: (props) => props.isMobile ? '5%' :'50%',
    transform:  (props) => props.isMobile ? '0' : 'translateX(-326px)',
    lineHeight: '24px',
    padding:  (props) => props.isMobile ? '0 10px' : 0,
  },
  btn: {
    width: 150,
    height: 48,
    borderRadius: 9,
    backgroundColor: '#000',
    marginTop: 24,
    border: 'none',
    '&:hover': {
      backgroundColor: '#000',
      color: '#fff'
    },
    '&:focus': {
      backgroundColor: '#000',
      color: '#fff'
    }
  }
}));

export function AddressEmptyCard(props): JSX.Element {
  const { isMobile } = useContext(AppContext);
  const classes = useStyles({ isMobile });
  const navigate = useNavigate();

  return (
    <div className={classes.card}>
      <div className={classes.title}>No transactions found for this address</div>
      <Button type="primary" className={classes.btn} onClick={() => navigate('/')}>
        Back Home
      </Button>
      <img className={classes.img} src={NoAddress} alt="" />
    </div>
  );
}

export function TxEmptyCard(props): JSX.Element {
  const { isMobile } = useContext(AppContext);
  const classes = useStyles({ isMobile });
  const navigate = useNavigate();

  return (
    <div className={classes.card}>
      <div className={classes.title}>No result found</div>
      <div className={classes.discribe}>
        If you just submitting the transaction, please allow ~30 minutes for the transaction to be synced in the IM scan.
      </div>
      <Button type="primary" className={classes.btn} onClick={() => navigate('/')}>
        Back Home
      </Button>
      <img className={classes.img} src={NoTx} alt="" />
    </div>
  );
}

export function InputInvalidCard(props): JSX.Element {
  const { isMobile } = useContext(AppContext);
  const classes = useStyles({ isMobile });
  const navigate = useNavigate();

  return (
    <div className={classes.card}>
      <div className={classes.title}>Invalid tx hash or address</div>
      <Button type="primary" className={classes.btn} onClick={() => navigate('/')}>
        Back Home
      </Button>
      <img className={classes.img} src={Invalid} alt="" />
    </div>
  );
}
