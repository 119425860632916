export const stagingNetworks = {
  883: {
    name: '883',
    color: '#3099f2',
    chainId: 883,
    rpcUrl: 'http://localhost:8545',
    blockTime: 5000,
    iconUrl: './noChain.png',
    blockDelay: 15,
    symbol: '',
    blockExplorerUrl: '',
    tokenSymbolList: ['USDT'],
    lqMintTokenSymbolBlackList: []
  },
  884: {
    name: '884',
    color: '#3099f2',
    chainId: 884,
    rpcUrl: 'http://localhost:8547',
    blockTime: 5000,
    iconUrl: './noChain.png',
    blockDelay: 15,
    symbol: '',
    blockExplorerUrl: '',
    tokenSymbolList: ['USDT'],
    lqMintTokenSymbolBlackList: []
  },
  localhost: {
    name: '--',
    color: '#666666',
    chainId: 31337,
    rpcUrl: 'http://localhost:8545',
    blockTime: 3000,
    iconUrl: './defaultIcon.svg',
    blockDelay: 4,
    symbol: 'ETH',
    blockExplorerUrl: '',
    tokenSymbolList: ['USDT'],
    lqMintTokenSymbolBlackList: []
  },
  bscTest: {
    name: 'BSC Testnet',
    color: '#7057ff',
    chainId: 97,
    rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    blockTime: 4000,
    iconUrl: './bsc.png',
    blockDelay: 80,
    symbol: 'BNB',
    blockExplorerUrl: 'https://testnet.bscscan.com',
    tokenSymbolList: ['CELR', 'USDT', 'WETH', 'DOMI', 'MCB'],
    lqMintTokenSymbolBlackList: ['DOMI']
  },
  ropsten: {
    name: 'Ropsten Testnet',
    color: '#ff4a8d',
    chainId: 3,
    rpcUrl: 'https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    blockTime: 15000,
    iconUrl: './ETH.png',
    blockDelay: 4,
    symbol: 'ETH',
    blockExplorerUrl: 'https://ropsten.etherscan.io',
    tokenSymbolList: ['CELR', 'USDT', 'WETH'],
    lqMintTokenSymbolBlackList: []
  },
  goerli: {
    name: 'Goerli Testnet',
    color: '#3099f2',
    chainId: 5,
    rpcUrl: 'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    blockTime: 15000,
    iconUrl: './ETH.png',
    blockDelay: 4,
    symbol: 'ETH',
    blockExplorerUrl: 'https://goerli.etherscan.io',
    tokenSymbolList: ['CELR', 'USDT', 'WETH', 'TCELR', 'USDC', 'DOMI', 'MCB', 'cUSD'],
    lqMintTokenSymbolBlackList: ['DOMI', 'cUSD']
  },
  mainnet: {
    name: 'Ethereum Mainnet',
    color: '#29b6af',
    chainId: 1,
    rpcUrl: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    blockTime: 15000,
    iconUrl: './ETH.png',
    blockDelay: 8,
    symbol: 'ETH',
    blockExplorerUrl: 'https://etherscan.io',
    tokenSymbolList: [
      'USDC',
      'USDT',
      'WETH',
      'BUSD',
      'DODO',
      'LYRA',
      'DOMI',
      'MCB',
      'IMX',
      'oneDODO',
      'OLO',
      'BOBA',
      'PSP',
      'XTK',
      'Metis',
      'WXT',
      'PEOPLE',
      'SOS',
      'FRAX',
      'CEC',
      'STND',
      'WOO',
      'CELR',
      'KROM',
      'PERP',
      'SAFLE',
      'DAI',
      'PKEX',
      'WBTC',
      'GHX',
      'CONV',
      'TSD',
      'AAVE',
      'CRV',
      'SYS',
      'FXS',
      'DF',
      'USX',
      'AELIN',
      'REVA',
      'JPEG',
      'CGG',
      'AVG',
      'PERL',
      'WAGMIV1',
      'WAGMIV2',
      'MASK',
      'LUSD',
      'THALES',
      'MSU',
      'UCG',
      'MELOS',
      'REEF',
      'iZi',
      'ASTR',
      'MGH',
      'ANML',
      'HUH',
      'GOVI',
      'GEL',
      'DEVT',
      'DTR',
      'RING',
      'NFTY',
      'RLY',
      'TORN',
      'AKRO',
      'iUSD',
      'SHI',
      'OOKI',
      'WAGMIV3',
      'PBR',
      'FLX',
      'PLOT',
      'XBP',
      'RAGE',
      'UMA',
      'SOLACE',
      'LFI',
      'cfUSDC',
      'GNO',
      'LUSD',
      'WING'
    ],
    lqMintTokenSymbolBlackList: [
      'DOMI',
      'oneDODO',
      'OLO',
      'PSP',
      'WXT',
      'PEOPLE',
      'SOS',
      'FRAX',
      'CELR',
      'SAFLE',
      'DAI',
      'PKEX',
      'WBTC',
      'GHX',
      'CONV',
      'TSD',
      'AAVE',
      'CRV',
      'SYS',
      'FXS',
      'AELIN',
      'REVA',
      'CGG',
      'AVG',
      'WAGMIV1',
      'WAGMIV2',
      'MSU',
      'UCG',
      'MELOS',
      'ASTR',
      'MGH',
      'GEL',
      'DEVT',
      'DTR',
      'NFTY',
      'RLY',
      'AKRO',
      'WAGMIV3',
      'PBR',
      'FLX',
      'PLOT',
      'XBP',
      'RAGE',
      'UMA',
      'SOLACE',
      'LFI',
      'cfUSDC',
      'GNO'
    ]
  },
  bsc: {
    name: 'BNB Chain',
    color: '#7057ff',
    chainId: 56,
    rpcUrl: 'https://bscrpc.com/',
    blockTime: 4000,
    iconUrl: './bnbchain.png',
    blockDelay: 8,
    symbol: 'BNB',
    blockExplorerUrl: 'https://bscscan.com',
    tokenSymbolList: [
      'USDC',
      'USDT',
      'WETH',
      'BUSD',
      'DODO',
      'DOMI',
      'MCB',
      'oneDODO',
      'OLO',
      'PSP',
      'ATL',
      'PEOPLE',
      'SOS',
      'FRAX',
      'CEC',
      'WOO',
      'PERP',
      'SAFLE',
      'WBNB',
      'BNB',
      'GHX',
      'TSD',
      'SYS',
      'FXS',
      'DF',
      'USX',
      'REVA',
      'JPEG',
      'AVG',
      'PERL',
      'MASK',
      'LUSD',
      'SWAY',
      'MSU',
      'UCG',
      'MELOS',
      'REEF',
      'MGH',
      'ANML',
      'HUH',
      'iZi',
      'DTR',
      'TORN',
      'AKRO',
      'iUSD',
      'SHI',
      'OOKI',
      'PBR',
      'PEEL',
      'SHELL',
      'GNO',
      'OKSE',
      'WING'
    ],
    lqMintTokenSymbolBlackList: [
      'oneDODO',
      'OLO',
      'PSP',
      'ATL',
      'PEOPLE',
      'SOS',
      'FRAX',
      'SAFLE',
      'BNB',
      'GHX',
      'TSD',
      'SYS',
      'FXS',
      'REVA',
      'AVG',
      'LUSD',
      'SWAY',
      'MSU',
      'UCG',
      'MELOS',
      'MGH',
      'DTR',
      'AKRO',
      'OOKI',
      'PBR',
      'PEEL',
      'SHELL',
      'GNO',
      'OKSE'
    ]
  },

  polygon: {
    name: 'Polygon (Matic)',
    color: '#3099f2',
    chainId: 137,
    rpcUrl: 'https://rpc.ankr.com/polygon',
    blockTime: 4000,
    iconUrl: './polygon.png',
    blockDelay: 80,
    symbol: 'MATIC',
    blockExplorerUrl: 'https://polygonscan.com',
    tokenSymbolList: [
      'USDC',
      'USDT',
      'WETH',
      'IMX',
      'WXT',
      'ATL',
      'FRAX',
      'WOO',
      'MAI',
      'SAFLE',
      'MATIC',
      'FXS',
      'REVA',
      'KROM',
      'SWAY',
      'USX',
      'DF',
      'ANML',
      'HUH',
      'GOVI',
      'DEVT',
      'RING',
      'THALES',
      'iUSD',
      'iZi',
      'GEL',
      'JPYC',
      'AX',
      'BIFI',
      'GREEN',
      'QI',
      'OOKI',
      'SOLACE'
    ],
    lqMintTokenSymbolBlackList: [
      'WXT',
      'ATL',
      'FRAX',
      'MAI',
      'SAFLE',
      'MATIC',
      'FXS',
      'REVA',
      'SWAY',
      'DEVT',
      'GEL',
      'AX',
      'BIFI',
      'GREEN',
      'QI',
      'SOLACE'
    ]
  },

  arbitrum: {
    name: 'Arbitrum',
    color: '#ff4a8d',
    chainId: 42161,
    rpcUrl: 'https://arb1.arbitrum.io/rpc',
    blockTime: 4000,
    iconUrl: './arbitrum.png',
    blockDelay: 8,
    symbol: 'ETH',
    blockExplorerUrl: 'https://arbiscan.io',
    tokenSymbolList: [
      'USDC',
      'USDT',
      'WETH',
      'DODO',
      'MCB',
      'IMX',
      'XTK',
      'FRAX',
      'WOO',
      'KROM',
      'PERP',
      'FXS',
      'DF',
      'USX',
      'AMY',
      'REVA',
      'iZi',
      'GOVI',
      'AKRO',
      'iUSD',
      'OOKI'
    ],
    lqMintTokenSymbolBlackList: ['FRAX', 'FXS', 'AMY', 'REVA', 'AKRO']
  },
  Avalanche: {
    name: 'Avalanche',
    color: '#3099f2',
    chainId: 43114,
    rpcUrl: 'https://api.avax.network/ext/bc/C/rpc',
    blockTime: 5000,
    iconUrl: './avalanche.png',
    blockDelay: 15,
    symbol: 'AVAX',
    blockExplorerUrl: 'https://snowtrace.io',
    tokenSymbolList: [
      'USDC',
      'USDT',
      'WETH',
      'IMX',
      'WXT',
      'PEOPLE',
      'SOS',
      'FRAX',
      'WOO',
      'ATL',
      'MAI',
      'TSD',
      'DOMI',
      'FXS',
      'AVAX',
      'REVA',
      'CGG',
      'LUSD',
      'JPEG',
      'CRA',
      'CRAM',
      'TUS',
      'JPYC',
      'USX',
      'GNO',
      'OKSE'
    ],
    lqMintTokenSymbolBlackList: [
      'WXT',
      'PEOPLE',
      'SOS',
      'FRAX',
      'ATL',
      'MAI',
      'TSD',
      'FXS',
      'AVAX',
      'REVA',
      'CGG',
      'LUSD',
      'CRA',
      'CRAM',
      'TUS',
      'GNO',
      'OKSE'
    ]
  },
  Optimism: {
    name: 'Optimism',
    color: '#3099f2',
    chainId: 10,
    rpcUrl: 'https://mainnet.optimism.io',
    blockTime: 5000,
    iconUrl: './optimism.png',
    blockDelay: 8,
    symbol: 'ETH',
    blockExplorerUrl: 'https://optimistic.etherscan.io',
    tokenSymbolList: ['USDC', 'USDT', 'WETH', 'LYRA', 'KROM', 'PERP', 'FRAX', 'FXS', 'DF', 'USX', 'AELIN', 'REVA', 'THALES', 'LUSD'],
    lqMintTokenSymbolBlackList: ['FRAX', 'FXS', 'AELIN', 'REVA']
  },
  OptimisticKovan: {
    name: 'Optimistic Kovan',
    color: '#3099f2',
    chainId: 69,
    rpcUrl: 'https://kovan.optimism.io',
    blockTime: 5000,
    iconUrl: './optimism.png',
    blockDelay: 8,
    symbol: 'ETH',
    blockExplorerUrl: 'https://kovan-optimistic.etherscan.io',
    tokenSymbolList: ['CELR', 'USDT', 'WETH'],
    lqMintTokenSymbolBlackList: []
  },
  Fantom: {
    name: 'Fantom',
    color: '#3099f2',
    chainId: 250,
    rpcUrl: 'https://rpc.ankr.com/fantom',
    blockTime: 5000,
    iconUrl: './fantom.png',
    blockDelay: 6,
    symbol: 'FTM',
    blockExplorerUrl: 'https://ftmscan.com',
    tokenSymbolList: [
      'USDC',
      'USDT',
      'WETH',
      'PEOPLE',
      'SOS',
      'FRAX',
      'WOO',
      'FXS',
      'FTM',
      'REVA',
      'IMX',
      'LUSD',
      'GEL',
      'SOLACE',
      'GNO',
      'OKSE'
    ],
    lqMintTokenSymbolBlackList: ['PEOPLE', 'SOS', 'FRAX', 'FXS', 'FTM', 'REVA', 'LUSD', 'GEL', 'SOLACE', 'GNO', 'OKSE']
  },
  FantomTestnet: {
    name: 'Fantom Testnet',
    color: '#3099f2',
    chainId: 4002,
    rpcUrl: 'https://rpc.testnet.fantom.network',
    blockTime: 5000,
    iconUrl: './fantom.png',
    blockDelay: 6,
    symbol: 'FTM',
    blockExplorerUrl: 'https://testnet.ftmscan.com',
    tokenSymbolList: ['CELR', 'USDT', 'TCELR'],
    lqMintTokenSymbolBlackList: []
  },

  Harmony: {
    name: 'Harmony',
    color: '#3099f2',
    chainId: 1666600000,
    rpcUrl: 'https://api.harmony.one',
    blockTime: 5000,
    iconUrl: './harmony.png',
    blockDelay: 20,
    symbol: 'ONE',
    blockExplorerUrl: 'https://explorer.harmony.one',
    tokenSymbolList: ['FRAX', 'PEOPLE', 'SOS', 'USDC', 'WETH', 'MAI', 'FXS', 'REVA'],
    lqMintTokenSymbolBlackList: ['FRAX', 'PEOPLE', 'SOS', 'MAI', 'FXS', 'REVA']
  },

  Moonriver: {
    name: 'Moonriver',
    color: '#3099f2',
    chainId: 1285,
    rpcUrl: 'https://rpc.moonriver.moonbeam.network',
    blockTime: 5000,
    iconUrl: './moonriver.png',
    blockDelay: 20,
    symbol: 'MOVR',
    blockExplorerUrl: 'https://moonriver.moonscan.io',
    tokenSymbolList: ['FRAX', 'PEOPLE', 'SOS', 'MAI', 'FXS', 'IMX', 'DODO'],
    lqMintTokenSymbolBlackList: ['FRAX', 'PEOPLE', 'SOS', 'MAI', 'FXS', 'DODO']
  },

  BoBa: {
    name: 'Boba Network',
    color: '#3099f2',
    chainId: 288,
    rpcUrl: 'https://mainnet.boba.network',
    blockTime: 10000,
    iconUrl: './boba.png',
    blockDelay: 8,
    symbol: 'ETH',
    blockExplorerUrl: 'https://blockexplorer.boba.network',
    tokenSymbolList: ['USDC', 'OLO', 'BOBA', 'FRAX', 'FXS', 'WAGMIV1', 'WAGMIV2', 'WAGMIV3'],
    lqMintTokenSymbolBlackList: ['OLO', 'FRAX', 'FXS', 'WAGMIV1', 'WAGMIV2', 'WAGMIV3']
  },

  CeloTestnet: {
    name: 'Celo Testnet',
    color: '#3099f2',
    chainId: 44787,
    rpcUrl: 'https://alfajores-forno.celo-testnet.org',
    blockTime: 10000,
    iconUrl: './celo.png',
    blockDelay: 8,
    symbol: 'CELO',
    blockExplorerUrl: 'https://alfajores-blockscout.celo-testnet.org',
    tokenSymbolList: ['USDC', 'cUSD'],
    lqMintTokenSymbolBlackList: ['USDC', 'cUSD']
  },

  Metis: {
    name: 'Metis',
    color: '#3099f2',
    chainId: 1088,
    rpcUrl: 'https://andromeda.metis.io/?owner=1088',
    blockTime: 10000,
    iconUrl: './metis_chain.png',
    blockDelay: 8,
    symbol: 'Metis',
    blockExplorerUrl: 'https://andromeda-explorer.metis.io',
    tokenSymbolList: ['Metis', 'PEOPLE', 'SOS', 'STND'],
    lqMintTokenSymbolBlackList: ['PEOPLE', 'SOS']
  },

  OKXChain: {
    name: 'OKX Chain',
    color: '#3099f2',
    chainId: 66,
    rpcUrl: 'https://exchainrpc.okex.org',
    blockTime: 10000,
    iconUrl: './oec.png',
    blockDelay: 8,
    symbol: 'OKT',
    blockExplorerUrl: 'https://www.oklink.com/en/oec',
    tokenSymbolList: ['PEOPLE', 'SOS', 'PLATO', 'MARK', 'WING'],
    lqMintTokenSymbolBlackList: ['PEOPLE', 'SOS', 'PLATO', 'MARK']
  },

  xDai: {
    name: 'Gnosis Chain',
    color: '#3099f2',
    chainId: 100,
    rpcUrl: 'https://rpc.xdaichain.com',
    blockTime: 10000,
    iconUrl: './gnosis.png',
    blockDelay: 8,
    symbol: 'xDai',
    blockExplorerUrl: 'https://blockscout.com/xdai/mainnet',
    tokenSymbolList: ['PEOPLE', 'SOS'],
    lqMintTokenSymbolBlackList: ['PEOPLE', 'SOS']
  },

  Heco: {
    name: 'Heco',
    color: '#3099f2',
    chainId: 128,
    rpcUrl: 'https://http-mainnet.hecochain.com',
    blockTime: 10000,
    iconUrl: './heco.png',
    blockDelay: 8,
    symbol: 'HT',
    blockExplorerUrl: 'https://hecoinfo.com',
    tokenSymbolList: ['PEOPLE', 'SOS', 'PLATO', 'MARK', 'RING'],
    lqMintTokenSymbolBlackList: ['PEOPLE', 'SOS', 'PLATO', 'MARK']
  },

  Aurora: {
    name: 'Aurora',
    color: '#3099f2',
    chainId: 1313161554,
    rpcUrl: 'https://mainnet.aurora.dev',
    blockTime: 10000,
    iconUrl: './Aurora.png',
    blockDelay: 8,
    symbol: 'aETH',
    blockExplorerUrl: 'https://aurorascan.dev',
    tokenSymbolList: ['USDC', 'USDT', 'FRAX', 'FXS', 'BUSD', 'FTM', 'AURORA', 'SOLACE'],
    lqMintTokenSymbolBlackList: ['FRAX', 'FXS', 'BUSD', 'FTM', 'AURORA', 'SOLACE']
  },

  Celo: {
    name: 'Celo',
    color: '#3099f2',
    chainId: 42220,
    rpcUrl: 'https://forno.celo.org',
    blockTime: 10000,
    iconUrl: './celo.png',
    blockDelay: 8,
    symbol: 'CELO',
    blockExplorerUrl: 'https://explorer.celo.org',
    tokenSymbolList: ['PEOPLE', 'SOS'],
    lqMintTokenSymbolBlackList: ['PEOPLE', 'SOS']
  },

  Moonbeam: {
    name: 'Moonbeam',
    color: '#3099f2',
    chainId: 1284,
    rpcUrl: 'https://rpc.api.moonbeam.network',
    blockTime: 5000,
    iconUrl: './moonbeam.png',
    blockDelay: 20,
    symbol: 'GLMR',
    blockExplorerUrl: 'https://moonbeam.moonscan.io',
    tokenSymbolList: ['USDC', 'USDT', 'CELR', 'PEOPLE', 'FRAX', 'CONV', 'WETH', 'WBTC', 'BUSD', 'FXS'],
    lqMintTokenSymbolBlackList: ['USDC', 'USDT', 'CELR', 'PEOPLE', 'FRAX', 'CONV', 'WETH', 'WBTC', 'BUSD', 'FXS']
  },
  Astar: {
    name: 'Astar',
    color: '#3099f2',
    chainId: 592,
    rpcUrl: 'https://astar.api.onfinality.io/public',
    blockTime: 5000,
    iconUrl: './astar.png',
    blockDelay: 20,
    symbol: 'ASTR',
    blockExplorerUrl: 'https://blockscout.com/astar',
    tokenSymbolList: ['WETH', 'USDT', 'USDC', 'DAI', 'BNB', 'BUSD', 'PKEX', 'WBTC', 'SDN', 'MATIC', 'AAVE', 'CRV', 'ASTR', 'NFTY', 'JPYC'],
    lqMintTokenSymbolBlackList: ['WETH', 'USDT', 'DAI', 'BNB', 'BUSD', 'WBTC', 'SDN', 'MATIC', 'AAVE', 'CRV', 'ASTR', 'NFTY']
  },
  Shiden: {
    name: 'Shiden',
    color: '#3099f2',
    chainId: 336,
    rpcUrl: 'https://evm.shiden.astar.network',
    blockTime: 5000,
    iconUrl: './shiden.png',
    blockDelay: 20,
    symbol: 'SDN',
    blockExplorerUrl: 'https://shiden.subscan.io',
    tokenSymbolList: ['SDN', 'PKEX'],
    lqMintTokenSymbolBlackList: ['SDN']
  },

  Oasis: {
    name: 'Oasis Emerald',
    color: '#3099f2',
    chainId: 42262,
    rpcUrl: 'https://emerald.oasis.dev',
    blockTime: 10000,
    iconUrl: './oasis.png',
    blockDelay: 8,
    symbol: 'ROSE',
    blockExplorerUrl: 'https://www.oasisscan.com',
    tokenSymbolList: ['USDC', 'USDT', 'WETH', 'BNB', 'AVAX', 'FTM', 'AMY', 'DAI', 'CELR'],
    lqMintTokenSymbolBlackList: ['USDC', 'USDT', 'WETH', 'BNB', 'AVAX', 'FTM', 'AMY', 'DAI', 'CELR']
  },

  Milkomeda: {
    name: 'Milkomeda Cardano',
    color: '#3099f2',
    chainId: 2001,
    rpcUrl: 'https://rpc-mainnet-cardano-evm.c1.milkomeda.com',
    blockTime: 5000,
    iconUrl: './milkomeda.png',
    blockDelay: 20,
    symbol: 'ADA',
    blockExplorerUrl: 'https://explorer-mainnet-cardano-evm.c1.milkomeda.com',
    tokenSymbolList: ['PKEX', 'USDT', 'USDC', 'WETH', 'WBTC', 'DAI', 'BNB', 'BUSD', 'AVAX', 'FTM', 'MATIC'],
    lqMintTokenSymbolBlackList: ['USDT', 'USDC', 'WETH', 'WBTC', 'DAI', 'BNB', 'BUSD', 'AVAX', 'FTM', 'MATIC']
  },

  CloverMainnet: {
    name: 'Clover Mainnet',
    color: '#3099f2',
    chainId: 1024,
    rpcUrl: 'https://api-para.clover.finance',
    blockTime: 10000,
    iconUrl: './clover.png',
    blockDelay: 8,
    symbol: 'CLV',
    blockExplorerUrl: 'https://clvscan.com',
    tokenSymbolList: ['WETH', 'USDC', 'USDT', 'DAI', 'WBTC'],
    lqMintTokenSymbolBlackList: ['WETH', 'USDC', 'USDT', 'DAI', 'WBTC']
  },

  Conflux: {
    name: 'Conflux',
    color: '#3099f2',
    chainId: 1030,
    rpcUrl: 'https://evm.confluxrpc.com',
    blockTime: 10000,
    iconUrl: './conflux.png',
    blockDelay: 8,
    symbol: 'CFX',
    blockExplorerUrl: 'https://evm.confluxscan.net',
    tokenSymbolList: ['WETH', 'USDC', 'USDT', 'DAI', 'WBTC'],
    lqMintTokenSymbolBlackList: ['WETH', 'USDC', 'USDT', 'DAI', 'WBTC']
  },

  REI: {
    name: 'REI Network',
    color: '#3099f2',
    chainId: 47805,
    rpcUrl: 'https://rpc.rei.network',
    blockTime: 10000,
    iconUrl: './REI.png',
    blockDelay: 8,
    symbol: 'REI',
    blockExplorerUrl: 'https://scan.rei.network',
    tokenSymbolList: ['WETH', 'USDC', 'USDT', 'DAI', 'WBTC', 'BUSD'],
    lqMintTokenSymbolBlackList: ['WETH', 'USDC', 'USDT', 'DAI', 'WBTC', 'BUSD']
  },

  // Syscoin: {
  //   name: "Syscoin",
  //   color: "#3099f2",
  //   chainId: 57,
  //   rpcUrl: "https://rpc.syscoin.org",
  //   blockTime: 5000,
  //   iconUrl: "./syscoin.png",
  //   blockDelay: 20,
  //   symbol: "SYS",
  //   blockExplorerUrl: "https://explorer.syscoin.org",
  //   tokenSymbolList: ["USDT", "USDC", "WETH", "WBTC", "BNB", "SYS", "DAI"],
  //   lqMintTokenSymbolBlackList: ["USDT", "USDC", "WETH", "WBTC", "BNB", "SYS", "DAI"],
  // },

  flowMainnet: {
    name: 'Flow',
    color: '#3099f2',
    chainId: 12340001,
    rpcUrl: '',
    blockTime: 15000,
    iconUrl: './FLOW.png',
    blockDelay: 4,
    symbol: 'FlowToken',
    blockExplorerUrl: 'https://flowscan.org',
    tokenSymbolList: ['AVAX', 'BNB', 'BUSD', 'DAI', 'FTM', 'MATIC', 'USDT', 'WBTC', 'WETH', 'RLY', 'cfUSDC'],
    lqMintTokenSymbolBlackList: ['AVAX', 'BNB', 'BUSD', 'DAI', 'FTM', 'MATIC', 'USDT', 'WBTC', 'WETH', 'RLY', 'cfUSDC']
  },

  CrabSmartChain: {
    name: 'Crab Smart Chain',
    color: '#3099f2',
    chainId: 44,
    rpcUrl: 'https://crab-rpc.darwinia.network',
    blockTime: 10000,
    iconUrl: './crab.png',
    blockDelay: 8,
    symbol: 'CRAB',
    blockExplorerUrl: 'https://subview.xyz',
    tokenSymbolList: ['USDC', 'USDT', 'RING'],
    lqMintTokenSymbolBlackList: ['USDC', 'USDT']
  },

  PlatON: {
    name: 'PlatON',
    color: '#3099f2',
    chainId: 210425,
    rpcUrl: 'https://openapi2.platon.network/rpc',
    blockTime: 10000,
    iconUrl: './platon.png',
    blockDelay: 8,
    symbol: 'LAT',
    blockExplorerUrl: 'https://scan.platon.network',
    tokenSymbolList: ['USDT', 'USDC', 'WETH', 'DAI', 'WBTC'],
    lqMintTokenSymbolBlackList: ['USDT', 'USDC', 'WETH', 'DAI', 'WBTC']
  },

  Evmos: {
    name: 'Evmos',
    color: '#3099f2',
    chainId: 9001,
    rpcUrl: 'https://eth.bd.evmos.org:8545',
    blockTime: 5000,
    iconUrl: './evmos.png',
    blockDelay: 20,
    symbol: 'EVMOS',
    blockExplorerUrl: 'https://evm.evmos.org',
    tokenSymbolList: ['USDT', 'USDC', 'DAI', 'WETH', 'WBTC', 'CELR', 'BNB', 'BUSD', 'AVAX', 'FTM', 'AURORA', 'FRAX', 'FXS'],
    lqMintTokenSymbolBlackList: ['USDT', 'USDC', 'DAI', 'WETH', 'WBTC', 'CELR', 'BNB', 'BUSD', 'AVAX', 'FTM', 'AURORA', 'FRAX', 'FXS']
  },

  Swimmer: {
    name: 'Swimmer Network',
    color: '#3099f2',
    chainId: 73772,
    rpcUrl: 'https://subnets.avax.network/swimmer/mainnet/rpc',
    blockTime: 10000,
    iconUrl: './swimmer.png',
    blockDelay: 8,
    symbol: 'TUS',
    blockExplorerUrl: 'https://explorer.swimmer.network',
    tokenSymbolList: ['CRA', 'CRAM', 'TUS'],
    lqMintTokenSymbolBlackList: ['CRA', 'CRAM', 'TUS']
  },

  SXNetwork: {
    name: 'SX Network',
    color: '#3099f2',
    chainId: 416,
    rpcUrl: 'https://rpc.sx.technology',
    blockTime: 10000,
    iconUrl: './sx.png',
    blockDelay: 8,
    symbol: 'SX',
    blockExplorerUrl: 'https://explorer.sx.technology',
    tokenSymbolList: [
      'USDC',
      'USDT',
      'WETH',
      'WBTC',
      'DAI',
      'MATIC',
      'UMA',
      'RAGE',
      'XBP',
      'IMX',
      'FLX',
      'CELR',
      'PLOT',
      'AX',
      'MAI',
      'QI',
      'GREEN',
      'BIFI',
      'LFI'
    ],
    lqMintTokenSymbolBlackList: [
      'USDC',
      'USDT',
      'WETH',
      'WBTC',
      'DAI',
      'MATIC',
      'UMA',
      'RAGE',
      'XBP',
      'IMX',
      'FLX',
      'CELR',
      'PLOT',
      'AX',
      'MAI',
      'QI',
      'GREEN',
      'BIFI',
      'LFI'
    ]
  },

  ApeChain: {
    name: 'Ape Chain',
    color: '#3099f2',
    chainId: 16350,
    rpcUrl: 'https://bas.metaapesgame.com/bas_mainnet_full_rpc',
    blockTime: 10000,
    iconUrl: './ape.png',
    blockDelay: 8,
    symbol: 'PEEL',
    blockExplorerUrl: 'https://explorer.bas.metaapesgame.com',
    tokenSymbolList: ['PEEL', 'SHELL'],
    lqMintTokenSymbolBlackList: ['PEEL', 'SHELL']
  },

  KavaEVMCoChain: {
    name: 'Kava EVM Co-Chain',
    color: '#3099f2',
    chainId: 2222,
    rpcUrl: 'https://evm.kava.io',
    blockTime: 10000,
    iconUrl: './kava.png',
    blockDelay: 8,
    symbol: 'KAVA',
    blockExplorerUrl: 'https://explorer.kava.io',
    tokenSymbolList: ['USDC', 'USDT', 'DAI', 'WETH', 'WBTC', 'USX'],
    lqMintTokenSymbolBlackList: ['USDC', 'USDT', 'DAI', 'WETH', 'WBTC']
  },
  Ontology: {
    name: 'Ontology',
    color: '#3099f2',
    chainId: 58,
    rpcUrl: 'https://dappnode10.ont.io:10339',
    blockTime: 10000,
    iconUrl: './ontology.svg',
    blockDelay: 8,
    symbol: 'ONG',
    blockExplorerUrl: 'https://explorer.ont.io',
    tokenSymbolList: ['ONG', 'USDC', 'USDT', 'WETH', 'WBTC', 'WING'],
    lqMintTokenSymbolBlackList: ['USDC', 'USDT', 'WETH', 'WBTC']
  },
  NervosGodwoken: {
    name: "Nervos Godwoken",
    chainId: 71402,
    rpcUrl: "https://v1.mainnet.godwoken.io/rpc",
    iconUrl: "./nervos.png",
    symbol: "pCKB",
    blockExplorerUrl: "https://gw-mainnet-explorer.nervosdao.community",
    tokenSymbolList: ["USDC", "USDT", "DAI", "WETH", "WBTC", "BUSD"],
    lqMintTokenSymbolBlackList: ["USDC", "USDT", "DAI", "WETH", "WBTC", "BUSD"],
  },

  Klaytn: {
    name: "Klaytn",
    chainId: 8217,
    rpcUrl: "https://public-node-api.klaytnapi.com/v1/cypress",
    iconUrl: "./klaytn.png",
    symbol: "KLAY",
    blockExplorerUrl: "https://www.klaytnfinder.io",
    tokenSymbolList: ["USDC", "USDT", "DAI", "WETH", "WBTC"],
    lqMintTokenSymbolBlackList: ["USDC", "USDT", "DAI", "WETH", "WBTC"],
  },
  AptosMainnet: {
    name: "Aptos Mainnet",
    chainId: 12360001,
    rpcUrl: "https://fullnode.mainnet.aptoslabs.com",
    iconUrl: "./aptos.png",
    symbol: "APTOS",
    blockExplorerUrl: "https://explorer.aptoslabs.com",
    tokenSymbolList: ["USDC", "USDT", "WETH", "WBTC", "DAI", "BUSD", "BNB"],
    lqMintTokenSymbolBlackList: ["USDC", "USDT", "WETH", "WBTC", "DAI", "BUSD", "BNB"],
  },
  SPSMainnet: {
    name: "SPS Mainnet",
    chainId: 13000,
    rpcUrl: "https://rpc.ssquad.games",
    iconUrl: "./SPS.png",
    symbol: "ECG",
    blockExplorerUrl: "https://spsscan.ssquad.games",
    tokenSymbolList: ["ECG", "SQUAD"],
    lqMintTokenSymbolBlackList: ["ECG", "SQUAD"],
  },
  OasysMainnet: {
    name: "Oasys Mainnet",
    chainId: 248,
    rpcUrl: "https://rpc.mainnet.oasys.games",
    iconUrl: "./oas.png",
    symbol: "OAS",
    blockExplorerUrl: "https://explorer.oasys.games/",
    tokenSymbolList: ["USDC", "USDT", "MCHC", "TCGC", "RAYS", "EPL"],
    lqMintTokenSymbolBlackList: ["USDC", "USDT", "RAYS", "EPL"],
  },
  ZKSyncMainnet: {
    name: "zkSync Era Mainnet",
    chainId: 324,
    rpcUrl: "https://zksync2-mainnet.zksync.io",
    iconUrl: "./ETH.png",
    symbol: "ETH",
    blockExplorerUrl: "https://explorer.zksync.io/",
    tokenSymbolList: ["BNB", "BUSD", "AVAX", "MATIC", "WETH", "USDC", "IBEX", "iUSD", "iZi", "MVX", "GGG", "DCA", "PEPE", "FLOKI", "GOVI", "OKSE"],
    lqMintTokenSymbolBlackList: ["BNB", "BUSD", "AVAX", "MATIC", "GGG", "DCA", "PEPE", "FLOKI", "OKSE"],
  },
  FilecoinMainnet: {
    name: "Filecoin Mainnet",
    chainId: 314,
    rpcUrl: "https://api.node.glif.io/rpc/v1",
    iconUrl: "./FEVM.png",
    symbol: "FIL",
    blockExplorerUrl: "https://filfox.info/en",
    tokenSymbolList: ["USDC", "WETH", "WBTC", "USDT", "WFIL", "TRB"],
    lqMintTokenSymbolBlackList: ["USDC", "WETH", "WBTC", "USDT", "WFIL", "TRB"],
  },
  ArbitrumNova: {
    name: "Arbitrum Nova",
    chainId: 42170,
    rpcUrl: "https://nova.arbitrum.io/rpc",
    iconUrl: "./arbitrumnova.png",
    symbol: "ETH",
    blockExplorerUrl: "https://nova-explorer.arbitrum.io",
    tokenSymbolList: ["ECG", "SQUAD"],
    lqMintTokenSymbolBlackList: ["ECG", "SQUAD"],
  },
  Canto: {
    name: "Canto",
    chainId: 7700,
    rpcUrl: "https://canto.slingshot.finance",
    iconUrl: "./canto.png",
    symbol: "CANTO",
    blockExplorerUrl: "https://evm.explorer.canto.io",
    tokenSymbolList: ["IBEX", "BNB", "BUSD", "WBTC", "CANTO"],
    lqMintTokenSymbolBlackList: ["BNB", "BUSD", "WBTC", "CANTO"],
  },
  PolygonzkEVM: {
    name: "Polygon zkEVM",
    chainId: 1101,
    rpcUrl: "https://zkevm-rpc.com",
    iconUrl: "./PolygonZkEVM.png",
    symbol: "ETH",
    blockExplorerUrl: "https://zkevm.polygonscan.com",
    tokenSymbolList: ["WETH"],
    lqMintTokenSymbolBlackList: [],
  },
  AntimatterB2: {
    name: "Antimatter B2",
    chainId: 1990,
    rpcUrl: "https://rpc.antimatter.finance",
    iconUrl: "./matter.png",
    symbol: "MATTER",
    blockExplorerUrl: "https://bastest-explorer.antimatter.finance/",
    tokenSymbolList: ["MATTER"],
    lqMintTokenSymbolBlackList: ["MATTER"],
  },
  injective: {
    name: "Injective mainnet",
    chainId: 999999997,
    rpcUrl: "https://k8s.mainnet.tm.injective.network",
    iconUrl: "./injective.png",
    symbol: "INJ",
    blockExplorerUrl: "https://explorer.injective.network/",
    tokenSymbolList: ["INJ", "ATOM", "ASTR", "DOT"],
    lqMintTokenSymbolBlackList: ["INJ", "ATOM", "ASTR", "DOT"],
  },
};
