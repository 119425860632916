import React, { useState, useEffect, useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { LinkOutlined } from '@ant-design/icons';
import { Spin, Typography } from 'antd';
import { AppContext } from '../App';
import SearchBar from '../components/common/SearchBar';
import AssetsTransfer from '../components/detail/AssetsTransfer';
import MessagePassing from '../components/detail/MessagePassing';
import { searchByTxHash } from '../redux/gateway';
import { getChainById } from '../utils/info';
import { InputInvalidCard, TxEmptyCard } from '../components/common/EmptyContent';
import { isValidAddress, isValidTxHash } from '../utils/validate';
import { getNetworkById } from '../constants/network';
import { transactionExplorerMediator } from '../utils/transactionExplorerUrlMediator';
import TableList from '../components/common/TableList';

const { Paragraph } = Typography;

const useStyles = createUseStyles<string, { isMobile: boolean }>(() => ({
  page: {
    backgroundColor: '#F3F3F3',
    padding: (props) => props.isMobile ? '0 16px' : '0 54px',
  },
  search: {
    marginTop: 72,
    marginBottom: 16,
  },
  detail: {
    width: '100%',
    borderRadius: (props) => props.isMobile ? '0 0 16px 16px' : 16,
    backgroundColor: '#fff',
    padding: (props) => props.isMobile ? 16 : '32px 36px'
  },
  title: {
    borderBottom: '1px solid #ECEBEE99',
    width: '100%',
    fontWeight: 600,
    fontSize: 20,
    paddingBottom: 24
  },
  mobileTitle: {
    fontSize: '0.16rem',
    padding: 16,
    backgroundColor: '#fff',
    height: 60,
    width: '100%',
    fontWeight: 600,
    borderRadius: '16px 16px 0 0',
    marginBottom: 2
  },
  content: {},
  overview: {},
  item: {
    width: '100%',
    borderBottom: (props) => props.isMobile ? 'none' : '1px solid #ECEBEE99',
    display: 'flex',
    height: (props) => props.isMobile ? 'auto' : 48,
    fontSize: 14,
    alignItems: (props) => props.isMobile ? 'baseline' : 'center',
    justifyContent: (props) => props.isMobile ? 'space-between' : 'flex-start',
    marginBottom: (props) => props.isMobile ? 8 : 0,
  },
  label: {
    width: (props) => props.isMobile ? 'auto' : 200,
    fontWeight: 700,
    fontSize: (props) => props.isMobile ? '0.12rem' : 14,
    marginRight: 8,
    whiteSpace: 'nowrap',
    color: '#555'
  },
  value: {
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    fontSize: (props) => props.isMobile ? '0.12rem' : 14,
    wordBreak: 'break-all',
    textAlign: 'end',
    '& .ant-typography': {
      marginBottom: 0,
      color:  (props) => props.isMobile ? '#1874FF' : '#000',
    },
  },
  srcIncon: {
    width: 24,
    height: 24,
    marginRight: 4
  },
  linkIcon: {
    fontSize: 14,
    marginLeft: 6,
    color: '#1874FF'
  },
  copyIcon: {
    fontSize: 14,
    marginLeft: 6,
    color: '#1874FF'
  },
  discribe: {
    marginBottom: 16,
    fontSize: 16,
    fontWeight: 700
  },
  hash: {
    color: '#2C98F3'
  }
}));

function TxDetail(props): JSX.Element {
  const { state, isMobile } = useContext(AppContext);
  const classes = useStyles({ isMobile });
  const navigate = useNavigate();
  const params = useParams();
  const { hash = '' } = params;
  const [input, setInput] = useState<string>(hash);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [showInvalidPage, setShowInvalidPage] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [detailInfo, setDetailInfo] = useState<any>(null);
  const [detailList, setDetailList] = useState<any>([]);


  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  // useEffect(() => {
  //   setShowInvalidPage(!isValidTxHash(hash) || !isValidAddress(hash));
  // }, [hash]);

  const handleInputSearch = (e) => {
    if (isValidAddress(input)) {
      navigate(`/account/${input}`);
    } else if (isValidTxHash(input)) {
      navigate(`/tx/${input}`);
      fetchDetailInfo();
    } else {
      setShowInvalidPage(true);
    }
  };

  useEffect(() => {
    fetchDetailInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDetailInfo = async () => {
    if(!isValidTxHash(hash)) return;
    setLoading(true);
    setDetailInfo(null);
    setDetailList([]);
    setIsEmpty(false);
    setShowInvalidPage(false);
    const res = await searchByTxHash(input);
    if (!res.getErr()) {
      const { txsearchinfoList } = res.toObject();
      if(!!txsearchinfoList?.length) {
        if(txsearchinfoList?.length === 1) {
          setDetailInfo(txsearchinfoList[0]); 
        } else {
          setDetailList(txsearchinfoList);
        }
      } else {
        setIsEmpty(true);
      }
      setLoading(false);
    } else {
      console.log('get transfer detal error:', res.getErr());
    }
  };

  const handleListDetails = (data) => {
    setDetailInfo(data); 
    setDetailList([]);
  }

  

  const getContent = () => {
    if (showInvalidPage) return <InputInvalidCard />;
    if (isEmpty) return <TxEmptyCard />;
    if (loading) return <Spin spinning={loading}><div style={{ height: '400px' }}/></Spin>
    if(detailInfo) {
      const { baseInfo, messageList, transferList } = detailInfo || {};
      const { initTime, lastUpdateTime, sender, srcChainId, srcTxHash } = baseInfo || {};
      const srcChain = getChainById(srcChainId, state?.transferConfig?.chains);
      return (
        <>
          {isMobile && <div className={classes.mobileTitle}>Cross-Chain Transaction Details</div>}
          <div className={classes.detail}>
            {!isMobile && <div className={classes.title}>Cross-Chain Transaction Details</div>}
            <div className={classes.content}>
              <div className={classes.overview}>
                <div className={classes.item}>
                  <div className={classes.label}>Sender Address:</div>
                  <div className={classes.value}>{sender}</div>
                </div>
                <div className={classes.item} style={{ alignItems: 'center' }}>
                  <div className={classes.label}>Source Chain:</div>
                  <div className={classes.value}>
                    {srcChain?.icon && <img className={classes.srcIncon} src={srcChain.icon} alt="srcIncon" />}
                    {srcChain?.name}
                  </div>
                </div>
                <div className={classes.item}>
                  <div className={classes.label}>Source tx hash:</div>
                  {srcTxHash && (
                    <div className={classes.value}>
                      {isMobile ? <a 
                        href={
                          getNetworkById(Number(srcChain?.id)).blockExplorerUrl + transactionExplorerMediator(srcChain?.id ?? 0) + srcTxHash
                        }
                        target="_blank"
                        rel="noreferrer">
                        <Paragraph copyable>{srcTxHash}</Paragraph>
                      </a> : <Paragraph copyable>{srcTxHash}</Paragraph>}
                      {!isMobile && <a
                        href={
                          getNetworkById(Number(srcChain?.id)).blockExplorerUrl + transactionExplorerMediator(srcChain?.id ?? 0) + srcTxHash
                        }
                        target="_blank"
                        rel="noreferrer">
                        <LinkOutlined className={classes.linkIcon} />
                      </a>}
                    </div>
                  )}
                </div>
                <div className={classes.item}>
                  <div className={classes.label}>Initiated Time:</div>
                  <div className={classes.value}>{moment(initTime).format('MMM-DD-YYYY hh:mm:ss A')} +UTC</div>
                </div>
                <div className={classes.item}>
                  <div className={classes.label}>Last Update Time:</div>
                  <div className={classes.value}>{moment(lastUpdateTime).format('MMM-DD-YYYY hh:mm:ss A')} +UTC</div>
                </div>
              </div>
              {!!transferList?.length && <AssetsTransfer data={transferList} srcChain={srcChain} />}
              {!!messageList?.length && <MessagePassing data={messageList} />}
            </div>
          </div>
        </>
      );
    }
    if(!!detailList?.length) {
      const tableDatas = detailList?.map(detail => {
        const { baseInfo } = detail || {};
        return {
          value: detail,
          sourceChainId: baseInfo.srcChainId,
          senderAddress: baseInfo.sender,
          sourceTransactionHash: baseInfo.srcTxHash,
          initialTime: baseInfo.initTime,
        }
      })
      return (
      <>
        <div className={classes.discribe}>
            Below is the list of cross-chain transactions associated with: <span className={classes.hash}>{hash}</span>
        </div>
        <TableList data={tableDatas} showPagination={false} isTxList={true} handleListDetails={handleListDetails} />
      </>)
    }
  };
  return (
    <div className={classes.page}>
      <SearchBar className={classes.search} value={input} onInputChange={handleInputChange} onInputSearch={handleInputSearch} />
      {/* {isMobile && <div className={classes.mobileTitle}>Cross-Chain Transaction Details</div>} */}
      {getContent()}
    </div>
  );
}

export default TxDetail;
