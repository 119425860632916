import * as Types from './types';

export const setTransferConfig = (config) => ({
  type: Types.TRANSFER_CONFIG,
  payload: config
});

export const setSupportedChains = (count: number) => ({
  type: Types.TRANSFER_SUPPORTED_CHAINS_COUNT,
  payload: count
})
