import React, { useContext } from 'react';
import cl from "classnames";
import { Table, Button, Pagination, PaginationProps } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../App';
import { openTransactionOnExplorer, prepareSenderAddressAbbreviation, prepareTransactionHashAbbreviation } from '../../utils/convert';
import AnimateTimer from './AnimateTimer';
import placeChain from '../../imgs/placeChain.png';
interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

const useStyles = createUseStyles(() => ({
  assets: {
    color: '#00D395'
  },
  btn: {
    fontSize: 14,
    fontWeight: 700,
    width: 96,
    height: 40,
    borderRadius: 16,
    backgroundColor: 'rgba(77, 124, 255, 0.1)',
    color: '#1874FF',
    border: 'none',
    textShadow: 'none',
    boxShadow: '0 2px 3px rgb(0 0 0 / 6%)',
    '&:hover': {
      backgroundColor: '#1874FF',
      color: '#fff'
    },
    
  },
  chainIcon: {
    borderRadius: '50%',
    marginBottom: 4
  },
  chainName: {
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 4
  },
  senderAddress: {
    fontSize: 14,
    fontWeight: 500
  },
  pagination: {
    marginTop: 10,
    '@global': {
      '.ant-pagination-item': {
        height: '32px !important',
        minWidth: '32px !important',
        lineHeight: '32px !important'
      },
      '.ant-pagination-item-active': {
        border: 'none',
        backgroundColor: '#ECEBEE',
        borderRadius: 8
      },
      '.ant-pagination-item-active a': {
        color: '#000'
      }
    }
  },
  total: {
    fontSize: 14,
    fontWeight: 600,
    color: '#777'
  },
  table: {
    padding: '24px 36px',
    background: '#fff',
    borderRadius: '16px',
    textAlign: 'center',
    '@global': {
      '.ant-table-thead > tr > th': {
        backgroundColor: '#fff',
        borderBottom: 'none',
        color: '#777777',
        fontSize: 12,
        fontWeight: 600,
        paddingTop: 0
      },
      '.ant-table-thead > tr > th::before': {
        display: 'none'
      },
      '.ant-table-tbody > tr > td': {
        borderBottom: 'none'
      }
    }
  },
  lastestCard: {
    '&:first-child': {
      borderRadius: '0 0 20px 20px'
    }
  },
  searchCard: {
    borderRadius: 0,
    marginBottom: 2,
  },
}));

function TableList(props): JSX.Element {
  const { state } = useContext(AppContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const { data, page, total, onListPageChange, showPagination, pagination, isTxList = false, handleListDetails } = props;

  const prepareChainInfo = (chainId: number, isSrc) => {
    let chain = state?.transferConfig?.chains?.find((chainInfo) => {
      return chainInfo.id === chainId;
    });
    return (
      <div>
        <img className={classes.chainIcon} src={chain?.icon || placeChain} alt='chainIcon' style={{ width: isSrc ? '24px' : '20px', height: isSrc ? '24px' : '20px'}}/>
        <span className={classes.chainName}>{chain?.name}</span> 
      </div>
    );
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'Source Chain',
      dataIndex: 'sourceChainId',
      key: 'sourceChainId',
      render: (chainId) => {
        return prepareChainInfo(chainId, true);
      }
    },
    {
      title: 'Sender Address',
      dataIndex: 'senderAddress',
      key: 'senderAddress',
      render: (address) => {
        return <span className={classes.senderAddress}>{prepareSenderAddressAbbreviation(address)}</span>;
      }
    },
    {
      title: 'Source TX Hash',
      key: 'sourceTransactionHash',
      render: (preview) => {
        return (
          <a className={classes.senderAddress} href={openTransactionOnExplorer(state?.transferConfig?.chains, preview.sourceChainId, preview.sourceTransactionHash)}>
            {prepareTransactionHashAbbreviation(preview.sourceTransactionHash)}
          </a>
        );
      }
    },
    {
      title: 'Initiate Time',
      dataIndex: 'initialTime',
      key: 'initialTime',
      render: (time) => {
        return <AnimateTimer time={time} />
      }
    },
  ];

  const commonExtraColumns  = [
    {
      title: 'Destination chain',
      dataIndex: 'destinationChainIds',
      key: 'destinationChainIds',
      render: (chainIds) => {
        return chainIds?.map((chainId) => {
          return prepareChainInfo(chainId, false);
        });
      }
    },
    {
      title: 'Assets Transfer',
      dataIndex: 'assetsTransfer',
      key: 'assetsTransfer',
      render: (chains) => {
        return chains?.map((el, idx) => (
          <div key={idx} className={classes.assets}>
            {el}
          </div>
        ));
      }
    },
    {
      title: '',
      key: 'action',
      render: (preview) => {
        return (
          <Button type="primary" className={classes.btn} onClick={() => navigate('/tx/' + preview.sourceTransactionHash)}>
            Details
          </Button>
        );
      }
    }
  ]

  const txExtraColumns = [
    {
      title: '',
      key: 'action',
      render: (preview) => {
        return (
          <Button type="primary" className={classes.btn} onClick={() => handleListDetails(preview.value)}>
            Details
          </Button>
        );
      }
    }
  ]

  const showTotal: PaginationProps['showTotal'] = (total) => (
    <div className={classes.total}>
      Total <span style={{ color: '#0C82FF' }}>{total}</span> items
    </div>
  );

  return (
    <div className={cl(props.cls, classes.table)}>
      <Table columns={isTxList ? columns.concat(txExtraColumns) : columns.concat(commonExtraColumns)} dataSource={data} pagination={false} />
      {showPagination && (
        <Pagination
          className={classes.pagination}
          size="small"
          current={page}
          total={total}
          showTotal={showTotal}
          showSizeChanger={false}
          onChange={onListPageChange}
          {...pagination}
        />
      )}
    </div>
  );
}

export default TableList;

TableList.defaultProps = {
  data: [],
  page: 1,
  isTxList: false,
};
