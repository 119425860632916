/**
 * @param {*} thisColor #555 rgba(85,85,85,0.6) rgb(85,85,85)
 * @param {*} thisOpacity 0.7
 * @returns rgba(85,85,85,0.7)
 */
export function getOpacityColor(thisColor, thisOpacity) {
  var theColor = thisColor.toString().toLowerCase();
  var r = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;

  if (theColor && r.test(theColor)) {
    if (theColor.length === 4) {
      var sColorNew = '#';
      for (var i = 1; i < 4; i += 1) {
        sColorNew += theColor.slice(i, i + 1).concat(theColor.slice(i, i + 1));
      }
      theColor = sColorNew;
    }

    var sColorChange: any = [];
    for (var j = 1; j < 7; j += 2) {
      sColorChange.push(parseInt('0x' + theColor.slice(j, j + 2)));
    }
    return 'rgba(' + sColorChange.join(',') + ',' + thisOpacity + ')';
  }

  if (theColor.startsWith('rgb')) {
    let numbers = theColor.match(/(\d(\.\d+)?)+/g);
    numbers = numbers?.slice(0, 3).concat(thisOpacity);
    return 'rgba(' + numbers?.join(',') + ')';
  }

  return theColor;
}
