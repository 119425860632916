import React, { useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { InfoCircleOutlined, LinkOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import { BigNumber } from '@ethersproject/bignumber';
import { formatDecimal } from 'number-format-utils/lib/format';
import { AppContext } from '../../App';
import { getChainById } from '../../utils/info';
import { MsgStatus } from '../../proto/web/web_pb';
import { getNetworkById } from '../../constants/network';
import { transactionExplorerMediator } from '../../utils/transactionExplorerUrlMediator';

const { Paragraph } = Typography;

const useStyles = createUseStyles<string, { isMobile: boolean }>(() => ({
  wrap: {
    fontWeight: 600
  },
  title: {
    fontSize: (props) => props.isMobile ? '0.16rem' : 16,
    fontWeight: 700,
    marginTop: 36,
    marginBottom: 15
  },
  content: {
    borderRadius: 16,
    border: '1px solid #ECEBEE99',
    padding: (props) => props.isMobile ? '12px 8px' : 16
  },
  card: {
    marginTop: 32,
    '&:first-child': {
      marginTop: 0
    }
  },
  cardTitle: {
    color: '#1874FF',
    fontSize: (props) => props.isMobile ? '0.16rem' : 16,
    fontWeight: 700,
    marginBottom: 8
  },
  item: {
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    marginBottom: 8,
    fontSize: (props) => props.isMobile ? '0.12rem' : 14,
    justifyContent: (props) => props.isMobile ? 'space-between' : 'flex-start',
  },
  label: {
    width: (props) => props.isMobile ? 'auto' : 200,
    color: '#555',
    whiteSpace: 'nowrap',
    marginRight: 8
  },
  value: {
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-all',
    textAlign: 'end',
    '& .ant-typography': {
      marginBottom: 0,
      color:  (props) => props.isMobile ? '#1874FF' : '#000',
    },
  },
  payload: {
    flex: 1,
    width: '100%',
    border: '1px solid #ECEBEE99',
    borderRadius: 8,
    padding: 8,
    fontSize: 12,
    fontWeight: 700,
    color: '#777',
    wordBreak: 'break-all'
  },
  waring: {
    display: 'flex',
    alignItems: 'center'
  },
  tooltip: {
    '& .ant-tooltip-inner': {
      color: '#000',
      fontSize: 12,
      backgroundColor: '#fff'
    },
    '& .ant-tooltip-arrow-content': {
      color: '#000',
      fontSize: 12,
      '--antd-arrow-background-color': '#fff'
    }
  },
  chainIcon: {
    width: 24,
    height: 24,
    marginRight: 4
  },
  linkIcon: {
    fontSize: 14,
    marginLeft: 6,
    color: '#1874FF'
  },
  copyIcon: {
    fontSize: 14,
    marginLeft: 6,
    color: '#1874FF'
  }
}));

function MessagePassing(props): JSX.Element {
  const { isMobile } = useContext(AppContext);
  const classes = useStyles({ isMobile });
  const { data } = props;
  return (
    <div className={classes.wrap}>
      <div className={classes.title}>Message passing </div>
      <div className={classes.content}>
        {data.map((el, idx) => (
          <MessageCard key={el.xferId} data={el} number={idx} />
        ))}
      </div>
    </div>
  );
}

export default MessagePassing;

const MessageCard = (props): JSX.Element => {
  const { state, isMobile } = useContext(AppContext);
  const { data, number } = props;
  const { msgStatus, dstChainId, payload, executionTx, msgFeeGas, msgFeeVolume } = data;
  const classes = useStyles({ isMobile });
  const getStatus = (status) => {
    let lab;
    switch (status) {
      case MsgStatus.MS_WAITING_FOR_SGN_CONFIRMATIONS:
        lab = (
          <div className={classes.waring}>
            <span style={{ color: '#FF7D00' }}>Waiting for SGN confirmations</span>{' '}
            <Tooltip
              overlayClassName={classes.tooltip}
              title={<span>The cross-chain message passing is being processed in Celer SGN, which might take a few minutes.</span>}>
              <InfoCircleOutlined style={{ fontSize: 13, marginLeft: 6, color: '#C4C4C4' }} />
            </Tooltip>
          </div>
        );
        break;
      case MsgStatus.MS_WAITING_FOR_DESTINATION_EXECUTION:
        lab = (
          <div className={classes.waring}>
            <span style={{ color: '#FF7D00' }}>Waiting for destination execution</span>{' '}
            <Tooltip
              overlayClassName={classes.tooltip}
              title={<span>The msg is waiting to be executed on the destination chain, which usually takes a few minutes.</span>}>
              <InfoCircleOutlined style={{ fontSize: 13, marginLeft: 6, color: '#C4C4C4' }} />
            </Tooltip>
          </div>
        );
        break;
      case MsgStatus.MS_COMPLETED:
        lab = (
          <div className={classes.waring}>
            <span style={{ color: '#2ED57B' }}>Completed</span>
          </div>
        );
        break;
      default:
        break;
    }
    return lab;
  };
  const destinationChain = getChainById(dstChainId, state.transferConfig.chains);
  const gasBigNum = BigNumber.from(msgFeeGas || '0');
  const tgas = Number(formatDecimal(gasBigNum, 6, 18).split(',').join(''));
  return (
    <div className={classes.card}>
      <div className={classes.cardTitle}>Msg #{number + 1}</div>
      <div className={classes.item}>
        <div className={classes.label}>Status:</div>
        <div className={classes.value}>{getStatus(msgStatus)}</div>
      </div>
      <div className={classes.item}>
        <div className={classes.label}>Destination chain:</div>
        <div className={classes.value}>
          <img className={classes.chainIcon} src={destinationChain?.icon} alt="" />
          {destinationChain?.name}
        </div>
      </div>
      <div className={classes.item}>
        <div className={classes.label}>Destination tx hash:</div>
        <div className={classes.value}>
          {executionTx ?
          <>
           {isMobile ? 
             <a href={
               getNetworkById(Number(destinationChain?.id)).blockExplorerUrl +
               transactionExplorerMediator(destinationChain?.id ?? 0) +
               executionTx
             }
              target="_blank"
              rel="noreferrer">
              <Paragraph copyable>{executionTx}</Paragraph>
             </a> :  <Paragraph copyable>{executionTx}</Paragraph>}
            {!isMobile && <a
              href={
                getNetworkById(Number(destinationChain?.id)).blockExplorerUrl +
                transactionExplorerMediator(destinationChain?.id ?? 0) +
                executionTx
              }
              target="_blank"
              rel="noreferrer">
              <LinkOutlined className={classes.linkIcon} />
            </a>}
          </> : '--'}
        </div>
      </div>
      <div className={classes.item}>
        <div className={classes.label}>Msg Passing Fee:</div>
        <div className={classes.value}>
          {' '}
          {tgas} {destinationChain?.gas_token_symbol} (${`${msgFeeVolume.toFixed(2)}`})
        </div>
      </div>
      {payload && !isMobile && <div className={classes.item}>
        <div className={classes.label} style={{ width: '200px' }}>Payload:</div>
        <div className={classes.payload}> {payload}</div>
      </div>}

      {payload && isMobile && <div className={classes.item}>
        <div className={classes.payload}> 
          <div style={{ width: '100%', fontSize: '0.14rem', color: '#555' }}>Payload:</div> {payload}
        </div>
      </div>}
    </div>
  );
};
