import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import TableList from '../components/common/TableList';
import SearchBar from '../components/common/SearchBar';
import { AddressEmptyCard, InputInvalidCard } from '../components/common/EmptyContent';
import { isValidAddress, isValidTxHash } from '../utils/validate';
import { searchByAddress } from '../redux/gateway';
import { TransferPreview } from '../constants/interfaces';
import { AppContext } from '../App';
import { getTokenByChainIdAndAddress } from '../utils/info';
import { BigNumber } from 'ethers';
import { formatDecimal } from 'number-format-utils/lib/format';
import { SearchByAddrResponse } from '../proto/web/web_pb';
import ScrollList from '../components/common/ScrollList';
import { Spin } from 'antd';

const useStyles = createUseStyles<string, { isMobile: boolean }>(() => ({
  page: {
    padding: (props) => props.isMobile ? '0 16px' : '0 54px',
    backgroundColor: '#F3F3F3'
  },
  list: {
    minHeight: '100vh',
    width: '100%'
  },
  search: {
    marginTop: 72,
    marginBottom: 16,
    // '@global': {
    //   '.ant-input': {
    //     backgroundColor: '#f5f5f5'
    //   }
    // }
  },
  discribe: {
    marginBottom: 16,
    fontSize: (props) => props.isMobile ? '0.12rem' : 16,
    fontWeight: 700
  },
  address: {
    color: '#2C98F3'
  }
}));

function AddressSearchList(props): JSX.Element {
  const { isMobile, state } = useContext(AppContext);
  const classes = useStyles({ isMobile });
  const navigate = useNavigate();
  const params = useParams();
  const { address = '' } = params;
  const [page, setPage] = useState<number>(1);
  const [scrollPage, setScrollPage] = useState<number>(1);
  const [prePage, setPrePage] = useState<number>(0);
  const [inputAddr, setInputAddr] = useState<string>(address);
  const [transferPreviews, setTransferPreviews] = useState<TransferPreview[]>([]);
  const [backendPageNumber, setBackendPageNumber] = useState<number>(0);
  const [backendAccountAddress, setBackendAccountAddress] = useState('');
  const [searchByAddrResponse, setSearchByAddrResponse] = useState<SearchByAddrResponse | undefined>(undefined);
  const [showNoResultPage, setShowNoResultPage] = useState(false);
  const [showInvalidPage, setShowInvalidPage] = useState(false);
  const [totalNumber, setTotalNumber] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const { transferConfig } = state;

  const [hasMore, setHasMore] = useState<boolean>(false);

  const handleLoadMore = () => {
    setPrePage(scrollPage);
    setScrollPage(scrollPage + 1);
  }

  const handlePageChange = (page) => {
    setPrePage(page - 1);
    setPage(page);
  };

  const handleAddressChange = (e) => {
    setInputAddr(e.target.value);
  };

  const handleInputSearch = (e) => {
    if (isValidAddress(inputAddr)) {
      navigate(`/account/${inputAddr}`);
    } else if (isValidTxHash(inputAddr)) {
      navigate(`/tx/${inputAddr}`);
    } else {
      navigate(`/account/${inputAddr}`);
    }
  };

  useEffect(() => {
    setShowInvalidPage(!isValidAddress(address));
  }, [address]);

  useEffect(() => {
    if (searchByAddrResponse) {
      setShowNoResultPage(searchByAddrResponse.getTotalNum() === 0);
    }
  }, [searchByAddrResponse]);

  // useEffect(() => {

  // }, [scrollPage, inputAddr, searchByAddrResponse, backendPageNumber, backendAccountAddress, transferConfig])

  useEffect(() => {
    /// From FrontEnd side, one page will display 10 items and start index is 1
    /// From BackEnd side, one page will have 20 items
    /// Hence, to display x page in website, we should get (x - 1) / 2 data from backend
    /// Moreover, the backend list starts with page num 1. Hence add 1 at last
    const currentPage = isMobile ? scrollPage : page;
    const targetBackendPage = Math.floor((currentPage - 1) / 2) + 1;

    if (
      targetBackendPage === backendPageNumber &&
      inputAddr.toLowerCase() === backendAccountAddress.toLowerCase() &&
      searchByAddrResponse
    ) {
      const transferPreviewsMapping = searchByAddrResponse.getTransferPreviewList().map((preview) => {
        let result: TransferPreview;
        result = {
          key: 'chain ' + preview.getSrcChainId() + ':' + preview.getSrcTxHash(),
          sourceChainId: preview.getSrcChainId(),
          senderAddress: preview.getSender(),
          destinationChainIds: preview.getDstChainIdList(),
          sourceTransactionHash: preview.getSrcTxHash(),
          initialTime: preview.getTs() + '',
          assetsTransfer: preview.getTransferInfoList().map(transferInfo => {
            return prepareAssetTransfer(transferInfo.getSendAmt(), preview.getSrcChainId(), transferInfo.getSrcTokenAddr())
          })
        };
        return result;
      });
      if(prePage === currentPage) return;
      if (currentPage % 2 === 0) {
        isMobile ? setTransferPreviews(transferPreviews.concat(transferPreviewsMapping.slice(10, 20))) : setTransferPreviews(transferPreviewsMapping.slice(10, 20));
      } else {
        if(isMobile && (transferPreviews.length !== (currentPage - 1) * 10)) return;
        isMobile ? setTransferPreviews(transferPreviews.concat(transferPreviewsMapping.slice(0, 10))) : setTransferPreviews(transferPreviewsMapping.slice(0, 10));
      }
      return;
    }

    requestDataFromBackend(inputAddr, targetBackendPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, scrollPage, inputAddr, searchByAddrResponse, backendPageNumber, backendAccountAddress, transferConfig]);

  useEffect(() => {
    setHasMore(transferPreviews.length < totalNumber)
  }, [transferPreviews, totalNumber])

  const prepareAssetTransfer = (amount: string, chainId: number, tokenAddress: string) => {
    if (amount.length === 0) {
      return '';
    }
    const token = getTokenByChainIdAndAddress(chainId, tokenAddress, transferConfig.chain_token);
    const srcBigNum = BigNumber.from(amount || '0');
    const srcAmount = Number(formatDecimal(srcBigNum, 6, token?.decimals).split(',').join(''));
    return srcAmount + ' ' + token?.symbol ?? "";
  };
  const requestDataFromBackend = (accountAddress: string, targetBackendPage: number) => {
      if (!isValidAddress(inputAddr)) {
        return;
      }
      setLoading(true);
      searchByAddress(accountAddress, 0, targetBackendPage).then((response) => {
        setTotalNumber(response.getTotalNum());
        setBackendPageNumber(targetBackendPage);
        setBackendAccountAddress(accountAddress);
        setSearchByAddrResponse(response);
        setLoading(false);
      });
  };

  return (
    <div className={classes.page}>
      <SearchBar className={classes.search} value={inputAddr} onInputChange={handleAddressChange} onInputSearch={handleInputSearch} />
      <div className={classes.list}>
        <div className={classes.discribe}>
          Below is the list of cross-chain transactions associated with: <span className={classes.address}>{address}</span>
        </div>
        {showInvalidPage && <InputInvalidCard />}
        {showNoResultPage && !showInvalidPage && <AddressEmptyCard />}
        {!showInvalidPage && !showNoResultPage && (
          <Spin spinning={loading}>
            {!isMobile && <TableList data={transferPreviews} total={totalNumber} page={page} onListPageChange={handlePageChange} showPagination={true} />}
            {isMobile && <ScrollList  data={transferPreviews} total={totalNumber} loadMore={true} hasMore={hasMore} handleLoadMore={handleLoadMore} />}
          </Spin>
        )}
      </div>
    </div>
  );
}

export default AddressSearchList;
