import * as jspb from 'google-protobuf';
import React, { useContext, useEffect, useState } from 'react';
import { ReactP5Wrapper } from 'react-p5-wrapper';
import { Spin } from 'antd';
import { createUseStyles } from 'react-jss';
import { sketch } from './sketch';
import DailyChart from './DailyChart';
import { getIMStats } from '../../redux/gateway';
import moment from 'moment';
import { DailyTransactionCount } from '../../constants/interfaces';
import { AppContext } from '../../App';
import { storageConstants } from '../../constants/const';
import MobileChainsImg from '../../imgs/chains/mobileChains.png'

const useStyles = createUseStyles<string, { isMobile: boolean }>(() => ({
  card: {
    background: '#fff',
    borderRadius: 20
  },
  title: {
    height: (props) => props.isMobile ? 58 : 80,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #ECEBEE',
    width: '100%',
    paddingLeft: (props) => props.isMobile ? 16 : 36,
    fontWeight: 600,
    fontSize: (props) => props.isMobile ? '0.18rem' : 20
  },
  content: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    background: '#fff',
    borderRadius: '0 0 16px 16px',
    padding: (props) => props.isMobile ? '16px' : '24px 36px 32px 36px',
  },
  left: {
    width: (props) => props.isMobile ? '100%' : '49%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    minHeight: (props) => props.isMobile ? 266 : 440,
    marginBottom: (props) => props.isMobile ? 12 : 0,

  },
  right: {
    width: '49%',
    height: (props) => props.isMobile ? 'auto' : 440,
    padding: (props) => props.isMobile ? '0 0 12px' : '0 47px',
    borderRadius: 16,
    border: '1px solid #ECEBEE',
    overflowX: 'auto',
    overflowY: 'hidden',
    paddingTop: 24,
    paddingBottom: 24,
    position: 'relative',
    textAlign: 'center',
    '@media (max-width: 960px)': {
      width: '100%'
    }
  },
  chainTitle: {
    color: '#000',
    fontWeight: 700,
    fontSize: 16,
    position: 'absolute',
    left: 24,
    top: 24
  },
  chainNum: {
    color: '#000',
    fontWeight: 700,
    fontSize: 16,
    position: 'absolute',
    left: 24,
    top: 48
  },
  transactionsCard: {
    width: (props) => props.isMobile ? '100%' : '49%',
    borderRadius: 16,
    border: (props) => props.isMobile ? 'none' : '1px solid #ECEBEE',
    padding: (props) => props.isMobile ? '20px 16px' : '21px 24px',
    height: 114
  },
  transactionstitle: {
    color: '#777',
    fontWeight: '600',
    fontSize: (props) => props.isMobile ? '0.14rem' : 14,
    marginBottom: 4
  },
  transactionsNum: {
    color: '##000000',
    fontWeight: 600,
    fontSize: (props) => props.isMobile ? '0.2rem' : 26
  },
  dailyCount: {
    height: 314,
    width: '100%',
    borderRadius: 16,
    border: '1px solid #ECEBEE',
    marginTop: 12,
  },
  img: {
    width: '100%',
    height: '100%',
    marginTop: 45,
  }
}));

function OverAll(props): JSX.Element {
  const { clientWidth, isMobile, state } = useContext(AppContext);
  const classes = useStyles({ isMobile });
  const { count } = state;
  const [totalTransactionsCount, setTotalTransactionsCount] = useState(0);
  const [last24HoursTransactionsCount, setLast24HoursTransactionsCount] = useState(0);
  const [totalDailyChart, setTotalDailyChart] = useState<DailyTransactionCount[]>([]);
  const [dailyChartAxis, setDailyChartAxis] = useState<string[]>([]);

  useEffect(() => {
    getIMStats().then((stats) => {
      setTotalTransactionsCount(stats.getTotalTx());
      setLast24HoursTransactionsCount(stats.getLatest24Tx());
      prepareDailyTransactionsCountMap(stats.getDailyTxMap());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prepareDailyTransactionsCountMap = (map: jspb.Map<string, number>) => {
    let dailyTransactionCountList: DailyTransactionCount[] = [];

    map.forEach((value: number, key: string) => {
      dailyTransactionCountList.push({ time: moment(key).format('MMM DD, YYYY'), count: value });
    });

    dailyTransactionCountList = dailyTransactionCountList.sort((chain1Count, chain2Count) => {
      return chain1Count.time > chain2Count.time ? 0 : 1;
    });

    setTotalDailyChart(dailyTransactionCountList);
    setDailyChartAxis(
      dailyTransactionCountList.map((dailyTransactionCount) => {
        return dailyTransactionCount.time;
      })
    );
  };

  const innerConfigsStr = localStorage.getItem(storageConstants.SKETCH_INNER_CONFIGS) || '';
  const outerConfigsStr = localStorage.getItem(storageConstants.SKETCH_OUTER_CONFIGS) || '';
  const spinning = (!innerConfigsStr || !outerConfigsStr);
  return (
    <div className={classes.card}>
      <div className={classes.title}>Overall IM Stats</div>
      <div className={classes.content}>
        
        {<div className={classes.left}>
          <div className={classes.transactionsCard}>
            <div className={classes.transactionstitle}>Total Transactions</div>
            <div className={classes.transactionsNum}>{totalTransactionsCount}</div>
          </div>
          <div className={classes.transactionsCard}>
            <div className={classes.transactionstitle}>Last 24 Hour Transactions</div>
            <div className={classes.transactionsNum}>{last24HoursTransactionsCount}</div>
          </div>
          <div className={classes.dailyCount}>
            <DailyChart dailyChartAxis={dailyChartAxis} dailyChartCount={totalDailyChart} width={clientWidth} isMobile={isMobile}/>
          </div>
        </div>}
        <div className={classes.right}>
          <div style={{ marginTop: 0 }}>
            {isMobile ? <img className={classes.img} src={MobileChainsImg} alt='mobileChains' /> : <Spin spinning={spinning}>
              {spinning ? <div style={{ height: '530px', width: '530px' }} /> : <ReactP5Wrapper sketch={sketch} />}
            </Spin>}
          </div>
          <div className={classes.chainTitle}>Supported Chains</div>
          <div className={classes.chainNum}>{count}</div>
        </div>
      </div>
    </div>
  );
}

export default OverAll;
