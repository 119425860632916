// let centerR = 65;
export const innerR = 15;
export const outerR = 12;
export const innerCicleR = 118;
export const outerCicleR = 187 - outerR;
const innerAngle = 20;
const outerAngle = 5;

export interface IconConfig {
  id: number;
  agl: number;
  rad: number;
  R: number;
  source: string;
  coordinate: Array<number>;
  placeR: number;
  angle: number;
  isInner: boolean;
  larger: boolean;
  icon: any;
  maskImage: any;
  bezierData: any;
  txs: number;
  startColor: Array<string>;
  endColor: Array<string>;
  bubbleColor: Array<string>;
  colorIndex: number;
  symbol: string;
  bubbleNum1: number,
  bubbleNum2: number,
  bubbles: any,
  bubbleT: number,
  bubbleT2: number,
  t: number,
}

const colorSet = ['#FF5E5E', '#FFA15E', '#AEFF5E', '#5EFF94', '#46AAFF', '#796DFF', '#D273FF'];
const colorIdx = () => Math.floor(Math.random() * colorSet.length);

export let sketchInnerConfigs: Array<IconConfig>;
export let sketchOutterConfigs: Array<IconConfig>;

export const setSketchConfig = (chains, isInner): Array<IconConfig> => {
  return chains.map((ch, idx) => {
    const colorIndex = colorIdx();
    const color = colorSet[colorIndex];
    return {
      id: ch.id,
      agl: 0,
      rad: 0,
      R: isInner ? innerR : outerR,
      source: ch.icon,
      coordinate: [0, 0],
      placeR: isInner ? innerCicleR : outerCicleR,
      angle: isInner ? innerAngle : outerAngle,
      isInner,
      larger: isInner,
      icon: null,
      maskImage: null,
      bezierData: null,
      txs: 0,
      startColor: [color, color],
      endColor: ['#6A6A6A', '#e9e9eb'],
      bubbleColor: [color, '#000000'],
      colorIndex,
      symbol: ch.name,
      bubbleNum1: 0,
      bubbleNum2: 0,
      bubbles: { start: [], end: [] },
      bubbleT: 0.006,
      bubbleT2: 0.006,
      t: 0,
    };
  });
};

const getLargerChainIdx = (idx, length) => {
  let resIdx = idx + 4;
  if (resIdx > (length-1)) {
    resIdx = resIdx - length;
  }
  return resIdx;
}

export const getLargerChains = (chains) => {
  const chainIds = chains.map(chain => chain.id);
  const firstChainIdx = Math.floor((Math.random() * chainIds.length));
  const secondChainIdx = getLargerChainIdx(firstChainIdx,  chainIds.length);
  const thirdChainIdx = getLargerChainIdx(secondChainIdx,  chainIds.length);
  const largerChians = [chainIds[firstChainIdx], chainIds[secondChainIdx], chainIds[thirdChainIdx]];
  return largerChians;
}



export const getInnerConfigs = (chains, chainIdsOrder) => {
  const innerChains = (chainIdsOrder?.slice(0, 12) || []).map(id => chains?.filter(chian => chian.id === id)[0])
  sketchInnerConfigs = setSketchConfig(innerChains, true);
  return sketchInnerConfigs;
};

export const getOuterConfigs = (chains, chainIdsOrder) => {
  const outerChains = (chainIdsOrder?.slice(12) || []).map(id => chains?.filter(chian => chian.id === id)[0])
  sketchOutterConfigs = setSketchConfig(outerChains, false);
  return sketchOutterConfigs;
};
